import { Form, FormEntry, InternalFormDivider } from 'components/form';
import { CheckBox, DateTimeInput, Input, Radio, ReSelect } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useAside, useCrud, useForm, useTranslations } from 'hooks';
import TaskTemplateService from 'modules/tasks/pages/Manage/pages/TaskTemplates/pages/TaskTemplates/services';
import TaskGroupService from 'modules/tasks/pages/Manage/services';
import { Suspense, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { runAction } from 'utils';

import DailyForm from './RecurrenceForms/Daily.form';
import MonthlyForm from './RecurrenceForms/Monthly.form';
import WeeklyForm from './RecurrenceForms/Weekly.form';
import YearlyForm from './RecurrenceForms/Yearly.form';

const EventForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { translate } = useTranslations();

	const { asideBuilder } = useAside();
	const { getOne } = useCrud(props.service);
	const { getData } = useForm();

	const componentMap = {
		Daily: DailyForm,
		Weekly: WeeklyForm,
		Monthly: MonthlyForm,
		Yearly: YearlyForm,
	};

	const taskGroupService = new TaskGroupService();
	const taskTemplateService = new TaskTemplateService();

	const [data, setData] = useState([]);
	const myForm = useRef(null);
	const [loading, setLoading] = useState(true);
	const [recurrenceTypeOptions, setRecurrenceTypeOptions] = useState([]);
	const [categoryTypeOptions, setCategoryTypeOptions] = useState([]);
	const [taskTemplateOptions, setTaskTemplateOptions] = useState([]);
	const [endOfRecurrenceOptions, setEndOfRecurrenceOptions] = useState([]);
	const [activeRecurrence, setActiveRecurrence] = useState(null);
	const [activeEndOfRecurrence, setActiveEndOfRecurrence] = useState(1);

	const fetchTaskTemplates = (taskGroupId = -1) => {
		taskTemplateService.setParent(taskGroupId);
		taskTemplateService.getOptionsList().then((res) => {
			setTaskTemplateOptions(res.data || []);
		});
	};

	const Component = activeRecurrence ? componentMap[activeRecurrence] : null;

	const fetchRecurrenceTypes = async () => {
		await runAction('tenants', 'getEnum', 'RecurrenceType')
			.then((enumValues) => {
				const l_recurrenceTypes = [];
				Object.keys(enumValues).forEach((key) => {
					l_recurrenceTypes.push({
						label: translate(key, true),
						value: enumValues[key],
					});
				});

				setRecurrenceTypeOptions(l_recurrenceTypes);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const fetchEndOfRecurrences = async () => {
		await runAction('tenants', 'getEnum', 'ScheduleEndOfRecurrenceType')
			.then((enumValues) => {
				const l_enumValues = [];
				Object.keys(enumValues).forEach((key) => {
					l_enumValues.push({
						label: translate(key, true),
						value: enumValues[key],
					});
				});

				setEndOfRecurrenceOptions(l_enumValues);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const getCategoryTypes = async () => {
		await runAction('tenants', 'getEnum', 'ScheduleCategory')
			.then((enumValues) => {
				const l_catTypes = [];
				Object.keys(enumValues).forEach((key) => {
					l_catTypes.push({
						label: translate(key, true),
						value: enumValues[key],
					});
				});

				setCategoryTypeOptions(l_catTypes);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				fetchTaskTemplates(res?.taskGroup?.id);
				setActiveEndOfRecurrence(res?.endOfRecurrenceType || 1);
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (data?.recurrenceType && recurrenceTypeOptions.length) {
			const label = recurrenceTypeOptions.find((x) => x.value === data.recurrenceType)?.label;
			setActiveRecurrence(label);
		}
	}, [data, recurrenceTypeOptions]);

	useEffect(() => {
		fetchEndOfRecurrences();
		fetchRecurrenceTypes();
		getCategoryTypes();
		initiateData();
	}, []);

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	const closeAside = () => {
		asideBuilder.reset();
		asideBuilder.setOpen(false);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(myForm.current),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />
					<input
						type='hidden'
						name='categoryTaskModel.taskId'
						value={data?.categoryTaskModel?.taskId || props?.data?.taskId}
					/>
					<FormEntry label='name'>
						<Input
							isView={isView}
							type='text'
							placeholder={'name'}
							required={true}
							name='name'
							defaultValue={data?.name}
						/>
					</FormEntry>
					{/* When you want to activate the categoryType, just remove sr-only classes */}
					<FormEntry className='sr-only' label='categoryType'>
						<ReSelect
							className='sr-only'
							required
							isView={isView}
							name='categoryType'
							options={categoryTypeOptions}
							defaultValue={data?.categoryType || 1}
						/>
					</FormEntry>
					<FormEntry label='taskGroup'>
						<ReSelect
							isView={isView}
							required
							name='taskGroupId'
							service={taskGroupService}
							onChange={(value) => fetchTaskTemplates(value)}
							defaultValue={data?.taskGroup || props?.data?.taskGroup}
						/>
					</FormEntry>
					<FormEntry
						label='taskTemplate'
						helpText={
							data?.categoryTaskModel?.taskId || props?.data?.taskId
								? translate('thisScheduleIsAssociatedWithATask')
								: ''
						}
					>
						<ReSelect
							required
							isView={isView}
							name='categoryTaskModel.taskTemplateId'
							options={taskTemplateOptions}
							defaultValue={data?.categoryTaskModel?.taskTemplate || props?.data?.taskTemplate}
						/>
					</FormEntry>
					<FormEntry label='recurrenceType'>
						<ReSelect
							required
							isView={isView}
							name='recurrenceType'
							options={recurrenceTypeOptions}
							defaultValue={data?.recurrenceType}
							onChange={(value) => {
								if (!value) {
									setActiveRecurrence(null);
									return;
								}
								const label = recurrenceTypeOptions.find((x) => x.value === value)?.label;
								setActiveRecurrence(label);
							}}
						/>
					</FormEntry>
					{Component && <Component isView={isView} data={data} />}
					<InternalFormDivider>{translate('rangeOfRecurrence')}</InternalFormDivider>
					<FormEntry label='startDateTime'>
						<DateTimeInput
							isView={isView}
							type='text'
							placeholder={'startDateTime'}
							required={true}
							name='startDateTime'
							defaultValue={data?.startDateTime || props?.data?.startDateTime}
						/>
					</FormEntry>
					{/* //TODO: Ask for backend to add this field */}
					<FormEntry label='endOfRecurrence'>
						<Radio
							options={endOfRecurrenceOptions}
							name='endOfRecurrenceType'
							alignment='vertical'
							defaultSelected={data?.endOfRecurrenceType || activeEndOfRecurrence}
							onChange={(e) => {
								const value = parseInt(e.target.value);
								if (!value) {
									setActiveEndOfRecurrence(1);
									return;
								}
								setActiveEndOfRecurrence(value);
							}}
							getFirstItemAsDefault={false}
						/>
					</FormEntry>
					{activeEndOfRecurrence === 1 && (
						<FormEntry label='endByDateTime'>
							<DateTimeInput
								required
								isView={isView}
								type='text'
								placeholder={'endByDateTime'}
								name='endByDateTime'
								defaultValue={data?.endByDateTime}
							/>
						</FormEntry>
					)}
					{activeEndOfRecurrence === 2 && (
						<FormEntry label='endAfterOccurrences'>
							<Input
								isView={isView}
								type='number'
								min={0}
								required
								placeholder={'endAfterOccurrences'}
								name='endAfterOccurrences'
								defaultValue={data?.endAfterOccurrences}
							/>
						</FormEntry>
					)}
					<InternalFormDivider>{translate('misc')}</InternalFormDivider>
					<FormEntry label={'enabled'}>
						<CheckBox isView={isView} name='enabled' label='enabled' selected={data?.enabled} />
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default EventForm;
