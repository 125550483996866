import { withSuspense } from 'hoc';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useToolbar } from 'hooks';
import { useOutletContext } from 'react-router-dom';
import TaskTableRefreshContext from './Contexts/index';
import TasksTable from './pages/Tasks/parts/TasksTable';

const TasksPage = () => {
	const { crumbsBuilder } = useToolbar();
	const [taskControls, setTaskControls] = useState({});
	const onlineEmployeesRef = useRef(null);
	const teamsRef = useRef(null);

	const { permissionsMap } = useOutletContext();

	useEffect(() => {
		crumbsBuilder.resetCrumbs();
	}, []);

	const refreshHandler = useCallback(() => {
		onlineEmployeesRef.current?.refresh();
		teamsRef.current?.refresh();
	}, []);

	return (
		<TaskTableRefreshContext.Provider value={refreshHandler}>
			<div className='grid grid-cols-1 gap-2'>
				{permissionsMap.tasks?.View && (
					<TasksTable
						permissions={permissionsMap.tasks}
						onControlUpdate={(controls) => {
							setTaskControls(controls);
						}}
						onRefresh={refreshHandler}
					/>
				)}
			</div>
		</TaskTableRefreshContext.Provider>
	);
};

export default withSuspense(TasksPage);
