import usePermissions, { PERMISSION, RESOURCE } from 'hooks/usePermissions';
const SettingsPermissions = () => {
	const { hasPermission } = usePermissions();
	return {
		settings: {
			General: {
				// Overview: {
				// 	View: true,
				// },
				TenantSettings: {
					View: hasPermission(RESOURCE.TenantSettings, PERMISSION.View),
					Update: hasPermission(RESOURCE.TenantSettings, PERMISSION.Create),
				},
				HL7Settings: {
					Create: hasPermission(RESOURCE.HL7, PERMISSION.Create),
					View: hasPermission(RESOURCE.HL7, PERMISSION.View),
					Update: hasPermission(RESOURCE.HL7, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.HL7, PERMISSION.Delete),
				},
				ReservedSettings: {
					View: hasPermission(RESOURCE.SuperAdmin, PERMISSION.View),
					Update: hasPermission(RESOURCE.SuperAdmin, PERMISSION.Create),
				},
				DispatcherSettings: {
					View: hasPermission(RESOURCE.Dispatcher, PERMISSION.View),
					Update: hasPermission(RESOURCE.Dispatcher, PERMISSION.Create),
				},
				PorterConfig: {
					View: hasPermission(RESOURCE.PorterConfig, PERMISSION.ShowQrCode),
				},
			},
			GeneralContents: {
				Genders: {
					Create: hasPermission(RESOURCE.TenantSettings, PERMISSION.Create),
					View: hasPermission(RESOURCE.TenantSettings, PERMISSION.View),
					Update: hasPermission(RESOURCE.TenantSettings, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.TenantSettings, PERMISSION.Delete),
				},
				Titles: {
					Create: hasPermission(RESOURCE.TenantSettings, PERMISSION.Create),
					View: hasPermission(RESOURCE.TenantSettings, PERMISSION.View),
					Update: hasPermission(RESOURCE.TenantSettings, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.TenantSettings, PERMISSION.Delete),
				},
			},
			SSO: {
				Setup: {
					ActiveDirectory: {
						View: hasPermission(RESOURCE.ActiveDirectory, PERMISSION.View),
						Update: hasPermission(RESOURCE.ActiveDirectory, PERMISSION.Create),
					},
					EntraId: {
						View: hasPermission(RESOURCE.EntraId, PERMISSION.View),
						Update: hasPermission(RESOURCE.EntraId, PERMISSION.Create),
					},
				},
				Configuration: {
					Create:
						hasPermission(RESOURCE.ActiveDirectory, PERMISSION.Create) ||
						hasPermission(RESOURCE.EntraId, PERMISSION.Create),
					View:
						hasPermission(RESOURCE.ActiveDirectory, PERMISSION.View) ||
						hasPermission(RESOURCE.EntraId, PERMISSION.View),
					Update:
						hasPermission(RESOURCE.ActiveDirectory, PERMISSION.Create) ||
						hasPermission(RESOURCE.EntraId, PERMISSION.Create),
					Delete:
						hasPermission(RESOURCE.ActiveDirectory, PERMISSION.Delete) ||
						hasPermission(RESOURCE.EntraId, PERMISSION.Delete),
					Sync:
						hasPermission(RESOURCE.ActiveDirectory, PERMISSION.Sync) ||
						hasPermission(RESOURCE.EntraId, PERMISSION.Sync),
				},
			},
		},
	};
};
export default SettingsPermissions;
