import { useTranslations } from 'hooks';
import { Link } from 'react-router-dom';

const LinkTabs = ({ activeItem = '', items = [], itemKeyName = 'path' }) => {
	const { translate } = useTranslations();

	return (
		<div className='flex border-b bg-white py-2 space-x-4 -my-4 -mx-2 mb-2'>
			{items.map((item, index) => (
				<Link
					key={index}
					className={`py-2 px-4 font-medium ${
						activeItem === item[itemKeyName] ? 'text-black' : 'text-gray-500'
					}`}
					to={item[itemKeyName]}
				>
					{translate(item.name)}
				</Link>
			))}
		</div>
	);
};

export default LinkTabs;
