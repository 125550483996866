import { Table } from 'components/ui/Table';
import { useCrud, useTranslations } from 'hooks';
import usePermissions, { PERMISSION, RESOURCE } from 'hooks/usePermissions';
import { forwardRef, useState } from 'react';
import OnlineEmployeeModel from '../models';
import EmployeeService from '../services';

const OnlineEmployeesTable = forwardRef(
	(
		{
			isDraggable = false,
			zIndex = 1,
			onMouseDown,
			onMouseUp,
			onTouchEnd,
			style,
			className,
			onRemove = () => {},
			isExportable = false,
			children,
			...props
		},
		ref,
	) => {
		const { hasPermission } = usePermissions();
		const { translate } = useTranslations();
		const [data, setData] = useState({ data: [], meta: {} });

		const [queryString, setQueryString] = useState('');
		const [isLoading, setIsLoading] = useState(false);

		const service = new EmployeeService();
		const model = new OnlineEmployeeModel();
		const { getAll } = useCrud(service);

		const fetchAndLoad = async (l_queryString = null) => {
			try {
				setIsLoading(true);
				const res = await getAll(l_queryString || queryString);
				setIsLoading(false);
				setData(res);
			} catch (error) {
				console.error('Error on Get All request: ', error);
			}
		};

		if (!hasPermission(RESOURCE.AccessDashboardPanel, PERMISSION.OnlineEmployees)) {
			return 'NO PERMISSION';
		}

		return (
			<div
				className={`bg-white rounded-md p-4 shadow-md flex flex-col relative ${className}`}
				ref={ref}
				onMouseDown={onMouseDown}
				onMouseUp={onMouseUp}
				onTouchEnd={onTouchEnd}
				style={style}
				{...props}
			>
				<div className='font-semibold'>{translate(model.getPluralModelName())}</div>
				<Table
					displayCheckBoxes={false}
					isLoading={isLoading}
					isExportable={false}
					model={model}
					meta={data.meta}
					data={data.data}
					onTableRequestChange={(queryStr) => {
						setQueryString(queryStr);
						fetchAndLoad(queryStr);
					}}
				/>
				{children}
			</div>
		);
	},
);
export default OnlineEmployeesTable;
