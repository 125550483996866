import { useAside, useTranslations } from 'hooks';
import ManageChartForm from './forms/ManageChart.form';
import ManageCounterForm from './forms/ManageCounter.form';
const ControlHandlers = ({
	counters,
	charts,
	onCounterChange = () => {},
	onChartChange = () => {},
	onApplyCounterChanges = () => {},
	onApplyChartChanges = () => {},
}) => {
	const { translate } = useTranslations();
	const { asideBuilder } = useAside();

	const openCounterManager = () => {
		asideBuilder.setOpen(true);
		asideBuilder.setTitle(translate('manageCounters'));
		asideBuilder.setComponent(ManageCounterForm);
		asideBuilder.setComponentKey('counter-manager-' + new Date());
		asideBuilder.setAutoClose(false);
		asideBuilder.setAutoClear(false);
		asideBuilder.setCloseOnOutsideClick(true);
		asideBuilder.hideSaveButton(true);
		asideBuilder.setComponentProps({
			data: {
				counters: counters,
			},
			onCounterChange,
			onApplyChanges: onApplyCounterChanges,
		});
		asideBuilder.build();
	};

	const closeCounterManager = () => {
		asideBuilder.setOpen(false);
		asideBuilder.reset();
	};

	const openChartManager = () => {
		asideBuilder.setOpen(true);
		asideBuilder.setTitle(translate('manageCharts'));
		asideBuilder.setComponent(ManageChartForm);
		asideBuilder.setComponentKey('chart-manager-' + new Date());
		asideBuilder.setAutoClose(false);
		asideBuilder.setAutoClear(false);
		asideBuilder.setCloseOnOutsideClick(true);
		asideBuilder.hideSaveButton(true);
		asideBuilder.setComponentProps({
			data: {
				charts: charts,
			},
			onChartChange,
			onApplyChanges: onApplyChartChanges,
		});
		asideBuilder.build();
	};

	const closeChartManager = () => {
		asideBuilder.setOpen(false);
		asideBuilder.reset();
	};

	return {
		openCounterManager,
		closeCounterManager,
		openChartManager,
		closeChartManager,
	};
};
export default ControlHandlers;
