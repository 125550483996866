import { FormEntry, InternalFormDivider } from 'components/form';
import { Input, Radio, ReSelect } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import { useEffect, useState } from 'react';
import { runAction } from 'utils';
import DayOfMonth from '../../components/DayOfMonthInput';
import DayOfWeeksInput from '../../components/DaysOfWeekInput';
import MonthInput from '../../components/MonthInput';

const RECURRENCE_PATTERN_OPTIONS = [
	{ label: 'dayOfMonth', value: 1 },
	{ label: 'dayOfWeek', value: 2 },
];

const YearlyForm = ({ isView, data }) => {
	const { translate } = useTranslations();
	const [monthlyOccurrenceOptions, setMonthlyOccurrenceOptions] = useState([]);
	const [activeRecurrencePattern, setActiveRecurrencePattern] = useState(1);

	const fetchRecurrenceTypes = async () => {
		await runAction('tenants', 'getEnum', 'WeekOfTheMonthOccurence')
			.then((enumValues) => {
				const enumOptions = [];
				Object.keys(enumValues).forEach((key) => {
					enumOptions.push({
						label: translate(key, true),
						value: enumValues[key],
					});
				});

				setMonthlyOccurrenceOptions(enumOptions);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	useEffect(() => {
		if (data?.recurrenceDailyModel?.recurrencePattern) {
			setActiveRecurrencePattern(data?.recurrenceDailyModel?.recurrencePattern);
		}
	}, [data]);

	useEffect(() => {
		fetchRecurrenceTypes();
	}, []);
	return (
		<>
			<InternalFormDivider>{translate('yearlyRecurrence')}</InternalFormDivider>
			<FormEntry label='every'>
				<Radio
					options={RECURRENCE_PATTERN_OPTIONS}
					name='recurrenceYearlyModel.recurrencePattern'
					alignment='horizontal'
					defaultSelected={data?.recurrenceYearlyModel?.recurrencePattern || activeRecurrencePattern}
					onChange={(e) => {
						const value = parseInt(e.target.value);
						if (!value) {
							setActiveRecurrencePattern(1);
							return;
						}
						setActiveRecurrencePattern(value);
					}}
					getFirstItemAsDefault={false}
				/>
			</FormEntry>
			<FormEntry label='recurEvery'>
				<Input
					required
					isView={isView}
					type='number'
					min={0}
					placeholder={'interval'}
					name='recurrenceYearlyModel.interval'
					defaultValue={data?.recurrenceYearlyModel?.interval || 1}
					sideLabel={translate('years')}
				/>
			</FormEntry>
			{activeRecurrencePattern === 1 && (
				<>
					<FormEntry label='day'>
						<DayOfMonth
							required
							defaultSelected={data?.recurrenceYearlyModel?.dayOfMonth}
							name='recurrenceYearlyModel.dayOfMonth'
						/>
					</FormEntry>
					<FormEntry label='month'>
						<MonthInput
							required
							defaultSelected={data?.recurrenceYearlyModel?.month}
							name='recurrenceYearlyModel.month'
						/>
					</FormEntry>
				</>
			)}

			{activeRecurrencePattern === 2 && (
				<>
					<FormEntry label='onEach'>
						<ReSelect
							required
							isView={isView}
							name='recurrenceMonthlyModel.monthlyOccurrence'
							options={monthlyOccurrenceOptions}
							defaultSelected={data?.recurrenceMonthlyModel?.monthlyOccurrence}
						/>
					</FormEntry>
					<FormEntry label='day'>
						<DayOfWeeksInput
							required
							isMultiple={false}
							defaultSelected={data?.recurrenceYearlyModel?.dayOfWeek}
							name='recurrenceYearlyModel.dayOfWeek'
						/>
					</FormEntry>
					<FormEntry label='month'>
						<MonthInput
							required
							defaultSelected={data?.recurrenceYearlyModel?.month}
							name='recurrenceYearlyModel.month'
						/>
					</FormEntry>
				</>
			)}
		</>
	);
};
export default YearlyForm;
