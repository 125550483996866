import { Form, FormEntry, InternalFormDivider } from 'components/form';
import { Input, ReSelect, SearchSelect } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useAside, useCrud, useForm, useTranslations } from 'hooks';
import PasswordValidator from 'modules/auth/components/PasswordValidator';
import UserProfileService from 'modules/persons/pages/Users/pages/UserProfiles/services';
import GenderService from 'modules/settings/pages/generalSettings/pages/GeneralContents/pages/Gender/services';
import { Suspense, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { getYearsAgoDate, runAction } from 'utils';
import FormElement from '../components/FormElement';
import TeamInput from '../components/TeamInput';
import UserSettings from './user.settings';

const UserForm = forwardRef(({ isView = false, ...props }, ref) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const myForm = useRef(null);
	const passwordInput = useRef(null);
	const [userProfileOptions, setUserProfileOptions] = useState([]);

	const [password, setPassword] = useState('');
	const [passwordIsValid, setPasswordIsValid] = useState(false);
	const [passwordHelperIsOpen, setPasswordHelperIsOpen] = useState(false);

	const [userProfiles, setUserProfiles] = useState([]);
	const [settings, setSettings] = useState(null);

	const [genderOptions, setGenderOptions] = useState([]);
	const { translate } = useTranslations();
	const { getData } = useForm();
	const { getOne, create, update } = useCrud(props.service);
	const userProfileService = new UserProfileService();
	const genderService = new GenderService();
	const { helperAsideBuilder, asideBuilder } = useAside();
	const [helperActive, setHelperActive] = useState(false);

	//TODO: Get Specific fields from BE
	const specificFields = [
		{
			name: 'dateOfBirth',
			label: 'dateOfBirth',
			type: 'date',
			otherOptions: {
				max: getYearsAgoDate(17),
				min: getYearsAgoDate(100),
			},
		},
		{
			name: 'gender',
			label: 'gender',
			type: 'select',
			options: genderOptions,
		},
	];

	//Get general information fields from BE
	const generalInformationFields = [
		{
			name: 'phoneNumber',
			label: 'phoneNumber',
			type: 'tel',
			placeholder: '(555) 555-1234',
		},
	];

	const getTenantSettings = async (action = () => {}) => {
		await runAction('tenants', 'getSettings')
			.then((res) => {
				action(res);

				setSettings(res);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const openDialog = (userData = null) => {
		helperAsideBuilder.setTitle(translate('accountSettings'));
		helperAsideBuilder.setComponent(UserSettings);
		helperAsideBuilder.setOpen(true);
		helperAsideBuilder.setSaveCallback((payload) => saveSettings(payload, userData));
		helperAsideBuilder.hideSaveButton(false);
		helperAsideBuilder.setComponentProps({
			data: userData,
			service: props.service,
			requestReload: () => {
				props?.requestReload();
				initiateData();
			},
			helperIsOpen: (status) => {
				asideBuilder.hideSaveButton(status);
				setHelperActive(status);
			},
			formSuccess: () => {
				setHelperActive(true);
				setTimeout(() => {
					runSubmit();
					asideBuilder.setOpen(false);
					asideBuilder.reset();
				}, 500);
			},
		});
		helperAsideBuilder.build();
	};

	const saveSettings = async (settingsData, mainData) => {
		await _doSave(settingsData, mainData);
		initiateData();
	};

	const _doSave = async (payload, mainData) => {
		try {
			const res = await props.service.updateSettings(mainData.id, payload);
			if (res?.succeeded) {
				toast(translate('settingsUpdatesSuccessfully'), {
					duration: 2000,
					icon: '✅',
				});
				return res;
			} else {
				toast(translate(res?.message || translate('somethingWentWrong')), {
					duration: 2000,
					icon: '❗️',
				});
				throw new Error(res?.message || translate('somethingWentWrong'));
			}
		} catch (error) {
			toast(translate(error.message) || translate('somethingWentWrong'), {
				duration: 2000,
				icon: '❗️',
			});
			console.error('Error on Update request: ', error);
			throw error;
		}
	};

	const assertPassword = () => {
		if (!data?.id) {
			if (!passwordIsValid) {
				passwordInput.current.focus();
				passwordInput.current.setCustomValidity(translate('passwordDoesNotMeetRequirements'));
				return false;
			} else {
				passwordInput?.current?.setCustomValidity('');
			}
		}
		return true;
	};

	const runSubmit = () => {
		const formData = getData(myForm.current, assertPassword());
		if (!formData) return;
		saveAndLoad(formData);
	};

	const saveAndLoad = async (data) => {
		asideBuilder.setIsLoading(true);
		if (data) {
			try {
				if (isNaN(data.id)) {
					await create(data, {
						creationSuccessMessage: 'userCreatedSuccessfully',
					}).then((res) => {
						asideBuilder.setIsLoading(false);
						props.data.id = res.data.id;
						setData(res.data);
						openDialog({ ...res.data, freshCreated: true });
					});
				} else {
					await update(data.id, data, {
						updateSuccessMessage: 'userUpdatedSuccessfully',
					}).then((res) => {
						asideBuilder.setIsLoading(false);
						asideBuilder.setOpen(false);
						asideBuilder.reset();
					});
				}
				props.requestReload();
			} catch (e) {
				asideBuilder.setIsLoading(false);
			}
		}
	};

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(myForm.current, assertPassword()),
		runSubmit: () => runSubmit(),
		clear: () => resetData(),
	}));

	const initiateData = () => {
		if (props?.data?.id) {
			getOne(props?.data?.id).then((res) => {
				setData(res);
				setLoading(false);
			});
		} else {
			getTenantSettings();
			setLoading(false);
		}

		userProfileService.getOptionsList().then((res) => {
			setUserProfileOptions(res.data);
		});

		genderService.getOptionsList().then((res) => {
			setGenderOptions(res.data);
		});
	};

	useEffect(() => {
		if (data?.profileIds && userProfileOptions) {
			const l_userProfiles = data?.profileIds.map((item) => ({
				value: item,
				label: userProfileOptions.find((option) => option.value === item)?.label,
			}));
			setUserProfiles(l_userProfiles);
		}
	}, [userProfileOptions, data]);

	useEffect(() => {
		initiateData();
	}, []);

	const TriggerButton = forwardRef((props, ref) => {
		return (
			<button
				ref={ref}
				type='button'
				className='mr-3 hover:bg-gray-50 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center absolute top-2 right-3'
				onClick={props.onClick}
			>
				<i className={`ri-settings-4-line text-lg`}></i>
				<span className='pl-1'>{translate('settings')}</span>
			</button>
		);
	});
	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={loading} />}>
			{!isNaN(data?.id) && !isView && <TriggerButton onClick={() => openDialog(data)} />}

			<div className={`w-full h-100 pb-10 overflow-y-visible ${helperActive ? 'opacity-40' : ''}`}>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />

					<input type='hidden' name='userName' value={data?.userName || false} />
					<input
						type='hidden'
						name='settings.loginTwoFaPolicy'
						value={data?.settings?.loginTwoFaPolicy ?? settings?.loginTwoFaPolicy ?? 0}
					/>
					<input
						type='hidden'
						name='settings.changePasswordNextLogin'
						value={data?.settings?.changePasswordNextLogin ?? settings?.changePasswordNextLogin ?? false}
					/>
					<input
						type='hidden'
						name='settings.passwordExpiresInDays'
						value={data?.settings?.passwordExpiresInDays ?? settings?.passwordExpiresInDays ?? 0}
					/>

					{isNaN(data?.id) && (
						<>
							<InternalFormDivider>{translate('authentication')}</InternalFormDivider>

							<FormEntry label='username'>
								<Input
									type='text'
									placeholder='johndoe'
									name='userName'
									required={true}
									defaultValue={data?.userName || ''}
								/>
							</FormEntry>
							<FormEntry
								label={'password'}
								isRequired={true}
								helpText={
									<PasswordValidator
										password={password}
										onChange={(isValid) => {
											setPasswordHelperIsOpen(!isValid);
											setPasswordIsValid(isValid);
										}}
										isOpen={passwordHelperIsOpen}
									/>
								}
							>
								<Input
									ref={passwordInput}
									type='password'
									placeholder='password'
									name='password'
									required={true}
									minLength={7}
									regexExpression={
										'(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[@$!%*#?~(&)+=^_-]).{7,}'
									}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</FormEntry>
						</>
					)}
					<InternalFormDivider>{translate('generalInformation')}</InternalFormDivider>
					<FormEntry label={'firstName'}>
						<Input
							disabled={helperActive}
							isView={isView}
							type='text'
							placeholder='John'
							name='name'
							minLength={3}
							required={true}
							defaultValue={data?.name}
						/>
					</FormEntry>
					<FormEntry label={'lastName'}>
						<Input
							disabled={helperActive}
							isView={isView}
							type='text'
							placeholder='Doe'
							name='lastName'
							required={true}
							defaultValue={data?.lastName}
						/>
					</FormEntry>
					<FormEntry label={'email'} isRequired={data?.id ? false : true}>
						<Input
							type='email'
							isView={isView}
							placeholder='info@example.com'
							name='email'
							required={true}
							disabled={data?.id ? true : false}
							regexExpression={'^(?!.{50})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'} //We are checking this in order to match the backend validation
							defaultValue={data?.email}
						/>
					</FormEntry>

					{generalInformationFields.map((field) => {
						return <FormElement isView={isView} {...field} defaultValue={data?.[field.name] || null} />;
					})}

					<InternalFormDivider>{translate('CATOInformation')}</InternalFormDivider>
					<FormEntry label='userProfiles'>
						<SearchSelect
							multiple
							isView={isView}
							required
							disabled={helperActive}
							options={userProfileOptions}
							name='profileIds'
							defaultSelected={data?.profileIds}
							onChange={(options) => {
								setUserProfiles(options);
							}}
						/>
					</FormEntry>

					<FormEntry label='defaultProfile'>
						<ReSelect
							isView={isView}
							required={true}
							disabled={helperActive}
							name={'DefaultProfileId'}
							defaultValue={
								userProfiles.find((item) => item.value === data?.defaultProfileId)?.value ??
								userProfiles.at(0)?.value
							}
							options={userProfiles}
						/>
					</FormEntry>
					<TeamInput isView={isView} disabled={helperActive} selectedValue={data?.teams} />

					{specificFields.length > 0 && (
						<InternalFormDivider>{translate('specificFields')}</InternalFormDivider>
					)}
					{specificFields.map((field) => {
						return (
							<FormElement
								isView={isView}
								{...field}
								defaultValue={data?.[field.name] || null}
								disabled={helperActive}
							/>
						);
					})}
				</Form>
			</div>
		</Suspense>
	);
});
export default UserForm;
