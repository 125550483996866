import { NO_DATA_DISPLAY } from 'config/configs';
import { DateRender } from 'core/renders';
import { useTranslations } from 'hooks';
import { renderToString } from 'react-dom/server';
const PatientRenderer = ({ value }) => {
	const { translate } = useTranslations();

	if (!value) return NO_DATA_DISPLAY;

	const tooltipHtml = (
		<div className='-my-1 -mx-3 p-3 border border-gray-100 rounded-md'>
			<table className='table-auto text-left'>
				<tr className='pb-1'>
					<th className=' pr-2'>
						{translate('admissionNumber')}
						{': '}
					</th>
					<td>{value.admissionNumber}</td>
				</tr>
				<tr className='pb-1'>
					<th className=' pr-2'>
						{translate('patientNumber')}
						{': '}
					</th>
					<td>{value.patientNumber}</td>
				</tr>
				<tr className='pb-1'>
					<th className=' pr-2'>
						{translate('name')}
						{': '}
					</th>
					<td>{value.name}</td>
				</tr>
				<tr className='pb-1'>
					<th className=' pr-2'>
						{translate('gender')}
						{': '}
					</th>
					<td>{value.gender}</td>
				</tr>
				<tr className='pb-1'>
					<th className=' pr-2'>
						{translate('dateOfBirth')}
						{': '}
					</th>
					<td>{DateRender({ value: value.dateOfBirth })}</td>
				</tr>
			</table>
		</div>
	);

	return (
		<div
			className='pr-3 pl-0.5 py-1.5 bg-gray-200 rounded-l-lg rounded-r-2xl'
			data-tooltip-variant='light'
			data-tooltip-delay-hide={300}
			data-tooltip-html={renderToString(tooltipHtml)}
			data-tooltip-id={`table-tooltip`}
		>
			<div className='pl-1 flex justify-between space-x-2 flex-row items-center '>
				<div className=''>
					<div className='whitespace-nowrap font-light -mb-0.5  text-xs text-gray-500'>
						{value?.patientNumber}
					</div>

					<div className='text-gray-700  font-medium whitespace-nowrap '>{value?.name}</div>
				</div>
				<div>
					<div className='text-gray-700 font-bold'>{value?.gender?.charAt(0).toUpperCase()}</div>
				</div>
			</div>
		</div>
	);
};

export default PatientRenderer;
