import Card from 'components/ui/Card';
import { Table } from 'components/ui/Table';
import { useState } from 'react';

import DepartmentService from '../services';

import { CrudOperations } from 'hoc';
import { useCrud, useTranslations } from 'hooks';
import DepartmentForm from '../forms/department.form';
import DepartmentModel from '../models';

const Tables = ({ permissions }) => {
	const { translate } = useTranslations();
	const service = new DepartmentService();
	const model = new DepartmentModel();
	model.setExportable(permissions.Export);

	const { create, update, getAll, remove, getExport } = useCrud(service);

	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState('');

	const fetchAndLoad = async (l_queryString = null) => {
		try {
			const res = await getAll(l_queryString || queryString);
			setData(res);
		} catch (error) {
			console.error('Error on Get All request: ', error);
		}
	};

	const { openCreate, openEdit, openView } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: DepartmentForm,
		componentName: model.getModelName(),
		permissions: permissions,
	});

	const rowClickHandler = (row) => {
		if (permissions.Update) {
			openEdit(row);
		} else if (permissions.View) {
			openView(row);
		}
	};

	return (
		<Card
			collapsible
			header={model.getPluralModelName()}
			headerButtonLabel={permissions.Create && `+ ${translate('newDepartment')}`}
			headerButtonClick={openCreate}
		>
			<Table
				model={model}
				onRowClick={rowClickHandler}
				onExportClick={async (queryString) => {
					return await getExport(queryString, model.getPluralModelName());
				}}
				meta={data.meta}
				data={data.data}
				onTableRequestChange={(queryString) => {
					setQueryString(queryString);
					fetchAndLoad(queryString);
				}}
			></Table>
		</Card>
	);
};

export default Tables;
