import { LOCATION_TRUNCATE_LENGTH } from 'config/configs';
import { useEffect, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { runAction, TruncateString } from 'utils';
import AddressLocationRender from './components/AddressLocationRender';

const AddressRenderer = ({
	value,
	belowLevel = 2,
	truncateLength = LOCATION_TRUNCATE_LENGTH,
	tooltipContainerId = 'table-tooltip',
	className = '',
}) => {
	const [tenantSettings, setTenantSettings] = useState(null);

	const fetchTenantSettings = async () => {
		try {
			const res = await runAction('tenants', 'getSettings');
			return res ?? null;
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	useEffect(() => {
		fetchTenantSettings().then(setTenantSettings);
	}, []);

	const l_truncateLength = useMemo(() => {
		return tenantSettings?.locationNameDisplayLength || truncateLength;
	}, [tenantSettings, truncateLength]);

	let address = value?.child;
	const addressList = [];
	while (address) {
		addressList.push(address);
		address = address?.child;
	}

	if (belowLevel) {
		const temp = addressList.filter((a) => a.level <= belowLevel);
		addressList.length = 0;
		addressList.push(...temp);
	}

	return (
		<div
			data-tooltip-variant='light'
			data-tooltip-html={renderToString(AddressLocationRender({ address: value }))}
			data-tooltip-id={tooltipContainerId}
		>
			<div className={`flex items-center text-gray-500 ${className}`}>
				{addressList.map((address, index) => (
					<div key={index} className={`flex items-center `}>
						<span className=' whitespace-nowrap  '>{TruncateString(address.name, l_truncateLength)}</span>
						{index !== addressList.length - 1 && <span className=' mx-2'>/</span>}
					</div>
				))}
			</div>
		</div>
	);
};

export default AddressRenderer;
