// Simplified Mediator Pattern
import addressesUtils from 'modules/addresses/commonUtils';
import authUtils from 'modules/auth/commonUtils';
import TaskModuleUtils from 'modules/global/modules/taskModules/utils';
import translationUtils from 'modules/global/modules/translations/utils';
import { getCountryByLanguageCode, getCountryFlag, getLanguages } from 'modules/global/services/languages';
import personsUtils from 'modules/persons/commonUtils';
import { getPermissions } from 'modules/persons/pages/Users/pages/Settings/pages/PermissionGroups/utils';
import scannerUtils from 'modules/scanners/commonUtils';
import TenantUtils from 'modules/settings/pages/generalSettings/pages/General/Tenant/utils';

const utils = {
	auth: { ...authUtils },
	language: { getLanguages, getCountryByLanguageCode, getCountryFlag }, //Should be removed
	persons: { ...personsUtils },
	addresses: { ...addressesUtils },
	tenants: TenantUtils,
	permissions: { getPermissions },
	translations: { ...translationUtils },
	taskModules: TaskModuleUtils,
	scanners: { ...scannerUtils },
};

export const runAction = (module, event, data) => {
	// console.log(`Requestion action ${module}:${event}`); //let us uncomment this one, it will be useful for debugging
	if (!(module in utils)) {
		console.log(`Requesting module ${module} does not exist`);
		return null;
	}

	const actions = utils[module];
	if (!(event in actions)) {
		console.log(`Requesting ${event}@${module} does not exist`);
		return null;
	}

	const action = actions[event];
	return action(data);
};
