import { useTranslations } from 'hooks';
import { forwardRef, useEffect, useState } from 'react';
import Chart from '..';

import DashboardService from '../../../../services';
import Settings from '../components/ChartSettings';
import chartColors from '../configs/chartColors';
import { convertData, exportDataToCSV } from '../utils/chartHelper';
import Filter from './Filters';

const TeamStatsChart = forwardRef(
	({ isDraggable = false, zIndex = 1, key = '1', onRemove = () => {}, isExportable = false, ...props }, ref) => {
		const { translate } = useTranslations();

		const dashboardService = new DashboardService();

		const DEFAULT_FILTER_DATA = {
			startTime: '00:01',
			endTime: '23:59',
		};
		const CHART_EXPORT_MAP = {
			openTime: { label: 'Open Time', order: 0 },
			openReadyTasksCount: { label: 'Open Ready', order: 1 },
			completedTasksCount: { label: 'Completed', order: 2 },
			cancelledTasksCount: { label: 'Cancelled', order: 3 },
			inProgressTasksCount: { label: 'In Progress', order: 4 },
			openNotReadyTasksCount: { label: 'Not Ready', order: 5 },
			loggedInEmployeesCount: { label: 'Logged In', order: 6 },
		};
		const [data, setData] = useState(null);
		const [renderTime, setRenderTime] = useState(() => new Date());
		const [filterVisible, setFilterVisible] = useState(false);
		const [settingsVisible, setSettingsVisible] = useState(false);
		const [legendsVisible, setLegendsVisible] = useState(true);
		const [filterData, setFilterData] = useState(null);

		const fetchData = async () => {
			let preparedFilterData = convertData(DEFAULT_FILTER_DATA);

			if (filterData) {
				preparedFilterData = convertData(filterData);
			}

			const result = await dashboardService.getTeamChartData([...preparedFilterData]);
			setData(result.data);
			setRenderTime(new Date());
		};

		const legends = [
			{
				key: 'openTime',
				label: translate('openTime'),
				color: chartColors.cyan,
			},
			{
				key: 'openReadyTasksCount',
				label: translate('openReadyTasks'),
				color: chartColors.blue,
			},
			{
				key: 'completedTasksCount',
				label: translate('completedTasks'),
				color: chartColors.green,
			},
			{
				key: 'cancelledTasksCount',
				label: translate('cancelledTasks'),
				color: chartColors.red,
			},
			{
				key: 'inProgressTasksCount',
				label: translate('inProgressTasks'),
				color: chartColors.yellow,
			},
			{
				key: 'openNotReadyTasksCount',
				label: translate('openNotReadyTasks'),
				color: chartColors.purple,
			},
			{
				key: 'loggedInEmployeesCount',
				label: translate('loggedInEmployees'),
				color: chartColors.gray,
			},
		];

		const FilterComponent = () => {
			return (
				<Filter
					display={filterVisible}
					onCancel={() => {
						setFilterVisible(false);
					}}
					data={filterData || DEFAULT_FILTER_DATA}
					onSubmit={(data) => {
						setFilterData(data);
						setFilterVisible(false);
					}}
				/>
			);
		};

		const SettingsComponent = () => {
			return (
				<Settings
					display={settingsVisible}
					onCancel={() => {
						setSettingsVisible(false);
					}}
					onShowLegendsClick={() => {
						setLegendsVisible(!legendsVisible);
					}}
					onRemove={onRemove}
					showLegends={legendsVisible}
				/>
			);
		};

		const filterClickHandler = () => {
			if (!filterVisible) {
				setFilterVisible(true);
				setSettingsVisible(false);
				return;
			}

			setFilterVisible(false);
		};

		const settingsClickHandler = () => {
			if (!settingsVisible) {
				setSettingsVisible(true);
				setFilterVisible(false);
				return;
			}

			setSettingsVisible(false);
		};

		useEffect(() => {
			fetchData();
		}, [filterData]);

		return (
			<Chart
				ref={ref}
				type='PieChart'
				className={isDraggable ? 'cursor-move' : ''}
				data={data}
				legends={legends}
				renderTime={renderTime}
				onRefresh={fetchData}
				key={key}
				data-grid-id={key}
				header='teams'
				description='teamsByTaskStatus'
				FilterComponent={FilterComponent}
				SettingsComponent={SettingsComponent}
				filterActive={filterData !== null}
				onFilterClick={filterClickHandler}
				onSettingsClick={settingsClickHandler}
				displayLegends={legendsVisible}
				isExportable={isExportable}
				onExport={() => exportDataToCSV(data, CHART_EXPORT_MAP)}
				{...props}
				style={{ ...props.style, zIndex: zIndex }}
			/>
		);
	},
);

export default TeamStatsChart;
