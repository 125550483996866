import { Form, FormEntry } from 'components/form';
import { CheckBox, Input, Radio } from 'components/ui/Input';
import { forwardRef, Suspense, useContext, useEffect, useImperativeHandle, useRef } from 'react';

import { PortalContext } from 'contexts/PortalManager';
import { useTranslations } from 'hooks';
import { runAction } from 'utils';
import { Reset2FA, ResetPassword, SetupQrNfcLogin, UserStatus } from '../utils/actions';

const UserSettings = forwardRef((props, ref) => {
	const { translate } = useTranslations();
	const { callAction } = useContext(PortalContext);

	const myForm = useRef(null);

	useEffect(() => {
		if (props?.helperIsOpen) {
			props?.helperIsOpen(props?.componentOpen || false);
		}
	}, [props.componentOpen]);

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());

			if (props.formSuccess) {
				props.formSuccess(formDataObject);
			}

			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	const resetData = () => {
		myForm.current.reset();
	};

	useImperativeHandle(ref, () => ({
		getData,
		clear: resetData,
	}));

	const { changeUserStatusConfirmation } = UserStatus({ props });
	const { reset2FaConfirmation } = Reset2FA({ props });
	const { resetPasswordConfirmation } = ResetPassword({ props });
	const { setupQr, setupNfc } = SetupQrNfcLogin({ props });

	return (
		<Suspense>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form ref={myForm}>
					<FormEntry label={'changePassword'}>
						<CheckBox
							label={'requestToChangePasswordAfterLogin'}
							name={'changePasswordNextLogin'}
							selected={props?.data?.settings?.changePasswordNextLogin || false}
						/>
					</FormEntry>
					{!props?.data?.freshCreated && (
						<>
							<FormEntry label='username'>
								<Input
									type='text'
									placeholder='johndoe'
									name='userName'
									disabled={true}
									defaultValue={props?.data?.userName || ''}
								/>
							</FormEntry>
							<FormEntry inputClass='flex justify-end' label='password'>
								<button
									type={'button'}
									onClick={() => resetPasswordConfirmation(props?.data?.userName)}
									className='text-white rounded-md text-sm my-3 px-5 py-1.5 bg-gray-500 hover:bg-gray-400'
								>
									{translate('resetPassword')}
								</button>
							</FormEntry>
						</>
					)}

					<FormEntry label='twoFactorAuthentication'>
						<Radio
							name={'loginTwoFaPolicy'}
							options={[
								{ label: 'off', value: 1 },
								{ label: 'optional', value: 2 },
								{ label: 'mandatory', value: 3 },
							]}
							defaultSelected={parseInt(props?.data?.settings?.loginTwoFaPolicy)}
							required={true}
							getFirstItemAsDefault={false}
						/>
					</FormEntry>

					{!props?.data?.freshCreated && (
						<FormEntry inputClass='flex justify-end' label=''>
							<button
								type={'button'}
								onClick={() => reset2FaConfirmation(props?.data?.id)}
								className='text-white rounded-md text-sm my-4 px-10 py-1.5 bg-gray-500 hover:bg-gray-400'
							>
								{translate('reset2FA')}
							</button>
						</FormEntry>
					)}
					<FormEntry label='passwordExpirationTime' helpText={'leaveIt0forNoPasswordExpirationTime'}>
						<Input
							type='number'
							placeholder='30'
							min={0}
							name='passwordExpiresInDays'
							sideLabel={'days'}
							defaultValue={props?.data?.settings?.passwordExpiresInDays}
						/>
					</FormEntry>

					<FormEntry inputClass='flex justify-end space-x-5 pt-5' label=''>
						<button
							type={'button'}
							onClick={() =>
								// alert(translate("notImplementedYet"))
								runAction('scanners', 'openNfc', {
									callAction,
									onScan: (code) => setupNfc(props?.data?.id, code),
								})
							}
							className='text-white rounded-md text-sm px-8 py-1.5 bg-gray-500 hover:bg-gray-400'
						>
							{translate('configureNFC')}
						</button>

						<button
							type={'button'}
							onClick={() =>
								// alert(translate("notImplementedYet"))
								runAction('scanners', 'openQr', {
									callAction,
									onScan: (code) => setupQr(props?.data?.id, code),
								})
							}
							className='text-white rounded-md text-sm px-8 py-1.5 bg-gray-500 hover:bg-gray-400'
						>
							{translate('configureQR')}
						</button>
					</FormEntry>

					{!props?.data?.freshCreated && (
						<FormEntry inputClass='flex justify-end pt-3' label=''>
							{props?.data?.lockoutEnabled ? (
								<button
									type={'button'}
									onClick={() => changeUserStatusConfirmation(props?.data?.id, true)}
									className='text-white rounded-md text-sm my-4 px-10 py-1.5 bg-green-500 hover:bg-green-400'
								>
									{translate('Activate')}
								</button>
							) : (
								<button
									type={'button'}
									onClick={() => changeUserStatusConfirmation(props?.data?.id, false)}
									className='text-white rounded-md text-sm my-4 px-10 py-1.5 bg-red-500 hover:bg-red-400'
								>
									{translate('Deactivate')}
								</button>
							)}
						</FormEntry>
					)}
				</Form>
			</div>
		</Suspense>
	);
});

export default UserSettings;
