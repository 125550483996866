import HL7Settings from '../pages/General/HL7';
import TenantSettings from '../pages/General/Tenant';
import DispatcherSettings from '../pages/General/Tenant/pages/Dispatcher';
import ReservedSettings from '../pages/General/Tenant/pages/Reserved';

import PorterConfig from '../pages/General/PorterConfig';

import GenderTable from '../pages/GeneralContents/pages/Gender/parts/GenderTable';
import TitleTable from '../pages/GeneralContents/pages/Titles/parts/TitleTable';
import ADConfigurationTable from '../pages/SSO/ADConfiguration/parts/ADConfigurationTable';
import SetupView from '../pages/SSO/Setup';

const routes = [
	{
		header: 'general',
		key: 'General',
		items: [
			// {
			// 	name: "overview",
			// 	key: "Overview",
			// 	hash: "#overview",
			// 	component: Overview,
			// },
			{
				name: 'tenantSettings',
				key: 'TenantSettings',
				hash: '#tenantSettings',
				component: TenantSettings,
			},
			{
				name: 'reservedSettings',
				key: 'ReservedSettings',
				hash: '#reservedSettings',
				component: ReservedSettings,
			},
			{
				name: 'hl7Settings',
				key: 'HL7Settings',
				hash: '#hl7Settings',
				component: HL7Settings,
			},
			{
				name: 'dispatcherSettings',
				key: 'DispatcherSettings',
				hash: '#dispatcherSettings',
				component: DispatcherSettings,
			},
			{
				name: 'porterConfig',
				key: 'PorterConfig',
				hash: '#porterConfig',
				component: PorterConfig,
			},
		],
	},
	{
		header: 'generalContents',
		key: 'GeneralContents',
		items: [
			{
				name: 'genders',
				key: 'Genders',
				hash: '#genders',
				component: GenderTable,
			},
			{
				name: 'titles',
				key: 'Titles',
				hash: '#titles',
				component: TitleTable,
			},
		],
	},
	{
		header: 'SSO',
		key: 'SSO',
		items: [
			{
				name: 'setup',
				key: 'Setup',
				hash: '#ad-setup',
				component: SetupView,
			},

			{
				name: 'configuration',
				key: 'Configuration',
				hash: '#ad-config',
				component: ADConfigurationTable,
			},
		],
	},
];

export default routes;
