import { Button, ReSelect } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import EmployeeService from 'modules/persons/pages/Employees/services';
import { forwardRef, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';

const AllocateHandler = forwardRef(({ fetchAndLoad, service, items, isVisible, onClose = () => {} }, ref) => {
	const { translate } = useTranslations();

	const twoEmployees = items.some((item) => item?.twoEmployees);

	let teamId = null;
	//check if items has length and if on first item there is an object teams and is not null
	if (items.length === 0 || items[0]?.team) {
		teamId = items[0]?.team?.id;
	}

	const employeeService = new EmployeeService();
	const [employee, setEmployee] = useState(null);
	const [withEmployee, setWithEmployee] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const allocateHandler = () => {
		try {
			setIsLoading(true);

			service
				.allocate(
					items.map((item) => item?.id),
					employee,
					withEmployee,
				)
				.then((res) => {
					toast(translate('taskAllocatedSuccessfully'), {
						duration: 2000,
						icon: '✅',
					});
					fetchAndLoad();
					allocateDialogRef.current.close();
					setIsLoading(false);
				});
		} catch (error) {
			setIsLoading(false);
			console.error('Error on allocateHandler', error);
		}
	};

	const allocateDialogRef = useRef(null);

	useEffect(() => {
		if (isVisible) {
			allocateDialogRef.current.showModal();
		} else {
			allocateDialogRef.current.close();
		}
	}, [isVisible]);

	const closeHandler = () => {
		allocateDialogRef.current.close();
		onClose();
	};

	return (
		<dialog ref={allocateDialogRef} className='rounded-lg overflow-y-visible'>
			<div className='flex flex-col  px-5 pt-5 pb-5 bg-gray-50'>
				<div className='flex flex-row justify-between items-center w-96'>
					<div className='uppercase text-lg text-gray-500'>{translate('allocate')}</div>
					<i className='ri-close-line cursor-pointer text-md text-gray-500' onClick={closeHandler}></i>
				</div>
				{/* horizontal line */}
				<div className='border-b-2 border-gray-200 mt-2 mb-5'></div>
				<div className='text-gray-500 text-sm'>{translate('selectAnEmployeeToAllocateSelectedTasksTo')}</div>
				<ReSelect
					displayAll
					extraParams={teamId ? `teamId=${teamId}` : ''}
					listClass='relative'
					service={employeeService}
					onChange={(value) => {
						setEmployee(value);
					}}
				/>
				{twoEmployees && (
					<>
						<div className='text-gray-500 text-sm pt-5'>
							{translate('selectAnEmployeeToAssociateFirstEmployee')}
						</div>
						<ReSelect
							displayAll
							extraParams={
								teamId
									? `teamId=${teamId}&${employee ? 'ExcludeIds=' + employee : ''}`
									: `&${employee ? 'ExcludeIds=' + employee : ''}`
							}
							listClass='relative'
							service={employeeService}
							onChange={(value) => {
								setWithEmployee(value);
							}}
						/>
					</>
				)}
				<div className='pt-10 flex justify-end'>
					<Button
						isLoading={isLoading}
						onClick={() => allocateHandler()}
						color={'warning'}
						disabled={twoEmployees ? !employee || !withEmployee : !employee}
						className={`  rounded-md `}
					>
						<i className='ri-map-pin-range-line mr-2'></i>
						{translate('allocate')}
					</Button>
				</div>
			</div>
		</dialog>
	);
});

export default AllocateHandler;
