import { Form, FormEntry, InternalFormDivider } from 'components/form';
import Card from 'components/ui/Card';
import { useTranslations } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import MultiDateInput from './components/MultiDateInput';
import TenantUtils from './utils';

import { Button, CheckBox, Input, ReSelect, TextArea } from 'components/ui/Input';
import { LOCATION_TRUNCATE_LENGTH } from 'config/configs';
import { useCrud } from 'hooks';
import TenantService from './services/index';

const TenantSettingsView = ({ permissions }) => {
	const [settings, setSettings] = useState(null);

	const myForm = useRef(null);
	const { translate, getLanguages } = useTranslations();
	const service = new TenantService();
	const { create } = useCrud(service);
	const [isLoading, setIsLoading] = useState(false);
	const [languages, setLanguages] = useState([]);

	const isView = (permissions.View && !permissions.Update) || false;

	const fetchLanguages = async () => {
		const l_languages = await getLanguages();

		const langOptions = l_languages.map((lang) => {
			return { label: lang.name, value: lang.languageCode };
		});

		setLanguages(langOptions);
	};

	//handle the form submission
	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(myForm.current);
		const formDataObject = Object.fromEntries(formData.entries());
		setIsLoading(true);
		await postSettings(formDataObject, (res) => {
			setIsLoading(false);
			getSettings(false);
		});
	};

	const postSettings = async (data, onSuccess = () => {}) => {
		if (data) {
			return await create(data, {
				creationSuccessMessage: 'tenantSettingsUpdatedSuccessfully',
			}).then((res) => {
				onSuccess(res);
			});
		}
		return null;
	};

	useEffect(() => {
		getSettings();
		fetchLanguages();
	}, []);

	const getSettings = async () => {
		setIsLoading(true);
		let l_settings = await TenantUtils.getSettings(false);
		setIsLoading(false);

		if (l_settings) {
			if (!l_settings.locationNameDisplayLength) l_settings.locationNameDisplayLength = LOCATION_TRUNCATE_LENGTH;
			if (!l_settings.dateFormat) l_settings.dateFormat = getBrowserDateTimeFormat().dateFormat;
			if (!l_settings.timeFormat) l_settings.timeFormat = getBrowserDateTimeFormat().timeFormat;
			if (!l_settings.timezone) l_settings.timezone = getBrowserTimeZone();
		}

		setSettings(l_settings);
	};

	function getBrowserDateTimeFormat() {
		const dateFormatter = new Intl.DateTimeFormat(navigator.language);
		const timeFormatter = new Intl.DateTimeFormat(navigator.language, {
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
			hour12: false, // Ensures we detect 24-hour vs. 12-hour format
		});

		// Detect Date Format
		const dateParts = dateFormatter.formatToParts(new Date(2000, 11, 31));
		const dateMap = { day: 'DD', month: 'MM', year: 'YYYY' };
		const dateFormat = dateParts.map((p) => dateMap[p.type] || p.value).join('');

		// Detect Time Format
		const timeParts = timeFormatter.formatToParts(new Date(2000, 11, 31, 14, 30, 45));
		const timeMap = { hour: 'HH', minute: 'mm', second: 'ss' };
		const timeFormat = timeParts.map((p) => timeMap[p.type] || p.value).join('');

		return { dateFormat, timeFormat };
	}

	function getBrowserTimeZone() {
		const formatter = new Intl.DateTimeFormat('en-US', { timeZoneName: 'long' });
		const parts = formatter.formatToParts(new Date());
		const timeZone = parts.find((part) => part.type === 'timeZoneName')?.value;
		return timeZone || 'Unknown Timezone';
	}

	const twoFaPolicyOptions = [
		{ value: 1, label: 'off' },
		{ value: 2, label: 'optional' },
		{ value: 3, label: 'mandatory' },
	];

	return (
		<Card isLoading={isLoading} collapsible header={'tenantSettings'} defaultOpen={true}>
			<div>
				<Form ref={myForm} onSubmit={(e) => handleSubmit(e)}>
					<InternalFormDivider>{translate('generalSettings')}</InternalFormDivider>

					<FormEntry label={'language'}>
						<ReSelect
							isView={isView}
							required
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
							name='language'
							options={languages}
							defaultValue={settings?.language.toUpperCase() || null}
						/>
					</FormEntry>

					<FormEntry label={'twoFactorSiteTitle'}>
						<Input
							isView={isView}
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3 '
							placeholder={'twoFactorSiteTitle'}
							required={true}
							name='twoFactorSiteTitle'
							defaultValue={settings?.twoFactorSiteTitle || ''}
						/>
					</FormEntry>
					<FormEntry label={'nationalHolidays'}>
						<MultiDateInput
							isView={isView}
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3 '
							name='nationalHolidays'
							defaultValue={settings?.nationalHolidays}
						/>
					</FormEntry>

					<InternalFormDivider>{translate('dateTimeSettings')}</InternalFormDivider>
					<FormEntry label={'dateFormat'}>
						<Input
							isView={isView}
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
							placeholder={'dateFormat'}
							required={true}
							name='dateFormat'
							defaultValue={settings?.dateFormat}
						/>
					</FormEntry>
					<FormEntry label={'timeFormat'}>
						<Input
							isView={isView}
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
							placeholder={'timeFormat'}
							required={true}
							name='timeFormat'
							defaultValue={settings?.timeFormat}
						/>
					</FormEntry>
					<FormEntry label={'timezone'}>
						<Input
							isView={isView}
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
							placeholder={'timezone'}
							required={true}
							name='timezone'
							defaultValue={settings?.timezone}
						/>
					</FormEntry>
					<InternalFormDivider>{translate('patientSettings')}</InternalFormDivider>
					<FormEntry label={'showPatientIdInTasks'}>
						<CheckBox
							isView={isView}
							name='showPatientIdInTasks'
							label='showPatientIdInTasks'
							selected={settings?.showPatientIdInTasks || false}
						/>
					</FormEntry>
					<FormEntry label={'showPatientNameInTasks'}>
						<CheckBox
							isView={isView}
							name='showPatientNameInTasks'
							label='showPatientNameInTasks'
							selected={settings?.showPatientNameInTasks || false}
						/>
					</FormEntry>
					<FormEntry label={'showPatientNumberInTasks'}>
						<CheckBox
							isView={isView}
							name='showPatientNumberInTasks'
							label='showPatientNumberInTasks'
							selected={settings?.showPatientNumberInTasks || false}
						/>
					</FormEntry>
					<FormEntry label={'showAdmissionNumberInTasks'}>
						<CheckBox
							isView={isView}
							name='showAdmissionNumberInTasks'
							label='showAdmissionNumberInTasks'
							selected={settings?.showAdmissionNumberInTasks || false}
						/>
					</FormEntry>
					<FormEntry label={'showPatientGenderInTasks'}>
						<CheckBox
							isView={isView}
							name='showPatientGenderInTasks'
							label='showPatientGenderInTasks'
							selected={settings?.showPatientGenderInTasks || false}
						/>
					</FormEntry>
					<FormEntry label={'showPatientDateOfBirthInTasks'}>
						<CheckBox
							isView={isView}
							name='showPatientDateOfBirthInTasks'
							label='showPatientDateOfBirthInTasks'
							selected={settings?.showPatientDateOfBirthInTasks || false}
						/>
					</FormEntry>

					<InternalFormDivider>{translate('configuration')}</InternalFormDivider>

					<FormEntry label={'passwordExpiresInDays'}>
						<Input
							isView={isView}
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
							placeholder={'passwordExpiresInDays'}
							type='number'
							min={1}
							required={true}
							name='passwordExpiresInDays'
							defaultValue={settings?.passwordExpiresInDays || ''}
						/>
					</FormEntry>

					<FormEntry label={'changePasswordNextLogin'}>
						<CheckBox
							isView={isView}
							name='changePasswordNextLogin'
							label='changePasswordNextLogin'
							selected={settings?.changePasswordNextLogin || false}
						/>
					</FormEntry>

					<FormEntry label={'encryptPatientName'}>
						<CheckBox
							isView={isView}
							name='encryptPatientName'
							label='encryptPatientName'
							selected={settings?.encryptPatientName || false}
						/>
					</FormEntry>

					<FormEntry label={'loginTwoFaPolicy'}>
						<ReSelect
							isView={isView}
							required
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
							name='loginTwoFaPolicy'
							options={twoFaPolicyOptions}
							defaultValue={settings?.loginTwoFaPolicy || null}
						/>
					</FormEntry>

					<InternalFormDivider>{translate('taskSettings')}</InternalFormDivider>
					<FormEntry label={'tasksStartTimeOffset'}>
						<Input
							type='number'
							min={0}
							isView={isView}
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
							placeholder={'tasksStartTimeOffset'}
							name='tasksStartTimeOffset'
							defaultValue={settings?.tasksStartTimeOffset || ''}
						/>
					</FormEntry>
					<FormEntry label={'tasksEndTimeOffset'}>
						<Input
							type='number'
							min={0}
							isView={isView}
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
							placeholder={'tasksEndTimeOffset'}
							name='tasksEndTimeOffset'
							defaultValue={settings?.tasksEndTimeOffset || ''}
						/>
					</FormEntry>

					<FormEntry label={'enabledTaskReadyForDispatch'}>
						<CheckBox
							isView={isView}
							name='enabledTaskReadyForDispatch'
							label='enabledTaskReadyForDispatch'
							selected={settings?.enabledTaskReadyForDispatch || false}
						/>
					</FormEntry>
					<FormEntry label={'taskReadyForDispatchRunIntervalInMinutes'}>
						<Input
							isView={isView}
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
							placeholder={'taskReadyForDispatchRunIntervalInMinutes'}
							type='number'
							min={0}
							name='taskReadyForDispatchRunIntervalInMinutes'
							defaultValue={settings?.taskReadyForDispatchRunIntervalInMinutes}
							sideLabel={'mins'}
						/>
					</FormEntry>
					<FormEntry label={'taskNotificationEmailBody'}>
						<TextArea
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
							maxLength={5000}
							rows={4}
							placeholder={'taskNotificationEmailBody'}
							required={false}
							name='taskNotificationEmailBody'
							defaultValue={settings?.taskNotificationEmailBody || ''}
						/>
					</FormEntry>
					<InternalFormDivider>{translate('misc')}</InternalFormDivider>
					<FormEntry label={'locationNameDisplayLength'} helpText={'lengthOfTheLocationNameWillBeTruncated'}>
						<Input
							type='number'
							min={0}
							isView={isView}
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
							placeholder={'locationNameDisplayLength'}
							name='locationNameDisplayLength'
							defaultValue={settings?.locationNameDisplayLength}
						/>
					</FormEntry>

					{permissions.Update && (
						<tr>
							<td colSpan={2}>
								<div className='text-right p-10 pb-5'>
									<Button type='submit' isLoading={isLoading}>
										<i className='ri-save-line mr-2'></i>
										{translate('saveChanges')}
									</Button>
								</div>
							</td>
						</tr>
					)}
				</Form>
			</div>
		</Card>
	);
};

export default TenantSettingsView;
