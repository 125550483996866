import { Form, FormEntry, InternalFormDivider } from 'components/form';
import { AddressSelector, Input, ReSelect, TextArea } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useAside, useCrud, useForm, useTranslations } from 'hooks';
import GenderService from 'modules/settings/pages/generalSettings/pages/GeneralContents/pages/Gender/services';
import TaskTypeService from 'modules/tasks/pages/Manage/pages/TaskTypes/services';
import { Suspense, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { getToday } from 'utils';
import CustomFieldRenderer from '../components/CustomFieldRenderer';
import CustomFieldForm from './customField.form';

const PatientForm = forwardRef(({ isView = false, isAdmit = false, ...props }, ref) => {
	const { translate } = useTranslations();
	const { asideBuilder, helperAsideBuilder } = useAside();
	const { getOne } = useCrud(props.service);
	const { getData } = useForm();
	const myForm = useRef(null);
	const isEdit = props.data.id && !isAdmit ? true : false;

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [customFields, setCustomFields] = useState([]);

	const genderService = new GenderService();

	const taskTypeService = new TaskTypeService();

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setCustomFields(res?.patient?.fields || []);

				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	const addField = (field) => {
		setCustomFields((prev) => [...prev, field]);
	};

	const openCustomFieldsDialog = () => {
		helperAsideBuilder.setTitle(translate('addCustomField'));
		helperAsideBuilder.setComponent(CustomFieldForm);
		helperAsideBuilder.setOpen(true);
		helperAsideBuilder.setComponentProps({
			taskGroupId: props.taskGroupId,
			chooseField: (field) => {
				helperAsideBuilder.setOpen(false);
				addField(field);
			},
		});
		helperAsideBuilder.build();
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(myForm.current),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={props?.isClone ? false : props.data.id || false} />
					{props?.data?.patient?.id && (
						<input type='hidden' name='patient.id' value={props?.data?.patient?.id || false} />
					)}
					<InternalFormDivider>{translate('admission')}</InternalFormDivider>
					<FormEntry label='admissionNr'>
						<Input
							isView={isView}
							disabled={isEdit}
							type='text'
							placeholder={'admissionNr'}
							required={true}
							name='admissionNr'
							defaultValue={!isAdmit ? data?.admissionNr : null}
						/>
					</FormEntry>
					<FormEntry label='homeLocation'>
						<AddressSelector
							required={true}
							isView={isView}
							truncateAt={40}
							address={!isAdmit ? data?.homeLocation : null}
							name='homeLocationJson'
						/>
					</FormEntry>
					<InternalFormDivider>{translate('patient')}</InternalFormDivider>
					<FormEntry label='patientNr'>
						<Input
							isView={isView}
							disabled={isEdit || isAdmit}
							type='text'
							placeholder={'patientNr'}
							required={true}
							name='patient.patientNr'
							defaultValue={data?.patient?.patientNr}
						/>
					</FormEntry>

					<FormEntry label={'firstName'}>
						<Input
							isView={isView}
							disabled={isEdit || isAdmit}
							type='text'
							placeholder='John'
							name='patient.firstName'
							minLength={3}
							required={true}
							defaultValue={data?.patient?.firstName}
						/>
					</FormEntry>
					<FormEntry label={'lastName'}>
						<Input
							isView={isView}
							disabled={isEdit || isAdmit}
							type='text'
							placeholder='Doe'
							name='patient.lastName'
							required={true}
							defaultValue={data?.patient?.lastName}
						/>
					</FormEntry>
					<FormEntry label='gender'>
						<ReSelect
							isView={isView}
							disabled={isEdit || isAdmit}
							name='patient.genderId'
							defaultValue={data?.patient?.gender}
							service={genderService}
						/>
					</FormEntry>
					<FormEntry label='idNumber'>
						<Input
							isView={isView}
							disabled={isAdmit}
							type='text'
							placeholder={'idNumber'}
							name='patient.idNumber'
							defaultValue={data?.patient?.idNumber}
						/>
					</FormEntry>

					<FormEntry label='taskType'>
						<ReSelect
							isView={isView}
							disabled={isAdmit}
							extraParams='moduleId=1'
							name='patient.taskTypeId'
							service={taskTypeService}
							defaultValue={data?.patient?.taskType}
						/>
					</FormEntry>

					<InternalFormDivider>{translate('personalInformation')}</InternalFormDivider>
					<FormEntry label={'address'}>
						<TextArea
							isView={isView}
							disabled={isAdmit}
							minLength={3}
							rows={2}
							placeholder={'address'}
							required={false}
							name='patient.address'
							defaultValue={data?.patient?.address}
						/>
					</FormEntry>
					<FormEntry label='dateOfBirth'>
						<Input
							isView={isView}
							disabled={isEdit || isAdmit}
							type='date'
							placeholder={'dateOfBirth'}
							max={getToday()}
							name='patient.dateOfBirth'
							defaultValue={data?.patient?.dateOfBirth}
						/>
					</FormEntry>
					<FormEntry label='phoneNr'>
						<Input
							disabled={isAdmit}
							isView={isView}
							type='tel'
							placeholder={'phoneNr'}
							name='patient.phoneNr'
							defaultValue={data?.patient?.phoneNr}
						/>
					</FormEntry>
					<FormEntry label='email'>
						<Input
							disabled={isAdmit}
							isView={isView}
							type='email'
							placeholder={'email'}
							name='patient.email'
							defaultValue={data?.patient?.emailAddress}
						/>
					</FormEntry>

					<InternalFormDivider>{translate('insurance')}</InternalFormDivider>
					<FormEntry label='SSN'>
						<Input
							disabled={isAdmit}
							isView={isView}
							type='text'
							placeholder={'socialSecurityNumber'}
							required={true}
							name='patient.socialSecurityNumber'
							defaultValue={data?.patient?.socialSecurityNumber}
						/>
					</FormEntry>
					<FormEntry label='companyId'>
						<Input
							disabled={isAdmit}
							isView={isView}
							type='text'
							placeholder={'insuranceCompanyId'}
							name='patient.insuranceCompanyId'
							defaultValue={data?.patient?.insuranceCompanyId}
						/>
					</FormEntry>
					<FormEntry label='companyName'>
						<Input
							disabled={isAdmit}
							isView={isView}
							type='text'
							placeholder={'insuranceCompanyName'}
							name='patient.insuranceCompanyName'
							defaultValue={data?.patient?.insuranceCompanyName}
						/>
					</FormEntry>
					<InternalFormDivider>{translate('familyMember')}</InternalFormDivider>
					<FormEntry label={'fullName'}>
						<Input
							disabled={isAdmit}
							isView={isView}
							type='text'
							placeholder='John Doe'
							name='patient.familyMemberName'
							minLength={3}
							defaultValue={data?.patient?.familyMemberName}
						/>
					</FormEntry>

					<FormEntry label={'address'}>
						<TextArea
							disabled={isAdmit}
							isView={isView}
							minLength={3}
							rows={2}
							placeholder={'address'}
							required={false}
							name='patient.familyMemberHomeAddress'
							defaultValue={data?.patient?.familyMemberHomeAddress}
						/>
					</FormEntry>
					<FormEntry label='phoneNr'>
						<Input
							disabled={isAdmit}
							isView={isView}
							type='tel'
							placeholder={'phoneNr'}
							name='patient.familyMemberPhoneNr'
							defaultValue={data?.patient?.familyMemberPhoneNr}
						/>
					</FormEntry>
					<FormEntry label='email'>
						<Input
							disabled={isAdmit}
							isView={isView}
							type='email'
							placeholder={'email'}
							name='patient.familyMemberEmail'
							defaultValue={data?.patient?.familyMemberEmail}
						/>
					</FormEntry>

					<InternalFormDivider>{translate('custom')}</InternalFormDivider>
					<CustomFieldRenderer
						isView={isView}
						fields={customFields}
						taskGroupId={null}
						onRemoveField={(field) => {
							setCustomFields((prev) => prev.filter((f) => f.id !== field.id));
						}}
					/>
				</Form>

				{!isView && (
					<div
						onClick={() => openCustomFieldsDialog()}
						className='pt-3 pl-1 font-semibold cursor-pointer space-x-3'
					>
						<ri className='ri-add-line'></ri>
						<span>{translate('addField')}</span>
					</div>
				)}
			</div>
		</Suspense>
	);
});

export default PatientForm;
