import { NO_DATA_DISPLAY } from 'config/configs';
import { PortalContext } from 'contexts/PortalManager';
import { useTranslations } from 'hooks';
import AddressService from 'modules/addresses/services/addresses';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { runAction } from 'utils';
import SearchSelectInput from './SearchSelectInput';
import ViewInput from './internal/ViewInput';

const AddressSelector = forwardRef(
	(
		{
			address = null,
			defaultValue = null,
			name = '',
			error = '',
			onSelect = () => {},
			required = false,
			disabled = false,
			placeholder = 'searchAddress',
			initiallySelectDisabled = false,
			hideLabel = false,
			label = null,
			labelClassName = null,
			onChange = () => {},
			containerClassName = '',
			truncateAt = 72,
			isView = false,
			noBodyPayload = false,
			noHasMounted = false,
		},
		ref,
	) => {
		const { callAction } = useContext(PortalContext);
		const { translate } = useTranslations();

		const [selectedAddress, setSelectedAddress] = useState(address);
		const [tenantSettings, setTenantSettings] = useState(null);
		const [rootLevel, setRootLevel] = useState(6);

		const searchInputRef = useRef();
		const endReached = useRef(false);
		const metaRef = useRef({ Page: 1, PageSize: 10, SortBy: 'id', SortAsc: true });
		const searchTerm = useRef('');

		useEffect(() => {
			setSelectedAddress(convertKeysToLowercase(address || defaultValue));
		}, [address, defaultValue]);

		const getTenantSettings = async () => {
			try {
				const res = await runAction('tenants', 'getSettings');
				return res ?? null;
			} catch (err) {
				console.error(err);
				throw err;
			}
		};

		const getTenantLicenseSettings = async () => {
			try {
				const res = await runAction('tenants', 'getLicenseSettings');
				return res ?? null;
			} catch (err) {
				console.error(err);
				throw err;
			}
		};

		useEffect(() => {
			getTenantSettings().then((res) => {
				setTenantSettings(res);
			});

			getTenantLicenseSettings()
				.then((res) => {
					if (res) {
						setRootLevel(decideRootLevel(res.hasGroupsLocation, res.hasCampusesLocations));
					}
				})
				.catch((err) => {
					setRootLevel(6);
				});
		}, []);

		const decideRootLevel = (hasGroupsLocation = true, hasCampusesLocations) => {
			if (!hasGroupsLocation) {
				if (!hasCampusesLocations) {
					return 4;
				}
				return 3;
			}
			return 6;
		};

		const selectAddress = (address) => {
			if (searchInputRef.current) {
				const leafAddress = getAddress(address);
				const optionAddress = {
					id: JSON.stringify({ id: leafAddress.id, level: leafAddress.level }),
					label: getAddressString(address),
				};
				searchInputRef.current.setActiveSelectedOption(optionAddress);
			}
			setSelectedAddress(address);
			onSelect(address);

			onChange(address);
		};

		function convertKeysToLowercase(obj) {
			const newObj = {};
			if (!obj) return obj;
			Object.keys(obj).forEach((key) => {
				let newKey = key;
				if (key[0] === key[0].toUpperCase()) {
					newKey = key[0].toLowerCase() + key.slice(1);
				}
				if (typeof obj[key] === 'object' && obj[key] !== null) {
					newObj[newKey] = convertKeysToLowercase(obj[key]);
				} else {
					newObj[newKey] = obj[key];
				}
			});
			return newObj;
		}

		function getAddressString(address) {
			if (address?.child) {
				if (address.level > rootLevel) {
					return getAddressString(address.child);
				}
				return `${address.name} > ${getAddressString(address.child)}`;
			} else {
				return address?.name || NO_DATA_DISPLAY;
			}
		}

		const getAddress = (address) => {
			if (address) {
				if (address.child) {
					return getAddress(address.child);
				} else {
					return address;
				}
			}
			return null;
		};

		const populateId = (option) => {
			return JSON.stringify({ id: option.id, level: option.level });
		};

		const mapOptions = (options) => {
			if (!options) return;
			const toReturn = [];
			options.forEach((option) => {
				toReturn.push({ id: populateId(option), label: option.name });
				if (option.children) {
					option.children.forEach((child) => {
						toReturn.push({
							id: populateId(child),
							label: `${option.name}->${child.name}`,
							html: `<div class='pl-1'>${child.name} <span class='text-xs text-gray-500'>(${option.name})</span></div>`,
						});
					});
				}
			});
			return toReturn;
		};

		const handleSearch = async (term) => {
			endReached.current = false;
			metaRef.current.Page = 1;
			searchTerm.current = term;
			const options = await AddressService.search(metaRef.current, term);
			return mapOptions(options?.data);
		};

		const handleOptionSelect = async (option) => {
			let newOption = { ...option };

			const addressObj = JSON.parse(option.id);

			const addressTree = await AddressService.getPath(addressObj.id, addressObj.level);
			if (addressTree?.data) {
				setSelectedAddress(addressTree.data);
				if (noBodyPayload) {
					const leaf = getAddress(addressTree.data);
					onChange({ id: leaf.id, level: leaf.level });
				} else {
					onChange(addressTree.data);
				}

				newOption.label = getAddressString(addressTree.data);
				return newOption;
			}
		};

		const handleOptionClear = () => {
			setSelectedAddress(null);
			onSelect(null);
			onChange(null);
		};

		const handleEndReached = async () => {
			if (endReached.current) return [];
			metaRef.current.Page += 1;
			const options = await AddressService.search(metaRef.current, searchTerm.current);

			if (options.data.length > 0) {
				return mapOptions(options?.data);
			} else {
				endReached.current = true;
				return [];
			}
		};
		if (isView) {
			return <ViewInput value={getAddressString(selectedAddress)} />;
		}

		return (
			<>
				{!hideLabel && label && (
					<label className={`block mb-1 text-xs font-medium text-gray-700 ${labelClassName}`}>
						{translate(label || '', true)}
					</label>
				)}
				<SearchSelectInput
					ref={searchInputRef}
					name={name}
					onEndReached={handleEndReached}
					onOptionSelectAction={handleOptionSelect}
					onSearchInput={handleSearch}
					onClear={handleOptionClear}
					required={required}
					disabled={disabled}
					placeholder={placeholder}
					containerClassName={containerClassName}
					defaultValue={
						selectedAddress
							? { id: populateId(getAddress(selectedAddress)), label: getAddressString(selectedAddress) }
							: null
					}
					onSideButtonClick={() => {
						!disabled &&
							runAction('addresses', 'openAddressSelect', {
								callAction,
								data: {
									selected: selectedAddress,
									initiallySelectDisabled,
								},
								onSelect: (s) => selectAddress(s.formatted),
							});
					}}
					truncateLength={truncateAt}
				/>
			</>
		);
	},
);
export default AddressSelector;
