import { Form, FormEntry } from 'components/form';
import { Input, SearchSelect, TextArea } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useCrud, useForm } from 'hooks';
import DepartmentService from 'modules/addresses/pages/settings/pages/Departments/services';
import UserService from 'modules/persons/pages/Users/pages/Users/services';
import TaskTypeService from 'modules/tasks/pages/Manage/pages/TaskTypes/services';
import CustomFieldService from 'modules/tasks/pages/Settings/pages/CustomFields/services';
import { forwardRef, Suspense, useEffect, useImperativeHandle, useRef, useState } from 'react';

const ExclusionForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { getOne } = useCrud(props.service);
	const { getData } = useForm();

	const departmentService = new DepartmentService();
	const userService = new UserService();
	const customFieldService = new CustomFieldService();
	const taskTypeService = new TaskTypeService();

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	const myForm = useRef(null);

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(myForm.current),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form
					ref={myForm}
					onSubmit={(e) => {
						e.preventDefault();
					}}
				>
					<input type='hidden' name='id' value={data?.id || false} />
					<FormEntry label='name'>
						<Input
							isView={isView}
							type='text'
							placeholder={'name'}
							required={true}
							name='name'
							defaultValue={data?.name}
						/>
					</FormEntry>
					<FormEntry label={'description'}>
						<TextArea
							isView={isView}
							minLength={3}
							rows={4}
							placeholder={'description'}
							required={false}
							name='description'
							defaultValue={data?.description}
						/>
					</FormEntry>
					<FormEntry label={'users'}>
						<SearchSelect
							multiple
							service={userService}
							isView={isView}
							name='userIds'
							defaultSelected={data?.userIds}
						/>
					</FormEntry>
					<FormEntry label={'department'}>
						<SearchSelect
							multiple
							service={departmentService}
							isView={isView}
							name='departmentIds'
							isSingleValueOnSelectAll
							defaultSelected={data?.departmentIds}
						/>
					</FormEntry>
					<FormEntry label={'taskTypes'}>
						<SearchSelect
							multiple
							service={taskTypeService}
							isView={isView}
							name='taskTypeIds'
							isSingleValueOnSelectAll
							defaultSelected={data?.taskTypeIds}
						/>
					</FormEntry>

					<FormEntry label={'customFields'}>
						<SearchSelect
							multiple
							service={customFieldService}
							isView={isView}
							name='customFieldIds'
							isSingleValueOnSelectAll
							defaultSelected={data?.customFieldIds}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default ExclusionForm;
