import { useTranslations } from 'hooks';
import { forwardRef, useEffect, useState } from 'react';
import Chart from '..';
import DashboardService from '../../../../services';
import Settings from '../components/ChartSettings';
import chartColors from '../configs/chartColors';
import { convertData, exportDataToCSV } from '../utils/chartHelper';
import Filter from './Filters';

const TaskStatsPerEmployeeChart = forwardRef(
	({ isDraggable = false, zIndex = 1, key = '1', onRemove = () => {}, isExportable = false, ...props }, ref) => {
		const { translate } = useTranslations();
		const dashboardService = new DashboardService();
		const DEFAULT_FILTER_DATA = {
			taskStatusIds: [5, 25],
		};
		const CHART_EXPORT_MAP = {
			label: { label: 'Team', order: 0 },
			count: { label: 'Nr. of Tasks', order: 1 },
			average: { label: 'Average', order: 2 },
		};

		const [data, setData] = useState(null);
		const [renderTime, setRenderTime] = useState(() => new Date());
		const [filterVisible, setFilterVisible] = useState(false);
		const [settingsVisible, setSettingsVisible] = useState(false);
		const [legendsVisible, setLegendsVisible] = useState(true);
		const [filterData, setFilterData] = useState(null);

		const fetchData = async () => {
			let preparedFilterData = convertData(DEFAULT_FILTER_DATA);

			if (filterData) {
				preparedFilterData = convertData(filterData);
			}

			const result = await dashboardService.getChartData([
				...preparedFilterData,
				{ key: 'showByTeam', value: false },
			]);
			setData(result.data);
			setRenderTime(new Date());
		};

		const legends = [
			{
				key: 'average',
				label: translate('average'),
				type: 'monotone',
				stroke: chartColors.orange,
				chartType: 'Line',
			},
			{
				key: 'count',
				label: translate('count'),
				fill: chartColors.blue,
				chartType: 'Bar',
				maxBarSize: 25,
				activeBar: {
					fill: chartColors.blueDark,
					stroke: chartColors.blue,
				},
			},
		];

		const FilterComponent = () => {
			return (
				<Filter
					display={filterVisible}
					onCancel={() => {
						setFilterVisible(false);
					}}
					data={filterData || DEFAULT_FILTER_DATA}
					onSubmit={(data) => {
						setFilterData(data);
						setFilterVisible(false);
					}}
				/>
			);
		};

		const SettingsComponent = () => {
			return (
				<Settings
					display={settingsVisible}
					onCancel={() => {
						setSettingsVisible(false);
					}}
					onShowLegendsClick={() => {
						setLegendsVisible(!legendsVisible);
					}}
					onRemove={onRemove}
					showLegends={legendsVisible}
				/>
			);
		};

		useEffect(() => {
			fetchData();
		}, [filterData]);

		const filterClickHandler = () => {
			if (!filterVisible) {
				setFilterVisible(true);
				setSettingsVisible(false);
				return;
			}

			setFilterVisible(false);
		};

		const settingsClickHandler = () => {
			if (!settingsVisible) {
				setSettingsVisible(true);
				setFilterVisible(false);
				return;
			}

			setSettingsVisible(false);
		};

		return (
			<Chart
				ref={ref}
				className={isDraggable ? 'cursor-move' : ''}
				data={data}
				legends={legends}
				renderTime={renderTime}
				onRefresh={fetchData}
				key={key}
				data-grid-id={key}
				header='tasks'
				description='tasksPerformedByEmployeesInTimeInterval'
				FilterComponent={FilterComponent}
				SettingsComponent={SettingsComponent}
				filterActive={filterData !== null}
				onFilterClick={filterClickHandler}
				onSettingsClick={settingsClickHandler}
				displayLegends={legendsVisible}
				isExportable={isExportable}
				onExport={() => exportDataToCSV(data, CHART_EXPORT_MAP)}
				{...props}
				style={{ ...props.style, zIndex: zIndex }}
			/>
		);
	},
);

export default TaskStatsPerEmployeeChart;
