import { Form, FormEntry } from 'components/form';
import { Button, ReSelect } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import toast from 'react-hot-toast';

const UserSettingsPortal = forwardRef((props, ref) => {
	const [show, setShow] = useState(false);
	const { translate, getLanguages } = useTranslations();
	const [user, setUser] = useState();
	const [data, setData] = useState([]);
	const onFinishCallback = useRef(null);
	const myForm = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [languages, setLanguages] = useState([]);

	const closeAndReset = () => {
		setShow(false);

		if (onFinishCallback.current) {
			onFinishCallback.current();
			onFinishCallback.current = null;
		}
	};

	const fetchLanguages = async () => {
		const l_languages = await getLanguages();

		const langOptions = l_languages.map((lang) => {
			return { label: lang.name, value: lang.languageCode };
		});

		setLanguages(langOptions);
	};

	useImperativeHandle(ref, () => ({
		callAction: (action, data, callback) => {
			switch (action) {
				case 'open':
					setShow(true);
					setData(data?.settings);
					setUser(data?.user);

					onFinishCallback.current = callback;
					break;
				default:
					throw Error(`No action named ${action} for portal ${props.id}`);
			}
		},
	}));

	useEffect(() => {
		// getSettings();
		fetchLanguages();
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(myForm.current);
		const formDataObject = Object.fromEntries(formData.entries());
		setIsLoading(true);
		await onFinishCallback.current(formDataObject).then((resp) => {
			if (resp.succeeded) {
				toast(translate('userSettingsHasBeenSavedSuccessfully'), {
					duration: 2000,
					icon: '✅',
				});
			} else {
				toast(translate('userSettingsFailedToSave'), {
					duration: 2000,
					icon: '❌',
				});
			}
		});
		setIsLoading(false);
		closeAndReset();
		onFinishCallback.current = null;
	};

	return (
		<>
			{show &&
				createPortal(
					<div
						onClick={() => closeAndReset()}
						className='absolute z-50 top-0 bottom-0 right-0 left-0 bg-slate-800/50 flex justify-center items-center'
					>
						<div
							onClick={(e) => e.stopPropagation()}
							className='relative flex flex-col h-fit w-3/4 sm:w-2/3 md:w-3/5 lg:w-1/3 rounded-lg bg-white px-4 pt-4'
						>
							<div
								className='flex justify-end cursor-pointer hover:opacity-50'
								onClick={() => closeAndReset()}
							>
								<i className='ri-close-line'></i>
							</div>
							<div className='flex flex-col items-center justify-center pt-2'>
								<div className='bg-primary-800 text-white rounded-full w-16 h-16 flex items-center justify-center text-2xl'>
									{user?.userName?.charAt(0).toUpperCase()}
								</div>
								<div className='pt-1 text-sm font-thin text-gray-500'>@{user.userName}</div>
								<div className=' font-semibold text-xl text-gray-600'>
									{user.name} {user.lastName}
								</div>
							</div>

							<div className='pt-8 p-5'>
								<Form ref={myForm} onSubmit={(e) => handleSubmit(e)}>
									<input type='hidden' name='timezone' defaultValue={data?.timezone} />
									<input type='hidden' name='dateFormat' defaultValue={data?.dateFormat} />
									<input type='hidden' name='timeFormat' defaultValue={data?.timeFormat} />
									<FormEntry required label={'language'}>
										<ReSelect
											required
											name='language'
											options={languages}
											defaultSelected={data?.language?.toUpperCase()}
										/>
									</FormEntry>

									<tr>
										<td colSpan={2}>
											<div className='text-right pt-5 pb-2'>
												<Button type='submit' isLoading={isLoading}>
													<i className='ri-save-line mr-2'></i>
													{translate('saveChanges')}
												</Button>
											</div>
										</td>
									</tr>
								</Form>
							</div>
						</div>
					</div>,
					document.body,
				)}
		</>
	);
});
export default UserSettingsPortal;
