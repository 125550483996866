import { configs } from 'config';
import localforage from 'localforage';
import { useEffect, useState } from 'react';
import tokenService from 'services/token/token.service';
import DataGridService from '../services';

/**
 * StorageHandler is a utility for managing data schemas using localforage and a data grid service.
 * @returns {Object} An object containing methods to store, retrieve, and manage schemas.
 */
const StorageHandler = (user_id = null) => {
	const service = new DataGridService();
	const userId = user_id || tokenService.getUser()?.id;

	const [isInitialized, setIsInitialized] = useState(false);
	const [initError, setInitError] = useState(null);

	const debugMode = false;

	const init = async () => {
		if (!isInitialized) {
			localforage.config(configs.localforageConfig);
			return await localforage.ready();
		}
		return;
	};

	useEffect(() => {
		const initialize = async () => {
			try {
				await init();
				if (debugMode) console.log('LocalForage initialized successfully.');
				setIsInitialized(true);
			} catch (error) {
				console.error('Failed to initialize LocalForage:', error);
				setInitError(error);
			}
		};

		initialize();
	}, []);

	if (initError) {
		// Optionally, handle initialization errors globally or notify the user
		console.error('Storage initialization failed:', initError);
	}

	/**
	 * Stores a schema in both the data grid service and localforage.
	 * @param {string} tableName - The name of the table.
	 * @param {Object} data - The schema data to store.
	 * @returns {Promise} A promise that resolves when the schema is stored.
	 */
	const storeSchema = async (tableName, data) => {
		await init();

		let newData = data;
		if (typeof data === 'function') {
			const previousSchema = await getSchema(tableName);
			newData = data(previousSchema || {});
		}

		const jsonData = JSON.stringify(newData);

		try {
			await service.update(tableName, jsonData);
			await localforage.setItem(`${tableName}-${userId}`, jsonData);
			if (debugMode) console.log(`Schema for ${tableName} stored successfully.`);
		} catch (error) {
			console.error(`Error storing schema for ${tableName}:`, error);
			throw error;
		}
	};

	/**
	 * Retrieves all data grids from the service and stores them in localforage.
	 * @returns {Promise} A promise that resolves when all schemas are stored.
	 */
	const fetchAllSchemas = async (l_userId = null) => {
		await init();
		const user_id = l_userId || userId;

		const { data } = await service.getAll();

		data.forEach(async (item) => {
			await localforage.setItem(`${item.dataGridName}-${user_id}`, item.context);
		});
	};

	/**
	 * Retrieves a schema from localforage.
	 * @param {string} tableName - The name of the table.
	 * @returns {Promise<Object>} A promise that resolves to the schema data.
	 */
	const getSchema = async (tableName) => {
		await init();

		try {
			const schema = await localforage.getItem(`${tableName}-${userId}`);
			if (debugMode) console.log(`Retrieved schema for ${tableName}:`, schema);
			return schema ? JSON.parse(schema) : null;
		} catch (error) {
			console.error(`Error retrieving schema for ${tableName}:`, error);
			throw error;
		}
	};

	/**
	 * Checks if a schema exists in localforage.
	 * @param {string} tableName - The name of the table.
	 * @returns {Promise<boolean>} A promise that resolves to true if the schema exists, otherwise false.
	 * @throws {Error} Throws an error if storage is not initialized.
	 * @throws {Error} Throws an error if an error occurs while retrieving the schema.
	 */

	const hasSchema = async (tableName) => {
		await init();

		try {
			const schema = await localforage.getItem(`${tableName}-${userId}`);
			return schema !== null;
		} catch (error) {
			console.error(`Error retrieving schema for ${tableName}:`, error);
			throw error;
		}
	};

	/**
	 * Removes a schema from both the data grid service and localforage.
	 * @param {string} tableName - The name of the table.
	 * @returns {Promise} A promise that resolves when the schema is removed.
	 */
	const removeSchema = async (tableName) => {
		await service.remove(tableName);
		return await localforage.removeItem(`${tableName}-${userId}`);
	};

	/**
	 * Clears all schemas from both the data grid service and localforage.
	 * @returns {Promise} A promise that resolves when all schemas are cleared.
	 */
	const clearAllSchemas = async () => {
		await service.remove();
		return await localforage.clear();
	};

	const clearAllBrowserSchemas = async () => {
		return await localforage.clear();
	};

	return {
		storeSchema,
		fetchAllSchemas,
		getSchema,
		removeSchema,
		clearAllSchemas,
		clearAllBrowserSchemas,
		hasSchema,
		isInitialized,
		init,
	};
};

export default StorageHandler;
