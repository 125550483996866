import { Button } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import { useRef, useState } from 'react';

const HelperAside = ({
	open = false,
	right = false,
	title = 'Menu',
	onClose = () => {},
	Component = null,
	componentKey = '',
	componentProps = {},
	hideSaveButton = true,
	parentWidth = 32,
	saveCallback = (data) => {},
}) => {
	const { translate } = useTranslations();
	const [loading, setLoading] = useState(false);
	const componentRef = useRef(null);
	const widthClass = {
		32: {
			right: 'lg:right-[32rem]',
			left: 'lg:left-[32rem]',
			translateRight: 'translate-x-[64rem]',
			translateLeft: '-translate-x-[64rem]',
			width: 'w-full lg:w-[32rem]',
		},
		40: {
			right: 'lg:right-[40rem]',
			left: 'lg:left-[40rem]',
			translateRight: 'translate-x-[80rem]',
			translateLeft: '-translate-x-[80rem]',
			width: 'w-full lg:w-[40rem]',
		},
		48: {
			right: 'lg:right-[48rem]',
			left: 'lg:left-[48rem]',
			translateRight: 'translate-x-[96rem]',
			translateLeft: '-translate-x-[96rem]',
			width: 'w-full lg:w-[48rem]',
		},
		54: {
			right: 'lg:right-[54rem]',
			left: 'lg:left-[54rem]',
			translateRight: 'translate-x-[108rem]',
			translateLeft: '-translate-x-[108rem]',
			width: 'w-full lg:w-[54rem]',
		},
		56: {
			right: 'lg:right-[56rem]',
			left: 'lg:left-[56rem]',
			translateRight: 'translate-x-[112rem]',
			translateLeft: '-translate-x-[112rem]',
			width: 'w-full lg:w-[56rem]',
		},
		64: {
			right: 'lg:right-[64rem]',
			left: 'lg:left-[64rem]',
			translateRight: 'translate-x-[128rem]',
			translateLeft: '-translate-x-[128rem]',
			width: 'w-full lg:w-[64rem]',
		},
	};

	const classes = {
		default: right
			? `right-0 ${widthClass[parentWidth].right} border-gray-200 border-l`
			: `left-0 ${widthClass[parentWidth].left}  border-gray-200 border-r`,
		close: right
			? `${widthClass[parentWidth].translateRight}  opacity-0`
			: `${widthClass[parentWidth].translateLeft}  -ml-20`,
	};

	const _doSubmitFromButton = async () => {
		if (componentRef.current?.runSubmit) {
			componentRef.current?.runSubmit();
		} else {
			_doSubmit();
		}
	};

	const _doSubmit = async (formData = null) => {
		setLoading(true);
		const data = formData || componentRef.current?.getData();
		if (data) {
			await saveCallback(data);
			onClose();
			componentRef.current?.clear();
		}
		setLoading(false);
	};

	return (
		<>
			{/* Main Side Menu */}
			<div
				className={`${open ? '' : classes.close} fixed top-0 md:top-12 ${
					classes.default
				} bottom-0 z-50 lg:z-30 ${
					widthClass[parentWidth].width
				}  p-4 pr-2 h-full flex flex-col transition-transform ease-in-out duration-300 bg-white shadow-xl`}
			>
				<div className='flex flex-row justify-between pr-2'>
					<div className='flex flex-row items-center'>
						<button
							type='button'
							onClick={onClose}
							className='text-gray-400 bg-transparent -ml-1 mr-1 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center'
						>
							<i className='ri-close-line text-lg'></i>
							<span className='sr-only'>Close menu</span>
						</button>
						<h5 id='drawer-navigation-label' className='text-base font-semibold text-gray-500 uppercase'>
							{title}
						</h5>
					</div>
				</div>

				<div className='py-4 h-full overflow-y-auto pr-2'>
					{Component && (
						<Component componentOpen={open} key={componentKey} ref={componentRef} {...componentProps} />
					)}
				</div>

				{!hideSaveButton && (
					<div className='py-1 flex flex-row-reverse justify-between items-center mb-1 md:mb-12 mr-5'>
						<Button disabled={loading} onClick={_doSubmitFromButton}>
							{translate('save')}
						</Button>
					</div>
				)}
			</div>
		</>
	);
};

export default HelperAside;
