import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

const PieChartComponent = ({
	data,
	legends,
	displayLegends = true,
	xAxisLabel = 'label',
	yAxisLabel = null,
	xAxisProps = {},
}) => {
	const [isLoading, setLoading] = useState(true);
	const [chartData, setChartData] = useState([]);
	const getLegend = (key) => {
		return legends.find((legend) => legend.key === key);
	};

	useEffect(() => {
		if (legends && data) {
			const newData = data
				.map((item) => {
					return Object.keys(item).map((key) => {
						const legend = getLegend(key);
						if (!legend) {
							return null;
						}
						return {
							name: legend?.label || key,
							value: item[key] || 0,
							fill: legend?.color || '#8884d8',
						};
					});
				})
				.filter((item) => item !== null);

			setChartData(newData);

			setLoading(false);
		}
	}, [data, legends]);

	const renderLegend = (props) => {
		const { payload } = props;
		return (
			<ul className='flex space-x-4'>
				{payload.map((entry, index) => (
					<li key={`item-${index}`} className='flex items-center space-x-2'>
						<div
							style={{
								width: 5,
								height: 30,
								backgroundColor: entry.color,
							}}
						></div>
						<span className='text-xs'>{getLegend(entry.value)?.label || entry.value}</span>
					</li>
				))}
			</ul>
		);
	};

	const tooltipFormatter = (value, name) => {
		return [value, getLegend(name)?.label || name];
	};

	if (isLoading) {
		return <></>;
	}

	return (
		<ResponsiveContainer width='100%' height={390}>
			<PieChart width={400} height={150}>
				<Tooltip formatter={tooltipFormatter} />
				{displayLegends && <Legend content={renderLegend} />}
				{chartData?.map((cd, index) => {
					return <Pie key={index} data={cd} dataKey='value' cx='50%' cy='50%' outerRadius={100} label />;
				})}
			</PieChart>
		</ResponsiveContainer>
	);
};

PieChartComponent.propTypes = {
	data: PropTypes.object.isRequired,
	legends: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		}),
	).isRequired,
	displayLegends: PropTypes.bool,
	xAxisLabel: PropTypes.string,
	yAxisLabel: PropTypes.string,
	xAxisProps: PropTypes.object,
};

export default PieChartComponent;
