import Card from 'components/ui/Card';
import { Table } from 'components/ui/Table';
import { CrudOperations } from 'hoc';
import { useCrud, useTranslations } from 'hooks';
import { useEffect, useState } from 'react';
import DocumentationForm from '../forms/documentation.form';
import DocumentationModel from '../models';
import DocumentationService from '../services';

const Tables = ({ taskGroupId = -1, permissions }) => {
	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const service = new DocumentationService();
	const model = new DocumentationModel();
	model.setExportable(permissions.Export);
	service.setParent(taskGroupId);

	const { getAll, getExport, create, update, remove } = useCrud(service);
	const { translate } = useTranslations();

	const fetchAndLoad = async (l_queryString = null) => {
		setIsLoading(true);

		getAll(l_queryString || queryString).then((res) => {
			setIsLoading(false);
			setData(res);
		});
	};

	const { openCreate, openEdit, openView } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: DocumentationForm,
		componentName: model.getModelName(),
		permissions: permissions,
	});

	const rowClickHandler = (row) => {
		if (permissions.Update) {
			openEdit(row);
		} else if (permissions.View) {
			openView(row);
		}
	};

	useEffect(() => {
		fetchAndLoad();
	}, [taskGroupId]);

	return (
		<Card
			collapsible
			defaultOpen={true}
			header={model.getPluralModelName()}
			headerButtonLabel={permissions.Create && `+ ${translate('createDocumentation')}`}
			headerButtonClick={openCreate}
		>
			<Table
				isLoading={isLoading}
				model={model}
				onExportClick={async (queryString) => {
					return await getExport(queryString, model.getPluralModelName());
				}}
				onRowClick={rowClickHandler}
				meta={data.meta}
				data={data.data}
				onTableRequestChange={(queryString) => {
					setQueryString(queryString);
					fetchAndLoad(queryString);
				}}
			/>
		</Card>
	);
};
export default Tables;
