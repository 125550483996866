import { NO_DATA_DISPLAY } from 'config/configs';
import { TruncatedTextRender } from 'core/renders';
import { StringColumn } from './baseColumns';

class SimpleObjectListColumn extends StringColumn {
	constructor(name, keyToRender = 'name', options = {}) {
		super(options.name || name, options);
		this.noDataValue = NO_DATA_DISPLAY;
		this.key = options.key || name;
		this.label = options.label || name;
		this.title = options.title || null; //this will display in (title) attribute of the column when selecting column
		this.sortKey = options.sortKey || name;
		this.hidden = options.hidden || false;
		this.truncateLength = options.truncateLength || 32;

		this.render = (value) => {
			if (!value) return this.noDataValue;
			const stringVal = value.map((item) => item[keyToRender]).join(', ');
			return <TruncatedTextRender value={stringVal} num={this.truncateLength} />;
		};
		this.filter = options.filter || null;
		this.order = options.order || 0;
	}
}
export default SimpleObjectListColumn;
