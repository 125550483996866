import { forwardRef, useRef } from 'react';
import MultipleSelectInput from './MultipleSelect';
import SingleSelectInput from './SingleSelect';

const SearchSelect = forwardRef(({ multiple = false, service = null, ...props }, ref) => {
	const endReached = useRef(false);
	const metaRef = useRef({ Page: 1, PageSize: 50, SortBy: 'id', SortAsc: true });
	const searchTerm = useRef('');

	const handleSearch = async (term) => {
		endReached.current = false;
		metaRef.current.Page = 1;
		searchTerm.current = term;
		const options = await service?.searchOptionsList(metaRef.current, [{ key: 'Name', value: term || '' }]);
		return options;
	};

	const handleEndReached = async () => {
		if (endReached.current) return [];
		metaRef.current.Page += 1;
		const options = await service?.searchOptionsList(metaRef.current, [
			{
				key: 'Name',
				value: searchTerm.current || '',
			},
		]);

		if (options?.data?.length > 0) {
			return options;
		} else {
			endReached.current = true;
			return null;
		}
	};

	const handleLabelNotFound = async (notFoundIds) => {
		const options = await service?.searchOptionsList(
			{ ...metaRef.current, Page: 1, PageSize: notFoundIds?.length + 1 },
			[{ key: 'ids', value: notFoundIds }],
		);

		return options?.data;
	};

	const onEndReachedProp = props.handleEndReached ? props.handleEndReached : service ? handleEndReached : null;
	const onSearchInputProp = props.handleSearch ? props.handleSearch : service ? handleSearch : null;
	const onLabelNotFound = props.handleLabelNotFound
		? props.handleLabelNotFound
		: service
		? handleLabelNotFound
		: null;

	if (multiple) {
		return (
			<MultipleSelectInput
				{...props}
				onSearchInput={onSearchInputProp}
				onEndReached={onEndReachedProp}
				isLoadingInitialState={service ? true : false}
				onLabelNotFoundPromise={onLabelNotFound}
				ref={ref}
			/>
		);
	}
	//TODO: DO the same for single select
	return <SingleSelectInput {...props} ref={ref} />;
});
export default SearchSelect;
