import { AddressSelector } from 'components/ui/Input';
import { NO_DATA_DISPLAY } from 'config/configs';
import { ActionColumn, AddressColumn, IdColumn, NameColumn, SimpleObjectColumn, StatusColumn } from 'core/columns';
import { DateColumn, NumberColumn } from 'core/columns/baseColumns';
import BaseModel from 'core/models/BaseModel';
import { StatusRender } from 'core/renders';
import BadgeRender from 'core/renders/components/BadgeRender';
import DetailsRender from '../components/ModelRenders/DetailsRender';
import ETARender from '../components/ModelRenders/EtaRender';
import PatientRender from '../components/ModelRenders/PatientRender';
import PlannedDepartureRender from '../components/ModelRenders/PlannedDepartureRender';
import ReSelectFilterRender from '../components/ModelRenders/ReSelectFilterRender';
import ScheduledDepartureRender from '../components/ModelRenders/ScheduleDepartureRender';
import TaskInformationRender from '../components/ModelRenders/TaskInformationRender';
import UserRender from '../components/ModelRenders/UserRender';

class TaskModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'task';
		this.pluralModelName = 'tasks';
		this.canBeRefreshed = true;
	}

	initializeColumns() {
		this.columns.push(
			new ActionColumn((d, itemData) => <TaskInformationRender row={itemData} />, {
				name: 'informationAction',
				label: 'I',
			}),
		);

		this.columns.push(
			new DateColumn('scheduledDeparture', {
				label: 'plannedDeparture',
				filter: {
					type: 'dateRange',
					key: 'dateRangePicker',
					isParam: false,
					isArray: false,
					fromDateKey: 'fromDate',
					toDateKey: 'toDate',
					toExcludeOnApply: [
						{
							on: 'fromDate',
							exclude: 'PlannedDate',
						},
						{
							on: 'toDate',
							exclude: 'PlannedDate',
						},
					],
				},
				render: (value, row) => <PlannedDepartureRender value={value} row={row} />,
			}),
		);
		this.columns.push(
			new DateColumn('scheduledArrival', {
				label: 'scheduled',
				render: (value, row) => <ScheduledDepartureRender value={value} row={row} />,
			}),
		);
		this.columns.push(
			new DateColumn('estimatedArrivalTime', {
				label: 'ETA',
				format: {
					hour: '2-digit',
					minute: '2-digit',
				},
				render: (value, row) => <ETARender value={value} row={row} />,
			}),
		);
		this.columns.push(new NameColumn({ truncateLength: 20 }));
		this.columns.push(
			new StatusColumn('status', null, {
				sortKey: 'statusId',
				render: (value, row) => {
					const colorMap = {
						Red: 'red',
						Green: 'green',
						Orange: 'orange',
						Gray: 'gray',
						Lila: 'purple',
						LightBlue: 'lightBlue',
						Blue: 'blue',
						LightGreen: 'lightGreen',
					};

					// const icon = row.canBeReturned ? <i className='ri-arrow-go-back-fill'></i> : null;
					const icon = row.canBeReturned ? '⮐' : null;

					return <StatusRender value={value.name} color={colorMap[value.color]} icon={icon} />;
				},
			}),
		);
		this.columns.push(
			new AddressColumn('from', {
				filter: {
					key: 'fromLocation',
					isParam: false,
					isArray: false,
					filterRender: (value, onChange) => (
						<AddressSelector
							containerClassName='w-36 py-0 px-0 text-sm capitalize '
							truncateAt={10}
							defaultValue={value}
							onChange={onChange}
							noBodyPayload
						/>
					),
				},
			}),
		);
		this.columns.push(
			new AddressColumn('to', {
				filter: {
					key: 'toLocation',
					isParam: false,
					isArray: false,
					filterRender: (value, onChange) => (
						<AddressSelector
							containerClassName='w-36 py-0 px-0 text-sm capitalize '
							truncateAt={10}
							defaultValue={value}
							onChange={onChange}
							noBodyPayload
						/>
					),
				},
			}),
		);
		this.columns.push(
			new ActionColumn((d, itemData) => <DetailsRender row={itemData} />, {
				name: 'detailsAction',
				label: 'details',
			}),
		);
		this.columns.push(
			new SimpleObjectColumn('employee', 'name', {
				hidden: true,
				sortKey: 'Employee',
				filter: {
					key: 'employeeId',
					isParam: false,
					isArray: false,
					filterRender: (value, onChange) => (
						<ReSelectFilterRender service='employee' value={value} onChange={onChange} />
					),
				},
			}),
		);
		this.columns.push(
			new SimpleObjectColumn('patient', 'name', {
				hidden: true,
				render: (value, row) => <PatientRender value={value} />,
			}),
		);

		this.columns.push(
			new SimpleObjectColumn('taskType', 'name', {
				hidden: true,
			}),
		);
		this.columns.push(
			new SimpleObjectColumn('expectedTaskTime', 'name', {
				label: 'ett',
				title: 'expectedTaskTime',
				hidden: true,
				render: (value, row) => row?.taskType?.expectedTaskTime || NO_DATA_DISPLAY,
			}),
		);
		this.columns.push(
			new SimpleObjectColumn('taskAnticipationTime', 'name', {
				label: 'tat',
				title: 'taskAnticipationTime',
				hidden: true,
				render: (value, row) => row?.taskType?.anticipationTime || NO_DATA_DISPLAY,
			}),
		);
		this.columns.push(
			new SimpleObjectColumn('team', 'name', {
				label: 'team',
				filter: {
					key: 'teamId',
					isParam: false,
					isArray: false,
					filterRender: (value, onChange) => (
						<ReSelectFilterRender service='team' value={value} onChange={onChange} />
					),
				},
				render: (value, row) => <BadgeRender value={value} itemClassName={'px-3'} />,
			}),
		);
		this.columns.push(
			new SimpleObjectColumn('withEmployee', 'name', {
				hidden: true,
				filter: {
					key: 'withEmployee',
					isParam: false,
					isArray: false,
					filterRender: (value, onChange) => (
						<ReSelectFilterRender service='employee' value={value} onChange={onChange} />
					),
				},
			}),
		);
		this.columns.push(new IdColumn({ label: 'nr' }));
		this.columns.push(new NumberColumn('priority', { label: 'pr', title: 'priority' }));
		this.columns.push(
			new SimpleObjectColumn('urgency', 'name', {
				hidden: true,
			}),
		);
		this.columns.push(new DateColumn('assignedOn', { hidden: true }));
		this.columns.push(new DateColumn('acceptedOn', { hidden: true }));
		this.columns.push(new DateColumn('startedOn', { hidden: true }));
		this.columns.push(new DateColumn('completedOn', { hidden: true }));
		this.columns.push(new DateColumn('cancelledOn', { hidden: true }));
		this.columns.push(
			new SimpleObjectColumn('createdBy', 'name', {
				render: (value) => <UserRender value={value} itemClassName={'px-3'} />,
			}),
		);
		this.columns.push(new DateColumn('createdOn', { hidden: true }));

		this.assignColumnOrder();
	}
}
export default TaskModel;
