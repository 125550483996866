import { useTranslations } from 'hooks';
const AccompaniedByNurseRender = () => {
	const { translate } = useTranslations();
	return (
		<span
			data-tooltip-variant='light'
			data-tooltip-id={`table-tooltip`}
			data-tooltip-content={translate('accompaniedByNurse')}
			className='hover:opacity-80'
		>
			<i class='ri-nurse-line text-lg px-1 text-primary-500'></i>
		</span>
	);
};
const TwoEmployeesRender = () => {
	const { translate } = useTranslations();
	return (
		<span
			data-tooltip-variant='light'
			data-tooltip-id={`table-tooltip`}
			data-tooltip-content={translate('twoEmployees')}
			className='hover:opacity-80'
		>
			<i class='ri-group-3-fill text-lg px-1 text-red-500'></i>
		</span>
	);
};

const DetailsRender = ({ value = null, row }) => {
	const customFields = row?.taskExtraDetails?.customFields || [];

	return (
		<div className=''>
			<div>
				{row.accompaniedByNurse && <AccompaniedByNurseRender />}
				{row.twoEmployees && <TwoEmployeesRender />}
			</div>
			<div>
				{customFields.map((field) => {
					const val = JSON.parse(field.value).join(', ');
					// if it is a checkbox, we need to show the key that is selected
					if (field.inputType === 8) {
						if (val) {
							return (
								<div key={field.id} className='border-t border-gray-200  mb-1'>
									<div className='text-xs text-primary-600 font-bold pl-1 '>{field.name}</div>
								</div>
							);
						}
						return '';
					}

					return (
						<div key={field.id} className='border-t border-gray-200  mb-1'>
							<div className='text-xs text-primary-600 '>{field.name}:</div>
							<div className='text-xs text-gray-500 font-bold pl-1'>{val}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
export default DetailsRender;
