import { Button } from 'components/ui/Input';
import { useTranslations } from 'hooks';
const ActionRow = ({
	item,
	permissions,
	editItem = () => {},
	viewItem = () => {},
	admitItem = () => {},
	dischargeItem = () => {},
}) => {
	const { translate } = useTranslations();
	return (
		<div className='flex flex-row justify-start -mt-1 pb-2 px-5'>
			<div className='flex flex-row justify-center items-center'>
				<div className='flex flex-row justify-center items-center space-x-5 py-4'>
					{permissions.View && (
						<Button small color={'primary'} onClick={viewItem}>
							<i className={`ri-eye-line mr-2`}></i>
							{translate('view')}
						</Button>
					)}
					{permissions.Update && (
						<Button small color={'primary'} onClick={editItem}>
							<i className={`ri-pencil-line mr-2`}></i>
							{translate('edit')}
						</Button>
					)}
					{permissions.Update && (
						<>
							<Button small color={'success'} onClick={admitItem}>
								<i className={`ri-user-received-line mr-2`}></i>
								{translate('admit')}
							</Button>
						</>
					)}
					{permissions.Update && item?.status?.id !== 3 && (
						<Button small color={'danger'} onClick={dischargeItem}>
							<i className={`ri-user-shared-line mr-2`}></i>
							{translate('discharge')}
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};

export default ActionRow;
