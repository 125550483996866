import Department from '../pages/Departments/parts/DepartmentsTable';
import RoomType from '../pages/RoomType/parts/RoomTypeTable';
const routes = [
	{
		header: 'general',
		key: 'General',
		items: [
			{
				name: 'roomType',
				key: 'RoomType',
				hash: '#roomType',
				component: RoomType,
			},
			{
				name: 'department',
				key: 'Department',
				hash: '#department',
				component: Department,
			},
		],
	},
];
export default routes;
