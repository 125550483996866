import { NO_DATA_DISPLAY } from 'config/configs';
const UserRender = ({ value, includeUsername = false }) => {
	if (!value) return NO_DATA_DISPLAY;

	if (!value.name && value.userName) {
		return value.userName;
	}

	if (includeUsername && value?.userName) {
		return `${value?.name} (${value?.userName})`;
	}

	return value?.name;
};
export default UserRender;
