import ExclusionTable from '../pages/Exclusions/parts/ExclusionTable';
import PermissionGroupsTable from '../pages/PermissionGroups/parts/PermissionGroupsTable';
const routes = [
	{
		header: 'general',
		key: 'General',
		items: [
			// {
			// 	name: "overview",
			// 	key: "Overview",
			// 	hash: "#overview",
			// 	component: Overview,
			// },
			// {
			// 	name: "lists",
			// 	key: "Lists",
			// 	hash: "#lists",
			// 	component: NotImplemented,
			// },
			{
				name: 'permissionGroups',
				key: 'PermissionGroup',
				hash: '#permission-groups',
				component: PermissionGroupsTable,
			},
			{
				name: 'exclusions',
				key: 'Exclusions',
				hash: '#exclusions',
				component: ExclusionTable,
			},
		],
	},
];

export default routes;
