import Card from 'components/ui/Card';
import { Table } from 'components/ui/Table';
import { useCrud, useTranslations } from 'hooks';
import { useEffect, useState } from 'react';

import { CrudOperations } from 'hoc';
import UserProfileForm from '../forms/userProfile.form';
import UserProfileModel from '../models';
import UserProfileService from '../services';

const Tables = ({ permissions }) => {
	const service = new UserProfileService();
	const model = new UserProfileModel();

	const { create, update, getAll, remove, getExport } = useCrud(service);
	const { translate } = useTranslations();
	const [data, setData] = useState({ data: [], meta: {} });

	const [queryString, setQueryString] = useState('');

	const fetchAndLoad = async (l_queryString = null) => {
		try {
			const res = await getAll(l_queryString || queryString);
			setData(res);
		} catch (error) {
			console.error('Error on Get All request: ', error);
		}
	};

	const { openCreate, openEdit, openView } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: UserProfileForm,
		componentName: model.getModelName(),
		permissions: permissions,
		formWidth: 54,
	});

	const rowClickHandler = (row) => {
		if (permissions.Update) {
			openEdit(row);
		} else if (permissions.View) {
			openView(row);
		}
	};

	useEffect(() => {
		const handleHashChange = async () => {
			if (window.location.hash === '#newUserProfile') {
				openCreate({});
			}
		};

		window.addEventListener('hashchange', handleHashChange);
		handleHashChange();
		return () => {
			window.removeEventListener('hashchange', handleHashChange);
		};
	}, []);

	return (
		<Card
			collapsible={false}
			header={model.getPluralModelName()}
			headerButtonLabel={permissions.Create && `+ ${translate('newUserProfile')}`}
			headerButtonClick={() => {
				openCreate();
			}}
		>
			<Table
				model={model}
				meta={data.meta}
				fetchData={fetchAndLoad}
				onRowClick={rowClickHandler}
				onTableRequestChange={(queryString) => {
					setQueryString(queryString);
					fetchAndLoad(queryString);
				}}
				onExportClick={async (queryString) => {
					return await getExport(queryString, 'user-profiles');
				}}
				data={data?.data}
			/>
		</Card>
	);
};
export default Tables;
