import { Form, FormEntry, InternalFormDivider } from 'components/form';
import { Input, SearchSelect } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useAside, useCrud, useForm, useTranslations } from 'hooks';
import UserProfileService from 'modules/persons/pages/Users/pages/UserProfiles/services';
import { Suspense, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const UrgencyForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { translate } = useTranslations();
	const { asideBuilder } = useAside();
	const { getOne } = useCrud(props.service);
	const { getData } = useForm();

	const [data, setData] = useState([]);
	const myForm = useRef(null);

	const [loading, setLoading] = useState(true);

	const userProfileService = new UserProfileService();

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	const closeAside = () => {
		asideBuilder.reset();
		asideBuilder.setOpen(false);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(myForm.current),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />
					<FormEntry label='name'>
						<Input
							isView={isView}
							type='text'
							placeholder={'name'}
							required={true}
							name='name'
							defaultValue={data?.name}
						/>
					</FormEntry>
					<InternalFormDivider>{translate('configuration')}</InternalFormDivider>
					<FormEntry label='label'>
						<Input
							isView={isView}
							type='text'
							placeholder={'label'}
							required={true}
							name='label'
							defaultValue={data?.label}
						/>
					</FormEntry>
					<FormEntry label='priority'>
						<Input
							isView={isView}
							type='number'
							min={0}
							max={5}
							placeholder={'priority'}
							required={true}
							name='priority'
							defaultValue={data?.priority}
						/>
					</FormEntry>
					<FormEntry
						label='userProfiles'
						isRequired={true}
						helpText={
							!isView && (
								<div>
									<div align='right' className='pt-5 '>
										<Link
											onClick={() => closeAside()}
											className='text-sm'
											to='/persons/users/users#newUserProfile'
										>
											{' + '}
											{translate('addUserProfile')}
										</Link>
									</div>
								</div>
							)
						}
					>
						<SearchSelect
							required
							multiple
							isView={isView}
							name='userProfileIds'
							service={userProfileService}
							defaultSelected={data?.profileIds}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default UrgencyForm;
