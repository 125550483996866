import PatientSelectorPortal from './PatientSelector';

export const portals = [
	{
		id: 'persons:patientSelector',
		element: PatientSelectorPortal,
		initialState: {},
	},
];

export const PORTALS = {
	patientSelector: 'persons:patientSelector',
};

export default portals;
