import Card from 'components/ui/Card';
import { Button } from 'components/ui/Input';
import { useAside, useCrud, useTranslations } from 'hooks';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import EntraIdSetupForm from '../forms/EntraIdSetup.form';
import EntraIdService from '../services';

const EntraIdConnectionView = ({ permissions }) => {
	const { asideBuilder } = useAside();
	const service = new EntraIdService();

	const { getOne, create } = useCrud(service);
	const { translate } = useTranslations();
	const [data, setData] = useState(null);
	const [testing, setTesting] = useState(false);

	const saveAndLoad = async (data) => {
		if (data) {
			await create(data, {
				creationSuccessMessage: 'entraIdSettingsUpdatedSuccessfully',
			}).then((res) => {
				asideBuilder.reset();
				asideBuilder.setOpen(false);
			});
			fetchAndLoad();
		}
	};

	const fetchAndLoad = async () => {
		try {
			const res = await getOne('');
			setData(res);
		} catch (error) {
			console.error('Error on Get All request: ', error);
		}
	};

	const openEditConnection = () => {
		asideBuilder.setOpen(true);
		asideBuilder.setWidth(35);
		asideBuilder.setTitle(translate('entraIdSetup'));

		asideBuilder.setComponent(EntraIdSetupForm);
		asideBuilder.setAutoClose(false);
		asideBuilder.setAutoClear(false);
		asideBuilder.setComponentKey('connection-edit');
		asideBuilder.setComponentProps({ data: {}, service: service });
		asideBuilder.setSaveCallback(saveAndLoad);
		asideBuilder.setSecondaryComponent(null);
		asideBuilder.setOnClose(() => asideBuilder.reset());
		asideBuilder.build();
	};

	const testConnection = async () => {
		setTesting(true);
		const res = await service.test();
		setTesting(false);
		if (res.succeeded) {
			toast(translate('connectionSuccessful'), {
				duration: 2000,
				icon: '✅',
			});
		} else {
			console.error(res.message);
			toast(translate('connectionFailed'), {
				duration: 2000,
				icon: '❗️',
			});
		}
	};

	useEffect(() => {
		fetchAndLoad();
	}, []);

	const SecondaryComponent = () => (
		<Button
			disabled={testing}
			onClick={(e) => {
				testConnection();
				e.stopPropagation();
			}}
		>
			{testing ? (
				<div className='flex flex-row space-x-2'>
					<div className='animate-spin'>
						<i className='ri-loader-4-line'></i>
					</div>
					<div>{translate('testingConnection')}</div>
				</div>
			) : (
				<>
					<i className='ri-rfid-line mr-2'></i>
					{translate('testConnection')}
				</>
			)}
		</Button>
	);

	return (
		<Card
			collapsible
			defaultOpen={true}
			header={'entraId'}
			SecondaryComponent={permissions.Update && SecondaryComponent}
			headerButtonLabel={
				permissions.Update && (
					<>
						<i className='ri-tools-line'></i> {translate('editConnection')}
					</>
				)
			}
			headerButtonClick={openEditConnection}
		>
			<div className='pt-1 pb-3 font-semibold'>{translate('connection')}</div>
			<div className='flex flex-col sm:flex-row pt-2 items-center'>
				<div className='w-full sm:w-1/5'>
					<div className='text-left sm:text-center text-lg font-semibold leading-5 tracking-normal'>
						{!data?.isSyncEnabled ? (
							<div className='text-red-600'>{translate('INACTIVE')}</div>
						) : (
							<div className=' text-green-600'>{translate('ACTIVE')}</div>
						)}
					</div>
				</div>
				<div className='w-full sm:w-4/5 text-sm opacity-60'>
					<div>{data?.tenantId}</div>
				</div>
			</div>
		</Card>
	);
};
export default EntraIdConnectionView;
