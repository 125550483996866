import { PortalContext } from 'contexts/PortalManager';
import { useCrud, useTranslations } from 'hooks';
import PatientService from 'modules/persons/pages/Patients/services';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { runAction } from 'utils';
const PatientSelector = forwardRef(
	(
		{
			name = '',
			required = false,
			isView = false,
			value,
			defaultValue,
			defaultSelected,
			hideLabel = false,
			label = null,
			className = '',
			containerClassName = '',
			labelClassName = '',
			disabled = false,
			onSelect = () => {},
			onChange = () => {},
		},
		ref,
	) => {
		const { callAction } = useContext(PortalContext);
		const { translate } = useTranslations();
		const hasMounted = useRef(false);
		const inputRef = useRef(null);

		const [selectedPatient, setSelectedPatient] = useState(null);
		const [inputInvalid, setInputInvalid] = useState(false);
		const service = new PatientService();
		const { getOne } = useCrud(service);

		const selectPatient = ({ selected }) => {
			setSelectedPatient(selected);
			onSelect(selected);
			onChange(selected);
		};
		const checkValidity = () => {
			setInputInvalid(inputRef.current && inputRef.current.validity.valid === false);
		};

		const patientClickHandler = () => {
			if (disabled) {
				return;
			}
			runAction('persons', 'openPatientSelect', {
				callAction,
				data: {
					selected: selectedPatient,
				},
				onSelect: (s) => selectPatient(s),
			});
		};

		const removeSelectedValue = () => {
			setSelectedPatient(null);
			onSelect(null);
			onChange(null);
		};

		useEffect(() => {
			checkValidity();
		}, [selectedPatient]);

		const mapData = (data) => {
			if (!data) {
				return null;
			}
			if (data?.Id && data?.Name && data?.Gender && data?.PatientNr) {
				return {
					id: data.Id,
					patient: {
						id: data.PatientId,
						patientNr: data.PatientNr,
						fullName: data.Name,
						gender: {
							name: data.Gender,
						},
					},
				};
			}
			return data;
		};

		const fetchPatient = async (value) => {
			if (value && typeof value === 'object') {
				const mappedData = mapData(value);
				if (mappedData) {
					setSelectedPatient(mappedData);
					return;
				}
			}

			const patient = await getOne(value);
			if (patient) {
				setSelectedPatient(patient);
			}
		};

		useEffect(() => {
			const selectedValue = value || defaultValue;
			if (!selectedValue) {
				return;
			}
			fetchPatient(selectedValue);
		}, [defaultValue, value]);

		useEffect(() => {
			if (!hasMounted.current && defaultSelected) {
				hasMounted.current = true;
				fetchPatient(defaultSelected);
			}
		}, [defaultSelected]);

		return (
			<div
				className={`relative w-full ${containerClassName} ${
					disabled ? 'opacity-50 pointer-events-none cursor-not-allowed' : 'opacity-100'
				}`}
			>
				{!hideLabel && label && (
					<label className={`block mb-1 text-xs font-medium text-gray-700 ${labelClassName}`}>
						{translate(label || '', true)}
					</label>
				)}
				<div
					onClick={() => {
						if (!disabled && !isView) {
							patientClickHandler();
						}
					}}
					className={`relative bg-gray-50 border ${
						inputInvalid ? 'border-red-300 ' : ' border-gray-300'
					} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 my-1 cursor-pointer ${className}`}
				>
					<select
						ref={inputRef}
						type='text'
						className='sr-only pt-10 pl-10'
						required={required ?? false}
						name={name}
						onChange={() => {}}
					>
						<option disabled selected></option>
						{selectedPatient && selectedPatient?.patient && (
							<option value={selectedPatient?.id} selected>
								{selectedPatient?.patient?.id}
							</option>
						)}
					</select>

					<div className='flex flex-row justify-between items-center '>
						{selectedPatient ? (
							<>
								<i className='ri-user-6-line text-gray-500 w-7'></i>
								<div className='flex flex-col  w-full'>
									<div className=''>
										<div
											className='font-thin text-gray-400 -mt-2 text-xs'
											style={{ fontSize: '0.6rem' }}
										>
											#{selectedPatient?.patient?.patientNr}
										</div>
										<div className='flex flex-row space-x-2 items-center -mb-1.5 pl-0.5 -mt-0.5'>
											<div className='font-bold text-gray-600'>
												{selectedPatient?.patient?.fullName}
											</div>
											{selectedPatient?.patient?.gender && (
												<div className='font-bold text-gray-600'>
													({selectedPatient?.patient?.gender?.name?.charAt(0).toUpperCase()})
												</div>
											)}
										</div>
									</div>
								</div>
								{!disabled && !isView && (
									<div
										onClick={(e) => {
											e.stopPropagation();
											removeSelectedValue();
										}}
									>
										<i className='ri-close-circle-fill opacity-50 hover:opacity-100 mr-2 ml-2 h-5 w-5'></i>
									</div>
								)}
							</>
						) : (
							<div className='text-sm text-gray-700'>{translate('selectPatient')}</div>
						)}

						<i className='ri-menu-search-line text-gray-500  w-5'></i>
					</div>
				</div>
			</div>
		);
	},
);

export default PatientSelector;
