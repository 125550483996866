import Card from 'components/ui/Card';
import { Table } from 'components/ui/Table';
import { useCrud, useTranslations } from 'hooks';
import { useState } from 'react';
import PatientModel from '../models';
import PatientService from '../services';

import { Toggle } from 'components/ui/Input';
import { CrudOperations } from 'hoc';
import Swal from 'sweetalert2';
import { themeConfig } from 'utils';
import PatientForm from '../forms/Patient.form';
import ActionRow from './ActionRow';

const Tables = ({ permissions }) => {
	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [displayDischarged, setDisplayDischarged] = useState(false);

	const service = new PatientService();
	const model = new PatientModel();

	const { create, update, getAll } = useCrud(service);

	const { translate } = useTranslations();

	const fetchAndLoad = async (l_queryString = null, showAllDischarged = null) => {
		try {
			const showDischargedQuery = showAllDischarged === null ? displayDischarged : showAllDischarged;
			let params = new URLSearchParams(l_queryString || queryString);
			//if it is first time remove status
			if (showAllDischarged === true) {
				params.delete('status');
			}
			if (showDischargedQuery) {
				params.append('ShowAllDischarged', true);
				params.append('status', 3);
			}

			setIsLoading(true);
			const res = await getAll(params.toString());
			setIsLoading(false);
			setData(res);
		} catch (error) {
			console.error('Error on Get All request: ', error);
		}
	};

	const { openCreate, openEdit, openView, openClone } = CrudOperations({
		create,
		update,
		fetchAndLoad,
		service,
		form: PatientForm,
		componentName: model.getModelName(),
		permissions: permissions,
	});

	const handleAdmit = (item) => {
		openClone(item, { isAdmit: true });
	};
	const handleDischarge = (item) => {
		Swal.fire({
			title: translate('dischargePatient'),
			text: translate('youAreAboutToDischargePatient'),
			showCancelButton: true,
			confirmButtonText: translate('discharge'),
			icon: 'warning',
			showLoaderOnConfirm: true,
			cancelButtonText: translate('cancel'),
			confirmButtonColor: '#ef4444',
			cancelButtonColor: '#b5bec9',
			preConfirm: async () => {
				try {
					const res = await service.discharge(item.id);
					await fetchAndLoad();
					return {
						success: res?.succeeded,
						message: res?.message === 'ActionFailed' ? null : res.message,
					};
				} catch (error) {
					console.error('Error on Discharge request: ', error);
				}
				return { success: false, message: null };
			},
		}).then((result) => {
			if (result.value?.success) {
				Swal.fire({
					icon: 'success',
					text: translate('successfullyDischarged'),
					confirmButtonColor: themeConfig.color.primary,
					cancelButtonColor: '#b5bec9',
				});
			} else if (result.value?.success === false) {
				Swal.fire({
					icon: 'error',
					text: translate('dischargeFailed'),
					confirmButtonColor: themeConfig.color.primary,
					cancelButtonColor: '#b5bec9',
				});
			}
		});
	};

	return (
		<Card
			collapsible
			header={model.getPluralModelName()}
			defaultOpen={true}
			headerButtonClick={openCreate}
			headerButtonLabel={permissions.Create && `+ ${translate('newAdmission')}`}
			subHeader={
				<div className='flex flex-row justify-start items-center space-x-3 -mb-7 -mt-3 rounded-lg p-2 border-2 border-slate-100  '>
					<div className=' text-xs text-gray-500'>{translate('displayAllDischargedPatients')}</div>
					<Toggle
						checked={displayDischarged}
						onClick={() => {
							const showAllDischarged = !displayDischarged;
							setDisplayDischarged(showAllDischarged);

							fetchAndLoad(queryString, showAllDischarged);
						}}
					/>
				</div>
			}
		>
			<Table
				model={model}
				isLoading={isLoading}
				isExportable={false}
				meta={data.meta}
				data={data.data}
				hasRowActions={true}
				onTableRequestChange={(queryStr) => {
					setQueryString(queryStr);
					fetchAndLoad(queryStr);
				}}
				RowActionsComponent={({ item }) => (
					<ActionRow
						item={item}
						permissions={permissions}
						editItem={() => openEdit(item)}
						viewItem={() => openView(item)}
						admitItem={() => handleAdmit(item)}
						dischargeItem={() => handleDischarge(item)}
					/>
				)}
			/>
		</Card>
	);
};
export default Tables;
