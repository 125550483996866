import { Form, FormEntry, InternalFormDivider } from 'components/form';
import { Input, SearchSelect, Select, TextArea } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useAside, useCrud, useForm, useTranslations } from 'hooks';
import DepartmentService from 'modules/addresses/pages/settings/pages/Departments/services';
import PermissionService from 'modules/persons/pages/Users/pages/Settings/pages/PermissionGroups/services';
import TaskTemplateService from 'modules/tasks/pages/Manage/pages/TaskTemplates/pages/TaskTemplates/services';
import TaskGroupService from 'modules/tasks/pages/Manage/services';
import { Suspense, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { runAction } from 'utils';
import Permission from '../components/Permission';

const UserProfileForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { asideBuilder } = useAside();
	const { translate } = useTranslations();
	const { getData } = useForm();
	const { getOne } = useCrud(props.service);

	const [data, setData] = useState([]);
	const [isFormDirty, setIsFormDirty] = useState(false);
	const myForm = useRef(null);
	const [loading, setLoading] = useState(true);
	const [permissionLoading, setPermissionsLoading] = useState(true);
	const [allPermissions, setAllPermissions] = useState([]);

	const [selectedPermissions, setSelectedPermissions] = useState(null);
	const [taskTemplateOptions, setTaskTemplateOptions] = useState([]);
	const [viewPatientsLevelEnum, setViewPatientsLevelEnum] = useState({});

	const departmentService = new DepartmentService();
	const permissionService = new PermissionService();
	const taskGroupService = new TaskGroupService();
	const taskTemplateService = new TaskTemplateService();

	const fetchViewPatientsLevelEnum = async () => {
		const l_viewPatientsLevelEnum = await runAction('tenants', 'getEnum', 'ViewPatientsLevel');
		setViewPatientsLevelEnum(l_viewPatientsLevelEnum);
		return l_viewPatientsLevelEnum;
	};

	const fetchPermissions = async () => {
		const res = await runAction('permissions', 'getPermissions');
		setAllPermissions(res);
		return res;
	};

	const fetchTaskTemplates = (taskGroupIds = []) => {
		if (taskGroupIds.length === 0) {
			setTaskTemplateOptions([]);
			return;
		}

		taskTemplateService.getMultiParentOptionsList(taskGroupIds).then((res) => {
			setTaskTemplateOptions(res.data || []);
		});
	};

	const getAllPermissionTypes = (l_allPermissions) => {
		let l_permissions = {};
		if (!l_allPermissions) return l_permissions;
		Object.keys(l_allPermissions).forEach((permissionKey) => {
			Object.keys(l_allPermissions[permissionKey]).forEach((permissionItemKey) => {
				l_permissions[permissionItemKey] = 0;
			});
		});

		return l_permissions;
	};

	const getPermissionsOfPermissionGroups = async (l_permissionGroups) => {
		if (l_permissionGroups?.length > 0) {
			setPermissionsLoading(true);
			const l_allPermissions = await fetchPermissions();
			if (!l_allPermissions) {
				setPermissionsLoading(false);
				return;
			}

			permissionService.getMultiple(l_permissionGroups).then((res) => {
				let updatedPermissions = {};
				res?.data?.forEach((permissionGroup) => {
					const newPermissions = permissionGroup.permissions;

					for (let key in getAllPermissionTypes(l_allPermissions)) {
						const newPermissionValue = newPermissions[key] ? newPermissions[key] : 0;
						const selectedPermissionValue = updatedPermissions[key] ? updatedPermissions[key] : 0;

						updatedPermissions[key] = selectedPermissionValue | newPermissionValue;
					}
				});

				setSelectedPermissions(updatedPermissions);
				setPermissionsLoading(false);
			});
		} else {
			setPermissionsLoading(false);
		}
	};

	const changePermissionGroups = (l_permissionGroups) => {
		getPermissionsOfPermissionGroups(l_permissionGroups);
	};

	const initiateData = () => {
		setLoading(true);

		fetchViewPatientsLevelEnum();

		fetchPermissions().then((res) => {
			setSelectedPermissions(getAllPermissionTypes(res));
		});

		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);

				fetchTaskTemplates(res?.taskGroupIds || []);
				getPermissionsOfPermissionGroups(res?.permissionGroupIds);
			});
		} else {
			setLoading(false);
			setPermissionsLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const resetData = () => {
		myForm.current.reset();
		setData([]);
		setSelectedPermissions(getAllPermissionTypes(allPermissions));
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(myForm.current),
		clear: () => resetData(),
	}));

	const closeAside = () => {
		asideBuilder.setOpen(false);
		asideBuilder.reset();
	};

	const handleLinkClick = (e) => {
		if (isFormDirty) {
			e.preventDefault();
			const userConfirmed = window.confirm(translate('youHaveUnsavedChangesAreYouSureYouWantToLeave'));
			if (userConfirmed) {
				closeAside();
				window.location.href = e.target.href;
			}
		} else closeAside();
	};

	const handleInputChange = (val, prevVal = null) => {
		//TODO: Optimize this part
		if (Array.isArray(val) || Array.isArray(prevVal)) {
			if (Array.isArray(val) && Array.isArray(prevVal)) {
				if (val.length === prevVal.length && val.every((v, i) => v === prevVal[i])) {
					return;
				}
			}
			if (prevVal === null) {
				return false;
			}
			if (Array.isArray(prevVal)) {
				if (prevVal.length === 1 && prevVal[0] === 1) {
					return;
				}
			}

			if (Array.isArray(val)) {
				if (val.length === 0) {
					return false;
				}
			}

			setIsFormDirty(true);
		}

		if (!isFormDirty && val !== prevVal) {
			setIsFormDirty(true);
		}
	};

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-auto'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />

					<InternalFormDivider>{translate('generalInformation')}</InternalFormDivider>
					<FormEntry label={'profileName'}>
						<Input
							maxLength={100}
							placeholder={'profileName'}
							type='text'
							isView={isView}
							required={true}
							name='name'
							onChange={(e) => {
								handleInputChange(e.target.value, data?.name);
							}}
							defaultValue={data?.name || ''}
						/>
					</FormEntry>
					<FormEntry label={'description'}>
						<TextArea
							maxLength={500}
							rows={4}
							isView={isView}
							placeholder={'description'}
							required={false}
							name='description'
							onChange={(e) => {
								handleInputChange(e.target.value, data?.description);
							}}
							defaultValue={data?.description || ''}
						/>
					</FormEntry>
					<InternalFormDivider>{translate('configuration')}</InternalFormDivider>
					<FormEntry label='taskGroups'>
						<SearchSelect
							multiple
							truncateLength={82}
							isView={isView}
							name='taskGroupIds'
							service={taskGroupService}
							isSingleValueOnSelectAll
							defaultSelected={data?.taskGroupIds}
							onChange={(selected) => {
								const selectedItems = selected.map((item) => item.value);
								fetchTaskTemplates(selectedItems);
								handleInputChange(selectedItems, data?.departmentIds);
							}}
						/>
					</FormEntry>
					<FormEntry label='taskTemplates'>
						<SearchSelect
							multiple
							truncateLength={82}
							isView={isView}
							name='taskTemplateIds'
							options={taskTemplateOptions}
							isSingleValueOnSelectAll
							excludeSelectedOptionsWhenNotInOptionsList
							defaultSelected={data?.taskTemplateIds}
							onChange={(selected) => {
								handleInputChange(
									selected.map((item) => item.value),
									data?.taskTemplateIds,
								);
							}}
						/>
					</FormEntry>

					<FormEntry
						label='partOfDepartment'
						helpText={
							<div align='right' className='pb-2 text-xs'>
								{translate('departmentsAreManagedIn')} <br />
								<Link
									onClick={handleLinkClick}
									className='text-cyan-500 font-medium'
									to='/persons/users/teams'
								>
									{' '}
									{translate('teamsAndDepartments')}
								</Link>
							</div>
						}
					>
						<SearchSelect
							multiple
							truncateLength={82}
							isView={isView}
							name='departmentIds'
							service={departmentService}
							isSingleValueOnSelectAll
							defaultSelected={data?.departmentIds}
							onChange={(selected) => {
								handleInputChange(
									selected.map((item) => item.value),
									data?.departmentIds,
								);
							}}
						/>
					</FormEntry>

					<FormEntry helpText={<div className='pb-2'></div>} label='showPatients'>
						<Select
							isView={isView}
							name='viewPatientsLevel'
							options={Object.keys(viewPatientsLevelEnum).map((key) => ({
								value: viewPatientsLevelEnum[key],
								label: translate(key, true),
							}))}
							selectedValue={data?.viewPatientsLevel || null}
						/>
					</FormEntry>

					<FormEntry
						label='permissionGroups'
						helpText={
							<div align='right' className='pt-1 text-xs'>
								{translate('groupsAreManagedIn')} <br />
								<Link
									onClick={handleLinkClick}
									className='text-cyan-500 font-medium'
									to='/persons/users/settings#permission-groups'
								>
									{' '}
									{translate('settings')}
								</Link>
							</div>
						}
					>
						<SearchSelect
							multiple
							truncateLength={82}
							isView={isView}
							name='permissionGroupIds'
							service={permissionService}
							defaultSelected={data?.permissionGroupIds}
							onChange={(selected) => {
								const selectedItems = selected.map((item) => item.value);
								changePermissionGroups(selectedItems);
								handleInputChange(selectedItems, data?.permissionGroup);
							}}
						/>
					</FormEntry>

					<InternalFormDivider>{translate('permissions')}</InternalFormDivider>
					{permissionLoading && (
						<tr>
							<td
								className={`${'absolute top-10 left-0 w-full h-full z-50 overflow-y-hidden'}`}
								colSpan={2}
							>
								<div className='flex justify-center items-center h-32'>
									<i className='ri-loader-2-line animate-spin text-primary-500 text-3xl'></i>
								</div>
							</td>
						</tr>
					)}
					{allPermissions && (
						<tr className={`${permissionLoading ? 'cursor-wait opacity-50' : 'cursor-auto opacity-100'}`}>
							<td colSpan={2}>
								{
									//permission is object, iterate through it

									Object.keys(allPermissions).map((allPermissionKey, allPermissionIndex) => {
										const currentPermissions = allPermissions[allPermissionKey];

										return (
											<div>
												<div className='flex items-center text-md text-center font-semibold text-cyan-700 uppercase pt-8 pb-4'>
													<div className='flex-grow border-t border-cyan-500'></div>
													<span className='px-5 text-lg'>{translate(allPermissionKey)}</span>
													<div className='flex-grow border-t border-cyan-500'></div>
												</div>
												<div>
													{Object.keys(currentPermissions).map((key, index) => (
														<Permission
															isView={isView}
															onFormDirty={() => setIsFormDirty(true)}
															permissionKey={key}
															key={index}
															index={index}
															disabledAndCheckedPermissions={selectedPermissions[key]}
															activePermissions={data?.customPermissions?.[key] || 0}
															permissions={currentPermissions}
														/>
													))}
												</div>
											</div>
										);
									})
								}
							</td>
						</tr>
					)}
				</Form>
			</div>
		</Suspense>
	);
});
export default UserProfileForm;
