import { useTranslations } from 'hooks';
import { forwardRef, useEffect, useState } from 'react';
import Chart from '..';
import DashboardService from '../../../../services';
import { convertData, exportDataToCSV } from '../utils/chartHelper';

import Settings from '../components/ChartSettings';
import chartColors from '../configs/chartColors';
import Filter from './Filters';

const EmployeeStatsChart = forwardRef(
	({ isDraggable = false, zIndex = 1, onRemove = () => {}, isExportable = false, ...props }, ref) => {
		const { translate } = useTranslations();
		const dashboardService = new DashboardService();

		const DEFAULT_FILTER_DATA = {
			intervalMinutes: 30,
			startTime: '08:00',
			endTime: '21:00',
		};

		const CHART_EXPORT_MAP = {
			startTime: { label: 'Start Time', order: 0 },
			endTime: { label: 'End Time', order: 1 },
			availableEmployees: { label: 'Available Employees', order: 2 },
			inTransitEmployees: { label: 'In Transit Employees', order: 3 },
			onBreakEmployees: { label: 'On Break Employees', order: 4 },
			totalEmployees: { label: 'Total Employees', order: 5 },
		};

		const [data, setData] = useState(null);
		const [renderTime, setRenderTime] = useState(() => new Date());
		const [filterVisible, setFilterVisible] = useState(false);
		const [settingsVisible, setSettingsVisible] = useState(false);
		const [legendsVisible, setLegendsVisible] = useState(true);
		const [filterData, setFilterData] = useState(null);

		const fetchData = async () => {
			let preparedFilterData = convertData(DEFAULT_FILTER_DATA);

			if (filterData) {
				preparedFilterData = convertData(filterData);
			}

			const result = await dashboardService.getEmployeeStatsChartData([...preparedFilterData]);
			setData(result.data);
			setRenderTime(new Date());
		};

		const legends = [
			{
				key: 'availableEmployees',
				label: translate('availableEmployees'),
				fill: chartColors.blue,
				chartType: 'Bar',
				maxBarSize: 25,
				stackId: 'a',
				activeBar: {
					fill: chartColors.blueDark,
					stroke: chartColors.blue,
				},
			},
			{
				key: 'inTransitEmployees',
				label: translate('inTransitEmployees'),
				fill: chartColors.green,
				chartType: 'Bar',
				maxBarSize: 25,
				stackId: 'a',
				activeBar: {
					fill: chartColors.greenDark,
					stroke: chartColors.green,
				},
			},
			{
				key: 'onBreakEmployees',
				label: translate('onBreakEmployees'),
				fill: chartColors.red,
				chartType: 'Bar',
				maxBarSize: 25,
				stackId: 'a',
				activeBar: {
					fill: chartColors.redDark,
					stroke: chartColors.red,
				},
			},
		];

		const FilterComponent = () => {
			return (
				<Filter
					display={filterVisible}
					onCancel={() => {
						setFilterVisible(false);
					}}
					data={filterData || DEFAULT_FILTER_DATA}
					onSubmit={(data) => {
						setFilterData(data);
						setFilterVisible(false);
					}}
				/>
			);
		};

		const SettingsComponent = () => {
			return (
				<Settings
					display={settingsVisible}
					onCancel={() => {
						setSettingsVisible(false);
					}}
					onShowLegendsClick={() => {
						setLegendsVisible(!legendsVisible);
					}}
					onRemove={onRemove}
					showLegends={legendsVisible}
				/>
			);
		};

		const filterClickHandler = () => {
			if (!filterVisible) {
				setFilterVisible(true);
				setSettingsVisible(false);
				return;
			}

			setFilterVisible(false);
		};

		const settingsClickHandler = () => {
			if (!settingsVisible) {
				setSettingsVisible(true);
				setFilterVisible(false);
				return;
			}

			setSettingsVisible(false);
		};

		useEffect(() => {
			fetchData();
		}, [filterData]);

		return (
			<Chart
				ref={ref}
				className={isDraggable ? 'cursor-move' : ''}
				data={data}
				legends={legends}
				renderTime={renderTime}
				onRefresh={fetchData}
				header='employees'
				description='onlineEmployeesByStatuses'
				xAxisLabel={'startTime'}
				FilterComponent={FilterComponent}
				SettingsComponent={SettingsComponent}
				filterActive={filterData !== null}
				onFilterClick={filterClickHandler}
				onSettingsClick={settingsClickHandler}
				displayLegends={legendsVisible}
				isExportable={isExportable}
				onExport={() => exportDataToCSV(data, CHART_EXPORT_MAP)}
				{...props}
				style={{ ...props.style, zIndex: zIndex }}
			/>
		);
	},
);

export default EmployeeStatsChart;
