import { Form, FormEntry, InternalFormDivider } from 'components/form';
import { CheckBox, Input, SearchSelect } from 'components/ui/Input';
import ViewInput from 'components/ui/Input/internal/ViewInput';
import { Loading } from 'components/ui/Interactive';
import { useAside, useCrud, useForm, useTranslations } from 'hooks';
import TaskGroupService from 'modules/tasks/pages/Manage/services';
import { forwardRef, Suspense, useEffect, useImperativeHandle, useRef, useState } from 'react';
import OptionInput from '../components/OptionInput';
import InputTypes from '../forms/InputTypes.form';

const CustomFieldForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { helperAsideBuilder } = useAside();
	const { getOne } = useCrud(props.service);
	const { translate } = useTranslations();
	const { getData } = useForm();

	const [data, setData] = useState([]);
	const myForm = useRef(null);
	const [loading, setLoading] = useState(true);
	const taskGroupService = new TaskGroupService();

	const [activeInputType, setActiveInputType] = useState(null);

	const openDialog = () => {
		helperAsideBuilder.setTitle(translate('inputTypes'));
		helperAsideBuilder.setComponent(InputTypes);
		helperAsideBuilder.setOpen(true);
		helperAsideBuilder.setComponentProps({
			data: { activeInputType: activeInputType ?? data?.inputType },
			chooseInputType: (inputType) => chooseIT(inputType),
		});
		helperAsideBuilder.build();
	};

	const chooseIT = (inputType) => {
		setActiveInputType(inputType);
		helperAsideBuilder.setOpen(false);
	};

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);
				setActiveInputType(res?.inputType);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(myForm.current),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />

					<InternalFormDivider>{translate('configuration')}</InternalFormDivider>
					<FormEntry label='fieldName'>
						<Input
							isView={isView}
							type='text'
							placeholder={'customFieldName'}
							required={true}
							name='name'
							defaultValue={data?.name}
						/>
					</FormEntry>
					<FormEntry label='inputType' isRequired={true}>
						{isView ? (
							<div isView={isView}>
								<ViewInput value={activeInputType?.name} />
							</div>
						) : (
							<div
								className=' relative bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 my-1 cursor-pointer '
								onClick={() => openDialog()}
							>
								<input
									type='text'
									name='InputType'
									required={true}
									className='sr-only'
									onChange={() => {}}
									value={activeInputType?.id}
								/>
								<div className='flex items-center justify-between'>
									<div>{activeInputType?.name}</div>
									<div className='opacity-50 hover:opacity-100'>
										<i className='ri-arrow-right-line'></i>
									</div>
								</div>
							</div>
						)}
					</FormEntry>
					{activeInputType?.requiresOptions && (
						<FormEntry label='fieldValue'>
							<OptionInput isView={isView} name='value' required={true} defaultValue={data?.value} />
						</FormEntry>
					)}

					<FormEntry label='availableForTaskGroups'>
						<SearchSelect
							required
							multiple
							isView={isView}
							name='taskGroupIds'
							service={taskGroupService}
							defaultSelected={data?.taskGroupIds}
							isSingleValueOnSelectAll
						/>
					</FormEntry>
					<InternalFormDivider>{translate('displayIn')}</InternalFormDivider>
					<FormEntry label={'taskListScreen'}>
						<CheckBox
							isView={isView}
							name='displayInTaskListScreen'
							label='taskListScreen'
							selected={data?.displayInTaskListScreen}
						/>
					</FormEntry>
					<FormEntry label={'patientForm'}>
						<CheckBox
							isView={isView}
							name='displayInPatientForm'
							label='patientForm'
							selected={data?.displayInPatientForm}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default CustomFieldForm;
