import { useState } from 'react';

import Card from 'components/ui/Card';
import { Table } from 'components/ui/Table';

import UsersForm from '../forms/user.form';

import { useCrud, useTranslations } from 'hooks';

import { CrudOperations } from 'hoc';
import UserModel from '../models';

import UserService from '../services';

const Tables = ({ permissions }) => {
	const service = new UserService();
	const model = new UserModel();
	model.setExportable(permissions.Export);

	const { create, update, getAll, getExport } = useCrud(service);
	const { translate } = useTranslations();

	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState('');

	const fetchAndLoad = async (l_queryString = null) => {
		try {
			const res = await getAll(l_queryString || queryString);
			setData(res);
		} catch (error) {
			console.error('Error on Get All request: ', error);
		}
	};

	const { openCreate, openEdit, openView } = CrudOperations({
		create,
		update,
		fetchAndLoad,
		service,
		form: UsersForm,
		componentName: model.getModelName(),
		permissions: permissions,
	});

	const rowClickHandler = (row) => {
		if (permissions.Update) {
			openEdit(row, { requestReload: fetchAndLoad });
		} else if (permissions.View) {
			openView(row, { requestReload: fetchAndLoad });
		}
	};
	return (
		<Card
			collapsible={false}
			defaultOpen={true}
			header={model.getPluralModelName()}
			headerButtonLabel={permissions.Create && `+ ${translate('newUser')}`}
			headerButtonClick={() => {
				openCreate({ requestReload: fetchAndLoad });
			}}
		>
			<Table
				model={model}
				onRowClick={rowClickHandler}
				onExportClick={async (queryString) => {
					return await getExport(queryString, model.getPluralModelName());
				}}
				meta={data.meta}
				data={data.data}
				onTableRequestChange={(queryString) => {
					setQueryString(queryString);
					fetchAndLoad(queryString);
				}}
			/>
		</Card>
	);
};

export default Tables;
