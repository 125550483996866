import { forwardRef } from 'react';
import EmployeeTable from './parts/EmployeeTable';
const OnlineEmployees = forwardRef(
	({ isDraggable = false, zIndex = 1, onRemove = () => {}, isExportable = false, ...props }, ref) => {
		return (
			<EmployeeTable
				ref={ref}
				className={isDraggable ? 'cursor-move' : ''}
				{...props}
				style={{ ...props.style, zIndex: zIndex }}
			/>
		);
	},
);
export default OnlineEmployees;
