import AbstractService from 'services/Service';
import { extractMeta } from 'utils/pagination';
const endpoint = 'home';

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}

	async getChartData(queryParams = []) {
		let params = new URLSearchParams();
		queryParams?.forEach((param) => {
			if (Array.isArray(param.value)) {
				param.value.forEach((val) => {
					params.append(param.key, val);
				});
			} else {
				params.set(param.key, param.value);
			}
		});

		const result = await this.api.get(`${this.endpoint}/tasks-stats?${params.toString()}`);
		return {
			data: result.data,
			meta: extractMeta(result, params.toString()),
		};
	}

	async getEmployeeStatsChartData(queryParams = []) {
		let params = new URLSearchParams();
		queryParams?.forEach((param) => {
			if (Array.isArray(param.value)) {
				param.value.forEach((val) => {
					params.append(param.key, val);
				});
			} else {
				params.set(param.key, param.value);
			}
		});

		const result = await this.api.get(`${this.endpoint}/employee-stats?${params.toString()}`);
		return {
			data: this.#mapGetData(result.data),
			meta: extractMeta(result, params.toString()),
		};
	}

	async getTeamChartData(queryParams = []) {
		let params = new URLSearchParams();
		queryParams?.forEach((param) => {
			if (Array.isArray(param.value)) {
				param.value.forEach((val) => {
					params.append(param.key, val);
				});
			} else {
				params.set(param.key, param.value);
			}
		});

		const result = await this.api.get(`${this.endpoint}/teams-stats?${params.toString()}`);

		return {
			data: result?.data,
			meta: extractMeta(result, params.toString()),
		};
	}

	async getSummary() {
		const result = await this.api.get(`${this.endpoint}/summary`);
		return {
			data: result.data,
		};
	}

	#mapGetData(data) {
		if (!data) return [];
		const dataToReturn = data.map((item) => {
			if (item.availableEmployees === 0 && item.inTransitEmployees === 0 && item.onBreakEmployees === 0) {
				return null;
			}

			return {
				...item,
				startTime: item.startTime.split(':').slice(0, 2).join(':'),
				endTime: item.endTime.split(':').slice(0, 2).join(':'),
			};
		});

		return dataToReturn.filter((item) => item);
	}

	getOne() {
		throw new Error('Method does not exist');
	}

	create() {
		throw new Error('Method does not exist');
	}

	update() {
		throw new Error('Method does not exist');
	}

	remove() {
		throw new Error('Method does not exist');
	}

	getExport() {
		throw new Error('Method does not exist');
	}
}
export default Service;
