import { formatDistanceToNow } from 'date-fns';
import * as locales from 'date-fns/locale'; // Import all available locales
import { useTranslations } from 'hooks';
import { forwardRef, useEffect, useState } from 'react';
import BarChartComposedComponent from './components/ChartComponents/BarChartComposed';
import PieChartComponent from './components/ChartComponents/PieChart';

const ChartRender = forwardRef(
	(
		{
			header = '',
			description = '',
			type = 'BarChart',
			legends,
			data,
			style,
			className,
			onMouseDown,
			onMouseUp,
			onTouchEnd,
			children,
			onRefresh = () => {},
			renderTime = null,
			FilterComponent = null,
			onFilterClick = () => {},
			filterActive = false,
			onSettingsClick = () => {},
			SettingsComponent = null,
			displayLegends = true,
			xAxisLabel = 'label',
			yAxisLabel = null,
			xAxisProps = {},
			isExportable = false,
			onExport = () => {},
			...props
		},
		ref,
	) => {
		const componentMap = {
			BarChart: BarChartComposedComponent,
			PieChart: PieChartComponent,
		};
		const { translate, activeLanguageCode } = useTranslations();

		const locale = locales[activeLanguageCode?.toLowerCase()] || locales.enUS;

		const Component = componentMap[type];
		const [timeAgo, setTimeAgo] = useState('');
		const [isLoading, setIsLoading] = useState(false);

		useEffect(() => {
			if (!renderTime || !locale) return;

			setTimeAgo(formatDistanceToNow(renderTime, { addSuffix: true, locale }));
			const interval = setInterval(() => {
				setTimeAgo(formatDistanceToNow(renderTime, { addSuffix: true, locale }));
			}, 20000); // Update every 20 seconds

			return () => clearInterval(interval);
		}, [renderTime, locale]);

		useEffect(() => {
			setIsLoading(false);
		}, [data]);
		return (
			<div
				className={`bg-white rounded-md p-4 shadow-md flex flex-col relative ${className}`}
				ref={ref}
				onMouseDown={onMouseDown}
				onMouseUp={onMouseUp}
				onTouchEnd={onTouchEnd}
				style={style}
				{...props}
			>
				<div className='flex flex-row justify-between items-center'>
					<div>
						<div className='pt-1 text-slate-700 text-sm uppercase tracking-wider'>
							{translate(header, true)}
						</div>
						<div className='tracking-wide text-gray-300 text-xs'>{translate(description, true)}</div>
					</div>
					<div className='flex flex-row space-x-3'>
						{isExportable && (
							<div className='cursor-pointer' onClick={onExport}>
								<i className='ri-upload-line text-gray-400 hover:text-gray-700'></i>
							</div>
						)}
						<div>
							<div className='cursor-pointer' onClick={onSettingsClick}>
								<i className='ri-settings-5-line text-gray-400 hover:text-gray-700'></i>
							</div>

							{SettingsComponent && <SettingsComponent />}
						</div>
						<div>
							<div className='cursor-pointer' onClick={onFilterClick}>
								<i
									className={`ri-equalizer-line pr-2 ${
										filterActive
											? 'text-primary-500 hover:text-primary-700'
											: 'text-gray-400 hover:text-gray-700 '
									}`}
								></i>
							</div>
							{FilterComponent && <FilterComponent />}
						</div>
					</div>
				</div>

				<div className='flex-grow py-2 h-full'>
					<Component
						data={data}
						legends={legends}
						displayLegends={displayLegends}
						xAxisLabel={xAxisLabel}
						yAxisLabel={yAxisLabel}
						xAxisProps={xAxisProps}
					/>
				</div>
				{children}
				<div className='border-t border-gray-200 mb-2'></div>
				<div className='text-gray-400 text-sm flex flex-row justify-between items-center'>
					<div className='flex flex-row items-center '>
						<i className='ri-time-line'></i>
						<div className='pl-1 font-thin text-xs'>{timeAgo}</div>
					</div>
					{isLoading && (
						<div className='flex flex-row items-center'>
							<div className='pr-1  text-xs'>{translate('loading')}...</div>
							<i className='ri-loader-4-line animate-spin'></i>
						</div>
					)}
					{!isLoading && (
						<div
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								onRefresh();
								setIsLoading(true);
							}}
							className='text-primary-400 hover:text-primary-700 cursor-pointer'
						>
							<span className='text-xs pr-1'>{translate('refresh')}</span>
							<i className='ri-refresh-line'></i>
						</div>
					)}
				</div>
			</div>
		);
	},
);

export default ChartRender;
