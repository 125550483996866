const chartColors = {
	blue: '#3b82f6',
	blueDark: '#2563eb',
	green: '#22c55e',
	greenDark: '#16a34a',
	red: '#ef4444',
	redDark: '#dc2626',
	yellow: '#eab308',
	yellowDark: '#ca8a04',
	purple: '#9c27b0',
	orange: '#f97316',
	orangeDark: '#ea580c',
	gray: '#6b7280',
	grayDark: '#4b5563',
	cyan: '#06b6d4',
	cyanDark: '#0891b2',
};
export default chartColors;
