import locales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { CrudOperations } from 'hoc';
import { useCrud, useTranslations } from 'hooks';
import { useOutletContext } from 'react-router-dom';

import Card from 'components/ui/Card';
import { Button } from 'components/ui/Input';
import { withSuspense } from 'hoc';
import { useToolbar } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import EventForm from './forms/Event.form';
import PlanningService from './services';

const PlanningPage = () => {
	const { permissionsMap } = useOutletContext();
	const { crumbsBuilder } = useToolbar();
	const { translate, activeLanguageCode } = useTranslations();
	const calendarRef = useRef(null);
	const location = useLocation();

	const { taskItem } = location.state || {};

	const service = new PlanningService();
	const { create, update, getAll, remove } = useCrud(service);

	const [data, setData] = useState({ data: [], meta: {} });

	const [isLoading, setIsLoading] = useState(false);
	const [dateRange, setDateRange] = useState({ start: null, end: null });

	const convertToDate = (isoDate) => {
		if (!isoDate) return;
		const date = new Date(isoDate);
		return new Date(date.getTime() + date.getTimezoneOffset() * -60000);
	};
	const fetchAndLoad = async (startDate, endDate) => {
		if (!startDate || !endDate) {
			startDate = dateRange.start;
			endDate = dateRange.end;
		}

		const l_start = getDateFromString(startDate);
		const l_end = getDateFromString(endDate);
		try {
			setIsLoading(true);
			const qs = `StartDate=${l_start}&EndDate=${l_end}`;
			const res = await getAll(qs);
			setIsLoading(false);
			const events = res.data.map((event, index) => ({
				title: event.name,
				start: convertToDate(event.occurrenceDate),
				// end: event.occurrenceDate,
				id: event.id + '-' + index,
				misc: event,
			}));

			setData((prevData) => ({ ...prevData, data: events }));
		} catch (error) {
			console.error('Error on Get All request: ', error);
		}
	};

	const { openCreate, openEdit } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: EventForm,
		componentName: 'schedule',
		permissions: permissionsMap,
	});

	const locale =
		locales.find((locale) => locale.code === activeLanguageCode?.toLowerCase()) ||
		locales.find((locale) => locale.code === 'en-gb');

	// const locale = locales[activeLanguageCode?.toLowerCase()] || locales.enUS;

	useEffect(() => {
		if (!taskItem) {
			return;
		}
		openCreate({
			data: {
				startDateTime: taskItem.startedOn,
				taskGroup: taskItem?.taskGroup,
				taskTemplate: taskItem?.taskTemplate,
				taskId: taskItem?.id,
			},
		});
	}, [taskItem]);

	useEffect(() => {
		crumbsBuilder.addPath('/tasks', 'tasks');
		crumbsBuilder.addCurrent('planning');
		crumbsBuilder.build();
	}, []); // Add crumbsBuilder to the dependency array

	const [selectedEventId, setSelectedEventId] = useState(null);

	function changeDate(dateEvent) {
		setDateRange({ start: dateEvent.startStr, end: dateEvent.endStr });
		fetchAndLoad(dateEvent.startStr, dateEvent.endStr);
	}

	const [selectedView, setSelectedView] = useState('dayGridMonth');
	const [currentDate, setCurrentDate] = useState(new Date());

	// Function to update the displayed month & year
	const updateCurrentDate = () => {
		if (calendarRef.current) {
			setCurrentDate(calendarRef.current.getApi().getDate());
		}
	};

	const handleViewChange = (event) => {
		const newView = event.target.value;
		setSelectedView(newView);
		if (calendarRef.current) {
			calendarRef.current.getApi().changeView(newView);
			updateCurrentDate();
		}
	};

	// Function to navigate calendar
	const navigate = (direction) => {
		if (calendarRef.current) {
			const calendarApi = calendarRef.current.getApi();
			if (direction === 'prev') calendarApi.prev();
			else if (direction === 'next') calendarApi.next();
			else calendarApi.today();
			updateCurrentDate();
		}
	};
	// Format Month & Year (e.g., "January 2025")
	const formattedDate = new Intl.DateTimeFormat(activeLanguageCode || 'en-GB', {
		month: 'long',
		year: 'numeric',
	}).format(currentDate);

	function getDateFromString(iDate = null) {
		const date = iDate ? new Date(iDate) : new Date();
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');

		return `${year}-${month}-${day}`;
	}

	return (
		<Card>
			<div className='flex justify-between items-center p-4'>
				{/* Left Side - Navigation Buttons */}
				<div className='flex items-center'>
					<select
						onChange={handleViewChange}
						value={selectedView}
						className='border-gray-300 rounded-xl text-sm py-1.5 mr-3'
					>
						<option value='dayGridMonth'>{translate('monthView')}</option>
						<option value='dayGridWeek'>{translate('weekView')}</option>
						<option value='timeGridWeek'>{translate('weekTimeView')}</option>
						<option value='listWeek'>{translate('listView')}</option>
						<option value='listMonth'>{translate('listMonthView')}</option>
					</select>
					<Button color='transparent' className='' small onClick={() => navigate('prev')}>
						<i className='ri-arrow-left-s-line'></i>

						{translate('prev')}
					</Button>
					<Button small color='secondary' className='mx-1' onClick={() => navigate('today')}>
						{translate('today')}
					</Button>
					<Button color='transparent' small className='mr-5' onClick={() => navigate('next')}>
						{translate('next')}
						<i className='ri-arrow-right-s-line'></i>
					</Button>
				</div>

				{/* Center - Active Month & Year */}
				<div className='text-lg font-semibold'>{formattedDate}</div>

				{/* Right Side - View Selector */}
				<div>
					<Button small onClick={() => openCreate()}>
						<i className='ri-add-line pr-1'></i>
						{translate('create')}
					</Button>
				</div>
			</div>
			<FullCalendar
				ref={calendarRef}
				plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
				initialView={selectedView}
				lazyFetching={true}
				loading={isLoading}
				headerToolbar={false} // Hide default toolbar
				locale={locale}
				datesSet={changeDate}
				events={data.data}
				eventClick={(data) => {
					const EventData = data.event._def;
					const id = EventData.publicId.split('-')[0];
					setSelectedEventId(data.event._def.publicId);
					openEdit({ id: id });
				}}
				// firstDay={1}
				// weekends={false}
				dateClick={(data) => {
					openCreate({ data: { startDateTime: data.dateStr } });
				}}
			/>
		</Card>
	);
};
export default withSuspense(PlanningPage);
