import { forwardRef } from 'react';
import TeamTable from './parts/TeamTable';
const Teams = forwardRef(
	({ isDraggable = false, zIndex = 1, onRemove = () => {}, isExportable = false, ...props }, ref) => {
		return (
			<TeamTable
				ref={ref}
				className={isDraggable ? 'cursor-move' : ''}
				{...props}
				style={{ ...props.style, zIndex: zIndex }}
			/>
		);
	},
);
export default Teams;
