import PermissionMap from 'config/PermissionMap';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { withDashboardLayout } from '../../hoc';

const TasksModule = () => {
	const { getPermissionMaps, findPageId, checkNestedPermissions } = PermissionMap();
	const location = useLocation();
	const navigate = useNavigate();
	const pathName = location.pathname.substring(1);
	const pageId = findPageId(pathName, config.pages);

	const permissionsMap = getPermissionMaps(pageId);

	const hasPermission = checkNestedPermissions(permissionsMap);

	// If no permission, navigate to the next available id
	if (!hasPermission) {
		config.pages.forEach((page) => {
			const permissionsMap = getPermissionMaps(page.id);
			const hasPermission = checkNestedPermissions(permissionsMap);

			if (hasPermission) {
				navigate(`/${page.id}`);
				return;
			}
		});
	}
	return <Outlet context={{ permissionsMap }} />;
};

const config = {
	page: 'tasks',
	pages: [
		{ id: 'tasks/tasks', label: 'taskList', to: 'tasks' },
		{ id: 'tasks/dashboard', label: 'dashboard', to: 'dashboard' },
		{ id: 'tasks/planning', label: 'planning', to: 'planning' },
		{ id: 'tasks/rules', label: 'rules', to: 'rules' },
		{ id: 'tasks/manage', label: 'manage', to: 'manage' },
		{ id: 'tasks/settings', label: 'taskSettings', to: 'settings' },
	],
};

export default withDashboardLayout(TasksModule, config);
