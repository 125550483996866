const withPaddinglessLayout = (Component) => {
	return (props) => {
		return (
			<div className='-m-2'>
				<Component {...props} />
			</div>
		);
	};
};

export default withPaddinglessLayout;
