import { NO_DATA_DISPLAY } from 'config/configs';
const WITHIN_STATUS_ID = [4];
const ETARender = ({ value, row }) => {
	if (!value) return NO_DATA_DISPLAY;
	const localTime = new Date(value).toLocaleString('en-GB', {
		hour: '2-digit',
		minute: '2-digit',
	});

	const currentTime = new Date();
	const valueTime = new Date(value);

	if (currentTime > valueTime && WITHIN_STATUS_ID.includes(row.status.id)) {
		return (
			<div className='flex items-center'>
				<div className={`bg-red-100 px-1.5 pr-2 py-1 rounded-md flex-row flex items-center`}>
					<div className={`mr-1 text-red-700`}>
						<i className='ri-timer-flash-line'></i>
					</div>
					<div className={`uppercase whitespace-nowrap text-red-700 font-semibold`}>{localTime}</div>
				</div>
			</div>
		);
	}
	return localTime;
};
export default ETARender;
