import { Button, Input, Select } from 'components/ui/Input';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { CheckBox } from '../Input';

import { useTranslations } from 'hooks';
import useDebounce from 'hooks/useDebounce';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import DateRangePicker from './Filters/DateRangePicker';

const ActionRowComponent = ({ item, rowActions = [] }) => {
	const { translate } = useTranslations();

	return (
		<div className='flex flex-row justify-center items-center'>
			<div className='flex flex-row justify-center items-center space-x-5 py-4'>
				{
					/* Show row actions */
					rowActions
						.filter((action) => !action?.isHidden)
						.map((action, index) => {
							return (
								<Button
									small
									className={action?.className || ''}
									color={action?.color || 'primary'}
									onClick={() => {
										action?.onClick(item);
									}}
									key={index}
								>
									{action?.icon && <i className={`${action.icon} mr-2`}></i>}
									{translate(action?.label)}
								</Button>
							);
						})
				}
			</div>
		</div>
	);
};

const Table = forwardRef(
	(
		{
			data,
			columns,
			actions,
			sortBy,
			sortAsc,
			onRowClick,
			onSortChange,
			showFilters = false,
			onFilterChange = () => {},
			onPostFilterChange = () => {},
			searchFilters = {},
			isLoading = false,
			hasRowActions = false,
			rowActions,
			multipleRowActions,
			RowActionsComponent = null,
			MultipleRowActionsComponent = null,
			singleSelect = false,
			onItemSelect = (selectedItems) => {},
			onSelectAll = () => {},
			onOverflowChange = () => {},
			model = {},
			columnClass = 'px-6 py-4',
			noActionOnSelect = false,
		},
		ref,
	) => {
		const { translate } = useTranslations();

		const [selectAllChecked, setSelectAllChecked] = useState(false);
		const [selectedItems, setSelectedItems] = useState([]);
		const [actionRowIndex, setActionRowIndex] = useState(-1);
		const [showNoDataMessage, setShowNoDataMessage] = useState(false);

		const multipleActionsRef = useRef(null);
		const tableHeaderRef = useRef(null);
		const tableTooltip = useRef(null);
		const tableRef = useRef(null);

		const [hasShownOverflowMessage, setHasShownOverflowMessage] = useState(false);

		useImperativeHandle(ref, () => ({
			resetSelectedColumns: () => setSelectedItems([]),
			setSelectedColumns: (items) => setSelectedItems(items),
		}));

		const selectItemHandler = (item) => {
			setActionRowIndex(-1);
			const index = selectedItems.findIndex((i) => i.id === item.id);

			if (singleSelect) {
				if (index !== -1) {
					setSelectedItems([]);
					onItemSelect([]);
				} else {
					setSelectedItems([item]);
					onItemSelect([item]);
				}
				return;
			}

			if (index === -1) {
				const l_selectedItems = [...selectedItems, item];
				setSelectedItems(l_selectedItems);
				onItemSelect(l_selectedItems);
			} else {
				const newItems = [...selectedItems];
				newItems.splice(index, 1);
				setSelectedItems(newItems);
				onItemSelect(newItems);
			}
		};

		const filterChangeHandler = (key, data, isQuery = true) => {
			if (isQuery) {
				onFilterChange(key, data);
			} else {
				onPostFilterChange(key, data);
			}
		};

		useEffect(() => {
			setShowNoDataMessage(data?.length === 0 && !isLoading);
		}, [data, isLoading]);

		const debouncedShowNoData = useDebounce(showNoDataMessage, 500);

		useEffect(() => {
			if (selectedItems.length === data?.length && data?.length > 0) {
				setSelectAllChecked(true);
				onSelectAll(true);
			} else {
				setSelectAllChecked(false);
				onSelectAll(false);
			}
		}, [selectedItems]);

		useEffect(() => {
			const hasOverflowed = tableHeaderRef.current.scrollWidth > tableHeaderRef.current.clientWidth;
			onOverflowChange(hasOverflowed);

			if (hasOverflowed && !hasShownOverflowMessage) {
				setHasShownOverflowMessage(true);
				tableTooltip.current?.open({
					anchorSelect: '.columns-picker-trigger-button',
					delay: 2000,
				});

				setTimeout(() => {
					tableTooltip.current?.close();
				}, 5000);
			}
		}, [columns]);

		useEffect(() => {
			if (selectedItems.length > 0 && !noActionOnSelect) {
				const timer = setTimeout(() => {
					multipleActionsRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'nearest',
						inline: 'nearest',
					});
				}, 2000);

				return () => clearTimeout(timer);
			}
		}, [selectedItems]);

		const selectAllHandler = (flag = true) => {
			setSelectAllChecked(!selectAllChecked);
			if (flag) {
				setSelectedItems(data);
			}

			if ((!flag, selectAllChecked)) {
				setSelectedItems([]);
			}
		};

		const handleTrClick = (item) => {
			if (actionRowIndex === item.id) {
				setActionRowIndex(-1);
				return;
			}

			hasRowActions && (rowActions || RowActionsComponent) && setActionRowIndex(item.id);

			singleSelect && selectItemHandler(item);

			onRowClick?.(item);
		};

		const handleSortChange = (sortKey, sortAsc = true) => {
			onSortChange?.(sortKey, sortAsc);
		};

		// Row base classes
		const classes = 'border-b bg-white hover:bg-gray-50';

		return (
			<div
				ref={tableHeaderRef}
				style={{ maxHeight: '70vh' }}
				className='relative overflow-x-auto overflow-y-auto  w-full '
			>
				<table ref={tableRef} className='table-container w-full text-sm text-left text-gray-500'>
					{model.areHeadersVisible() && (
						<thead className='text-xs text-gray-700 uppercase bg-primary-50 sticky top-0 h-12 '>
							<tr className=''>
								{model.areSelectsVisible() && (
									<th className='p-0 m-0 pl-2 w-10 text-center'>
										{singleSelect ? (
											<></>
										) : (
											<CheckBox
												checkboxSize='text-xl'
												halfChecked={selectedItems.length > 1}
												selected={selectAllChecked}
												onChange={(e) => selectAllHandler(e.target.checked)}
											/>
										)}
									</th>
								)}

								{columns.map((col, index) => {
									return (
										<th
											data-tooltip-content={translate(col.title, true)}
											data-tooltip-id={`table-tooltip`}
											hidden={col.hidden}
											className={`align-middle ${
												col.sortKey || (col?.isSortable && col.isSortable())
													? 'cursor-pointer'
													: ''
											} group  ${columnClass} text-${col.position || 'left'} ${col.className}`}
											scope='col'
											key={index}
											onClick={() => {
												if (col.sortKey)
													if (sortAsc) {
														handleSortChange(col.sortKey, false);
													} else {
														handleSortChange(col.sortKey, true);
													}
											}}
										>
											<div className='flex justify-between items-center'>
												<div>{translate(col.label)}</div>
												<div className='pl-1'>
													{col.sortKey &&
														(sortBy === col.sortKey ? (
															<i
																className={`opacity-75 group-hover:opacity-100 ${
																	sortAsc
																		? 'ri-arrow-down-s-fill'
																		: 'ri-arrow-up-s-fill'
																}`}
															></i>
														) : (
															<i className='opacity-0 group-hover:opacity-50 ri-expand-up-down-fill'></i>
														))}
												</div>
											</div>
										</th>
									);
								})}

								{actions ? (
									<th className={`px-6 py-3 text-right`} scope='col'>
										{translate('actions')}
									</th>
								) : (
									<></>
								)}
							</tr>
							{showFilters && (
								<tr className={classes} key='filter-row'>
									{model.areSelectsVisible() && (
										<td className='p-0 m-0 '>
											<div className='flex flex-row items-center justify-center pl-2'>
												<i className='text-xl font-bold ri-search-line '></i>
												<div className={`ml-2`}></div>
											</div>
										</td>
									)}
									{columns.map((col, index) => {
										if (col.filter && col.filter.key) {
											const isQuery = col.filter?.isParam ?? true;

											const isArray = col.filter?.isArray ?? true;

											if (col.filter.filterRender) {
												return (
													<td
														hidden={col.hidden}
														className={`px-6 py-2 text-${col.position || 'left'} ${
															col.className
														}`}
														key={`filter-${index}`}
													>
														{col.filter.filterRender(
															searchFilters[col.filter.key]?.[0] ||
																col.filter.defaultValue,
															(value) => {
																filterChangeHandler(
																	col.filter.key,
																	isArray ? [value] : value,
																	isQuery,
																);
															},
														)}
													</td>
												);
											} else {
												return (
													<td
														hidden={col.hidden}
														className={`px-6 py-2 text-${col.position || 'left'} ${
															col.className
														}`}
														key={`filter-${index}`}
													>
														{col.filter.type === 'select' ? (
															<Select
																options={col.filter.options}
																className='border border-slate-300 px-2 py-1 rounded'
																defaultValue={`${
																	(searchFilters[col.filter.key]?.[0] ||
																		col.filter.defaultValue) ??
																	''
																}`}
																onChange={(e) =>
																	filterChangeHandler(
																		col.filter.key,
																		[e.target.value],
																		isQuery,
																	)
																}
															/>
														) : col.filter.type === 'dateRange' ? (
															<DateRangePicker
																startDate={searchFilters[col.filter.fromDateKey]?.[0]}
																endDate={searchFilters[col.filter.toDateKey]?.[0]}
																onDateChange={(date) => {
																	filterChangeHandler(
																		col.filter.fromDateKey,
																		isArray ? [date.startDate] : date.startDate,
																		isQuery,
																	);
																	filterChangeHandler(
																		col.filter.toDateKey,
																		isArray ? [date.endDate] : date.endDate,
																		isQuery,
																	);
																}}
															/>
														) : (
															<Input
																type={col.filter.type || 'text'}
																className='border border-slate-300 px-2 py-1 rounded '
																defaultValue={`${
																	searchFilters[col.filter.key]?.[0] || ''
																}`}
																onChange={(e) => {
																	filterChangeHandler(
																		'query_' + //TODO: Advance this to be more dynamic, currently we suppose that all inputs are queries
																			col.filter.key,
																		isArray ? [e.target.value] : e.target.value,
																		isQuery,
																	);
																}}
															/>
														)}
													</td>
												);
											}
										} else {
											return (
												<td
													hidden={col.hidden}
													className={`px-6 py-2 text-${col.position || 'left'} ${
														col.className
													}`}
													key={`filter-${index}`}
												></td>
											);
										}
									})}
								</tr>
							)}
						</thead>
					)}
					{/* <Tooltip place="top" type="dark" effect="float" /> */}
					{
						/* Show loading spinner */
						isLoading && (
							<tr>
								<td
									className={`${
										data?.length > 0
											? 'absolute top-10 left-0 w-full h-full z-50 overflow-y-hidden'
											: ''
									}`}
									colSpan={columns.length + 1}
								>
									<div className='flex justify-center items-center h-32'>
										<i className='ri-loader-2-line animate-spin text-primary-500 text-3xl'></i>
									</div>
								</td>
							</tr>
						)
					}
					<tbody className={`${isLoading ? 'cursor-wait opacity-50' : 'cursor-auto opacity-100'}`}>
						{!data || data.length === 0 ? (
							<></>
						) : (
							data.map((item, index) => {
								const itemIsSelected = selectedItems.findIndex((i) => i.id === item.id) !== -1;

								return (
									<>
										<tr
											className={`${classes.replace('bg-white hover:bg-gray-50', '')} 
									${onRowClick ? 'cursor-pointer' : ''}
									
									${
										itemIsSelected
											? 'bg-slate-100 hover:bg-slate-200'
											: actionRowIndex === item.id
											? 'bg-gray-100 hover:bg-gray-150 border-b-0'
											: 'bg-white hover:bg-gray-50 border-b'
									}

									
									`}
											key={index}
										>
											{model.areSelectsVisible() && (
												<td className='p-0 m-0 pl-2 text-center w-10'>
													<CheckBox
														selected={itemIsSelected}
														onChange={(e) => selectItemHandler(item)}
														checkboxSize='text-xl'
													/>
												</td>
											)}

											{columns.map((col, index) => {
												return (
													<td
														hidden={col.hidden}
														key={index}
														className={` ${columnClass} ${
															col.key === 'id' ? 'w-4' : 'w-auto'
														} text-${col.position || 'left'} ${col.className}`}
														onClick={() => handleTrClick(item)}
													>
														{col.render ? col.render(item[col.key], item) : item[col.key]}
													</td>
												);
											})}
											{actions ? (
												<td className={'flex flex-row-reverse justify-center'}>
													{actions.map((action, index) => {
														return (
															<Button
																small
																onClick={() => action.action(item)}
																key={index}
																className='mt-2'
															>
																{action.label}
															</Button>
														);
													})}
												</td>
											) : (
												<></>
											)}
										</tr>

										{actionRowIndex === item.id && ActionRowComponent && !RowActionsComponent && (
											<tr
												className={'bg-gray-100 border-b border-slate-200'}
												key={index + 'action'}
											>
												<td
													//put items to the right
													className='p-0 m-0 '
													colSpan={columns.length + 1}
												>
													<div className='flex flex-row justify-end pr-10'>
														<ActionRowComponent item={item} rowActions={rowActions} />
													</div>
												</td>
											</tr>
										)}
										{RowActionsComponent && actionRowIndex === item.id && (
											<tr
												className='bg-gray-100 border-b border-slate-200'
												key={index + 'action'}
											>
												<td
													//put items to the right
													className='p-0 m-0 '
													colSpan={columns.length + 1}
												>
													<RowActionsComponent item={item} />
												</td>
											</tr>
										)}
									</>
								);
							})
						)}
					</tbody>
				</table>
				<div ref={multipleActionsRef}>
					{!singleSelect && (
						<>
							{selectedItems.length > 0 && !noActionOnSelect && (
								<>
									{MultipleRowActionsComponent ? (
										<div className=' bg-gray-100  border border-slate-200 p-4 m-5 rounded-l-lg drop-shadow-md '>
											<MultipleRowActionsComponent
												selectedItems={selectedItems}
												allRowsLength={data.length || 0}
												clearSelectedItems={() => setSelectedItems([])}
											/>
										</div>
									) : (
										<>
											{
												<div className=' bg-gray-100  border border-slate-200 p-4 m-5 rounded-l-lg drop-shadow-md '>
													<div className='flex flex-row justify-between items-center'>
														<div className='flex flex-row items-center space-x-2'>
															<i className='ri-checkbox-circle-fill text-primary-500'></i>
															<span className='text-sm text-gray-500'>
																{selectedItems.length === data.length
																	? translate('allItemsAreSelected')
																	: selectedItems.length +
																	  ' ' +
																	  translate('itemsAreSelected')}
															</span>
														</div>
														<div className='flex flex-row mr-3 space-x-2'>
															{
																//If no multiple actions are provided, we will show a clear button
																!multipleRowActions && (
																	<Button
																		small
																		onClick={() => {
																			setSelectedItems([]);
																		}}
																	>
																		{translate('clear')}
																	</Button>
																)
															}
															{
																//If multiple actions are provided, we will show a button for each action
																multipleRowActions &&
																	multipleRowActions.map((action, index) => {
																		return (
																			<Button
																				small
																				color={action.color || 'primary'}
																				onClick={() => {
																					action.onClick(selectedItems);
																				}}
																				key={index}
																			>
																				{action?.icon && (
																					<i
																						className={`${action.icon} mr-2`}
																					></i>
																				)}
																				{translate(action.label)}
																			</Button>
																		);
																	})
															}
														</div>
													</div>
												</div>
											}
										</>
									)}
								</>
							)}
						</>
					)}
				</div>
				{debouncedShowNoData && (
					<div className='w-full flex justify-center items-center text-center py-12 border-b border-slate-200 text-slate-400'>
						{translate('noDataAvailable')}
					</div>
				)}
				<Tooltip ref={tableTooltip} />
			</div>
		);
	},
);

Table.propTypes = {
	data: PropTypes.array,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			position: PropTypes.oneOf(['left', 'center', 'right']), //left, center, right
			render: PropTypes.func, //optional
			extraStyle: PropTypes.object, //optional
			sortKey: PropTypes.string,
		}),
	),
	actions: PropTypes.shape({
		label: PropTypes.string,
		action: PropTypes.func,
	}),
	selectedId: PropTypes.string,
	onRowClick: PropTypes.func,
};

export default Table;
