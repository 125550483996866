import AbstractService from 'services/Service';
import { runAction } from 'utils';
import { extractMeta } from 'utils/pagination';
const endpoint = 'users/teams';

class Service extends AbstractService {
	constructor() {
		super(endpoint);
		this.endpoint = endpoint;
		this.queryString = 'page=1&sortBy=Id&pageSize=100&sortAsc=true';
	}

	async getAll(filters = null) {
		return await super.getAll(filters ? `${this.queryString}&${filters}` : this.queryString);
	}

	async getDispatcherItems() {
		return await this.api.get(this.endpoint + '/dispatcher-items');
	}

	async getOthers(id = null) {
		const allItems = await this.getAll();
		const allTeams = this.#flattenTeams(allItems.data);

		const getDescendants = (teamId) => {
			let descendants = [];
			const team = allTeams.find((item) => item.id === teamId);
			if (team && team.children) {
				descendants = [...team.children];
				team.children.forEach((child) => {
					descendants = [...descendants, ...getDescendants(child.id)];
				});
			}
			return descendants;
		};

		if (id) {
			const descendants = getDescendants(id);
			const descendantsIds = descendants.map((descendant) => descendant.id);
			return allTeams.filter(
				(item) => item.id !== id && item.parentId !== id && !descendantsIds.includes(item.id),
			);
		} else {
			return allTeams;
		}
	}

	async getTakeOverTeams(id) {
		const qs = `TeamId=${id}`;
		const result = await this.api.get(`${this.endpoint}/fall-back/options-list?${qs}`);
		return {
			data: result?.data?.map((d) => ({
				value: d?.id,
				label: d?.name,
			})),
			meta: extractMeta(result, qs),
		};
	}

	async mapData(data) {
		const {
			id,
			homeLocation,
			excludedTaskTypeIds,
			taskGroupIds,
			color,
			description,
			name,
			numberOfTaskShown,
			dispatchingMethod,
			parentId = -1,
			fallBackTeamIds,
			...rest
		} = data;

		const addressParsed = homeLocation ? JSON.parse(homeLocation) : null;

		let departments = [];
		if (!rest.departments) {
			departments = this.#destructObjectSubmit(rest, 'departments');

			departments.forEach((department) => {
				let idsArr = [];

				const departmentToIds = this.#destructObjectSubmit(department, 'departmentToIds');
				departmentToIds.forEach((departmentToId) => {
					idsArr.push(this.#parseValue(departmentToId?.id));
				});
				department['departmentToIds'] = idsArr;
			});
		} else {
			rest.departments.forEach((department) => {
				let idsArr = [];

				department?.departmentsTo?.forEach((departmentTo) => {
					idsArr.push(this.#parseValue(departmentTo?.id));
				});

				departments.push({
					departmentId: this.#parseValue(department?.department?.id),
					departmentToIds: idsArr,
				});
			});
		}

		const resp = {
			...(await this.#getConfigData(id)),
			name,
			color,
			description,
			dispatchingMethod: dispatchingMethod ? this.#parseValue(dispatchingMethod) : undefined,
			numberOfTaskShown: this.#parseValue(numberOfTaskShown),
			homeLocation: addressParsed ?? {
				id: addressParsed?.id,
				level: addressParsed?.level,
			},
			excludedTaskTypeIds: this.#prepareArrayPayload(excludedTaskTypeIds),
			taskGroupIds: this.#prepareArrayPayload(taskGroupIds),
			fallBackTeamIds: this.#prepareArrayPayload(fallBackTeamIds),
			departments,
			parentId: this.#parseValue(parentId) !== -1 ? this.#parseValue(parentId) : null,
		};

		return resp;
	}

	#prepareArrayPayload(data) {
		return Array.isArray(data)
			? data
			: typeof data === 'string' && data?.length > 0
			? data?.split(',')?.map(Number)
			: [];
	}

	mapConfigData(data) {
		const {
			canReceiveTasks,
			keepAllocatedTasksDuringBreaks,
			keepAllocatedTasksDuringOffline,
			breakTimeOptions,
			...rest
		} = data;

		const dispatcherItems = this.#destructObjectSubmit(rest, 'dispatcherItems');
		dispatcherItems.forEach((dispatcherItem) => {
			Object.keys(dispatcherItem).forEach((key) => {
				dispatcherItem[key] = this.#parseValue(dispatcherItem[key]);
			});
		});

		const timeScheduleItems = this.#destructObjectSubmit(rest, 'timeScheduleItems');
		timeScheduleItems.forEach((timeScheduleItem) => {
			Object.keys(timeScheduleItem).forEach((key) => {
				timeScheduleItem[key] = this.#parseValue(timeScheduleItem[key]);
			});
		});

		return {
			canReceiveTasks: this.#parseValue(canReceiveTasks),
			keepAllocatedTasksDuringBreaks: this.#parseValue(keepAllocatedTasksDuringBreaks),
			keepAllocatedTasksDuringOffline: this.#parseValue(keepAllocatedTasksDuringOffline),
			dispatcherItems,
			timeScheduleItems,
			breakTimeOptions: JSON.parse(breakTimeOptions),
		};
	}

	async saveConfig(data) {
		const { id, ...rest } = data;
		const { homeLocation, ...tData } = (await super.getOne(id))?.data;
		const teamData = await this.mapData(tData);
		const lastLeaf = (node) => {
			if (node.child) {
				return lastLeaf(node.child);
			}
			return node;
		};
		const lastChildOfLocation = lastLeaf(homeLocation);

		const submitData = {
			...teamData,
			...this.mapConfigData(rest),
			homeLocation: lastChildOfLocation ?? {
				id: lastChildOfLocation?.id,
				level: lastChildOfLocation?.level,
			},
		};

		return await this.api.put(`${this.endpoint}/${id}`, submitData);
	}

	async copy(id) {
		this.beforeEach();
		return await this.api.get(`${this.endpoint}/${id}/copy`);
	}

	async remove(id, isHardDelete = false) {
		return await this.api.delete(`${this.endpoint}/${id}/${isHardDelete}`);
	}

	#flattenTeams(teams) {
		let allTeams = [];
		teams.forEach((team) => {
			allTeams.push(team);
			if (team.children) {
				allTeams = [...allTeams, ...this.#flattenTeams(team.children)];
			}
		});
		return allTeams;
	}

	#formatTime(time) {
		return (time ? time.substring(0, 5) : '') + ':00';
	}

	#destructObjectSubmit(data, keyName) {
		return Object.keys(data).reduce((acc, key) => {
			const match = key.match(new RegExp(`${keyName}\\[(\\d+)\\]\\.(.+)`));
			if (match) {
				const index = parseInt(match[1]);
				const fieldKey = match[2];
				acc[index] = { ...(acc[index] || {}), [fieldKey]: data[key] };
				delete data[key];
			}

			return acc;
		}, []);
	}

	#parseValue(value) {
		if (value === 'null') return null;
		if (value === 'undefined') return null;
		if (value === undefined) return null;
		if (value === '') return null;
		if (value === 'true') return true;
		if (value === 'false') return false;

		if (typeof value === 'string' && value.match(/^\d{2}:\d{2}$/)) return this.#formatTime(value);

		if (isNaN(value)) return value;
		if (!isNaN(value)) return parseFloat(value);
	}

	async #getDefaultTeamScheduleItems() {
		const enumValues = await runAction('tenants', 'getEnum', 'TeamScheduleDay');
		const teamSchedules = [];
		Object.keys(enumValues).forEach((key) => {
			teamSchedules.push({
				scheduleDay: enumValues[key],
				enabled: false,
				start: '00:00:00',
				end: '00:00:00',
			});
		});

		return teamSchedules;
	}

	async #getDefaultDispatcherItems() {
		const dispatcherItems = (await this.getDispatcherItems())?.data;

		return dispatcherItems?.map((item, index) => ({
			ItemId: item.itemId,
			Order: index + 1,
			Enabled: false,
			Value: 0,
		}));
	}

	async #getConfigData(id) {
		if (this.#parseValue(id)) {
			const configData = (await super.getOne(id))?.data;
			return {
				dispatcherItems: configData?.dispatcherItems,
				timeScheduleItems: configData?.timeScheduleItems,
				canReceiveTasks: configData?.canReceiveTasks,
				keepAllocatedTasksDuringBreaks: configData?.keepAllocatedTasksDuringBreaks,
				keepAllocatedTasksDuringOffline: configData?.keepAllocatedTasksDuringOffline,
				breakTimeOptions: configData?.breakTimeOptions,
			};
		}

		return {
			dispatcherItems: await this.#getDefaultDispatcherItems(),
			timeScheduleItems: await this.#getDefaultTeamScheduleItems(),
			canReceiveTasks: true,
			keepAllocatedTasksDuringBreaks: false,
			keepAllocatedTasksDuringOffline: false,
			breakTimeOptions: null,
		};
	}
}

export default Service;
