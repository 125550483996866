import { SearchSelect } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import { useEffect, useState } from 'react';
import { runAction } from 'utils';

const StatusInput = ({
	defaultValue,
	name = 'taskStatusIds',
	label = 'statuses',
	allItemsAreSelectedMessage = 'allStatusesAreSelected',
}) => {
	const { translate } = useTranslations();

	const fetchTaskStatuses = async () => {
		try {
			const res = await runAction('taskModules', 'getTaskStatuses');

			if (!res) return;
			const l_statuses = res.map((status) => {
				if (status.id === 5) return { value: status.id, label: translate(status.name, true) + ' ⮐' };
				else return { value: status.id, label: translate(status.name, true) };
			});

			setTaskItemStatuses(l_statuses);
		} catch (error) {
			console.error('Error on getting task statuses ', error);
		}
	};

	useEffect(() => {
		fetchTaskStatuses();
	}, []);

	const [taskItemStatuses, setTaskItemStatuses] = useState([]);

	return (
		<SearchSelect
			allItemsAreSelectedMessage={allItemsAreSelectedMessage}
			className='h-8 -mt-0.5'
			label={label}
			name={name}
			multiple
			defaultValue={defaultValue}
			options={taskItemStatuses}
		/>
	);
};
export default StatusInput;
