import { FormEntry } from 'components/form';
import { useTranslations } from 'hooks';
import InputField from 'modules/tasks/components/InputField';
import { forwardRef, useEffect, useState } from 'react';
import { Each } from 'utils/Each';

const PreviewForm = forwardRef((props, ref) => {
	const { translate } = useTranslations();
	const [fields, setFields] = useState([]);

	useEffect(() => {
		if (props?.helperIsOpen) {
			props?.helperIsOpen(props?.componentOpen || false);
		}
	}, [props.componentOpen]);

	useEffect(() => {
		setFields(props?.fields);
	}, [props?.fields]);

	return (
		<div className='w-full h-100 pb-10 overflow-y-visible'>
			<div className='w-full border-b border-gray-200 ' />
			<div className='py-5 text-xs opacity-60 '>
				<i className='ri-information-line text-sm pr-1'></i>
				<span>{translate('dataChangesInPreviewWillHaveEffectOnceTaskTemplateIsSaved')}</span>
			</div>
			<Each
				of={fields}
				render={(field) => {
					if (field?.isHidden) return null;
					return (
						<FormEntry label={field.name} required={field.isRequired}>
							<InputField
								// name={field.name}
								inputType={field.inputType}
								defaultData={field.defaultValue}
								options={
									typeof field?.values === 'string' ? JSON.parse(field.values) : field?.values || []
								}
								isRequired={field.isRequired}
								isDisabled={field.isDisabled}
								parentId={props.taskGroupId}
							/>
						</FormEntry>
					);
				}}
			/>
		</div>
	);
});

export default PreviewForm;
