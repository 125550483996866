import Card from 'components/ui/Card';
import { Table } from 'components/ui/Table';
import { CrudOperations } from 'hoc';
import { useCrud, useTranslations } from 'hooks';
import { useState } from 'react';
import CustomFieldForm from '../forms/CustomField.form';
import CustomFieldModel from '../models';
import CustomFieldService from '../services';

const Tables = ({ permissions }) => {
	const service = new CustomFieldService();
	const model = new CustomFieldModel();

	const { create, update, getAll, remove } = useCrud(service);
	const { translate } = useTranslations();
	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState('');

	const fetchAndLoad = async (l_queryString = null) => {
		try {
			const res = await getAll(l_queryString || queryString);
			setData(res);
		} catch (error) {
			console.error('Error on Get All request: ', error);
		}
	};

	const { openCreate, openEdit, openView } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: CustomFieldForm,
		componentName: model.getModelName(),
		permissions: permissions,
	});

	const rowClickHandler = (row) => {
		if (permissions.Update) {
			openEdit(row);
		} else if (permissions.View) {
			openView(row);
		}
	};

	return (
		<Card
			collapsible
			header={model.getPluralModelName()}
			subHeader={'configureExtraCustomFieldsToBeUsedInTaskTemplates'}
			defaultOpen={true}
			headerButtonClick={openCreate}
			headerButtonLabel={permissions.Create && `+ ${translate('addCustomField')}`}
		>
			<Table
				model={model}
				isExportable={false}
				meta={data?.meta}
				data={data?.data}
				onTableRequestChange={(queryString) => {
					setQueryString(queryString);
					fetchAndLoad(queryString);
				}}
				onRowClick={rowClickHandler}
			/>
		</Card>
	);
};

export default Tables;
