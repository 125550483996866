import { useCallback, useEffect, useMemo, useState } from 'react';
import AsideContext from './AsideContext';

import Aside from 'components/system/Aside';
import HelperAside from 'components/system/HelperAside';

const initialState = {
	open: false,
	right: false,
	Component: null,
	componentProps: {},
	saveCallback: (data) => {},
	autoClose: true,
	isLoading: false,
};

const AsideProvider = ({ children, offset = 0 }) => {
	const [asideState, setAsideState] = useState(initialState);
	const [helperAsideState, setHelperAsideState] = useState(initialState);

	const [isExtraLargeScreen, setIsExtraLargeScreen] = useState(window.matchMedia('(min-width: 1280px)').matches);
	useEffect(() => {
		const mediaQuery = window.matchMedia('(min-width: 1280px)');
		const handleResize = () => setIsExtraLargeScreen(mediaQuery.matches);

		mediaQuery.addListener(handleResize);
		return () => mediaQuery.removeListener(handleResize);
	}, []);

	const updateToggleState = useCallback((forceClose = false) => {
		setAsideState((prevState) => ({
			...prevState,
			open: forceClose ? false : !Boolean(prevState?.open),
		}));

		if (forceClose) updateToggleHelperState(true);
	}, []);

	const updateToggleHelperState = useCallback((forceClose = false) => {
		setHelperAsideState((prevState) => ({
			...prevState,
			open: forceClose ? false : !Boolean(prevState?.open),
		}));
	}, []);

	const updateConfig = useCallback((config = false, secondConfig = false) => {
		config && setAsideState((prevState) => ({ ...prevState, ...config }));
		secondConfig &&
			setHelperAsideState((prevState) => ({
				...prevState,
				...secondConfig,
			}));

		// Fix for save button visibility issue
		if (config && secondConfig && !secondConfig.open && !config.open) {
			setAsideState((prevState) => ({
				...prevState,
				hideSaveButton: false,
			}));
		}
	}, []);

	const providerValue = useMemo(
		() => ({
			asideState,
			updateConfig,
			updateToggleState,
		}),
		[asideState, updateConfig, updateToggleState],
	);

	const { open, right, width } = asideState;

	const dispatchAsideToggleEvent = () => {
		const event = new CustomEvent('asideToggle', {
			detail: { open },
		});
		window.dispatchEvent(event);
	};

	useEffect(() => {
		dispatchAsideToggleEvent();
	}, [open]);

	return (
		<AsideContext.Provider value={providerValue}>
			<div className={`relative overflow-auto flex`} style={{ height: `calc(100% - ${offset}px)` }}>
				{/* Aside */}
				<Aside onClose={() => updateToggleState(true)} {...asideState} />

				{/* Helper Aside */}
				<HelperAside onClose={() => updateToggleHelperState(true)} {...helperAsideState} parentWidth={width} />

				{/* Main Content */}
				<main
					className='p-2 transition-all ease-in-out duration-200 relative h-full overflow-y-auto'
					style={{
						width:
							open && isExtraLargeScreen
								? right
									? `calc(100% - ${width}rem)` // Shift left and tighten
									: `calc(100% - ${width - 5}rem)` // Shift right and tighten
								: '100%', // Default state
						marginLeft: open && !right ? `${width - 5}rem` : '0',
					}}
				>
					{children}
					<div className='h-5' />
				</main>
			</div>
		</AsideContext.Provider>
	);
};

export default AsideProvider;
