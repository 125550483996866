import { ReSelect } from 'components/ui/Input';
import EmployeeService from 'modules/persons/pages/Employees/services';
import TeamService from 'modules/persons/pages/Users/pages/Teams/services';

const ReSelectFilterRender = ({ value, onChange, service = 'employee' }) => {
	const services = {
		employee: new EmployeeService(),
		team: new TeamService(),
	};

	return (
		<div className='capitalize w-36'>
			<ReSelect
				containerClassName='w-36 text-sm capitalize '
				small
				service={services[service]}
				onChange={onChange}
				defaultValue={value}
			/>
		</div>
	);
};

export default ReSelectFilterRender;
