import Tooltip from 'components/ui/Input/internal/Tooltip';
import { useTranslations } from 'hooks';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import ErrorBox from './internal/ErrorBox';
import ViewInput from './internal/ViewInput';

const TextArea = forwardRef(({ isView, ...props }, ref) => {
	const { translate } = useTranslations();

	const inputRef = useRef(null);
	const [error, setError] = useState('');

	useImperativeHandle(ref, () => ({
		setCustomValidity: (err) => inputRef?.current?.setCustomValidity(translate(err, true)),
		showError: (err) => setError(translate(err, true)),
	}));

	if (isView) {
		return <ViewInput value={props?.defaultValue} />;
	}

	return (
		<Tooltip text={translate(props?.title ?? null, true)}>
			<div
				className={`relative w-full ${props.containerClassName} ${
					props.disabled ? 'opacity-50 pointer-events-none cursor-not-allowed' : 'opacity-100'
				}`}
			>
				<textarea
					ref={inputRef}
					type='text'
					{...props}
					placeholder={translate(props?.placeholder || '', true)}
					className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-300 focus:border-primary-300 block w-full p-2.5 my-1 ${props.className}`}
					onChange={(e) => {
						if (error) setError('');
						props.onChange && props.onChange(e);
					}}
				></textarea>

				<ErrorBox error={error} setError={setError} />
			</div>
		</Tooltip>
	);
});

TextArea.defaultProps = {
	className: '',
};

export default TextArea;
