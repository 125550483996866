import { SEARCH_TERM_MIN_LENGTH, SELECT_ALL_VALUE } from 'config/configs';
import { BadgedListRender } from 'core/renders';
import { useOutsideClickHandler, useTranslations } from 'hooks';
import useDebounce from 'hooks/useDebounce';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { TruncateBeginningString } from 'utils';
import ViewInput from '../../internal/ViewInput';
import Toggle from '../../Toggle';
import { IconButton, Option, SearchInput, SelectAllOption } from './components';

/**
 * TODO:
 *
 * 1. Check if the value is working as expected
 * 2. Move the label into component
 */

/**
 * MultipleSelectInput component
 * @param {boolean} disabled - If the input is disabled
 * @param {boolean} required - If the input is required
 * @param {string} name - The name of the input
 * @param {string} containerClassName - The class name of the
 * @param {array} options - The options to be displayed
 * @param {function} onOptionSelect - The function to be called when an option is selected
 * @param {function} onOptionSelectPromise - The function to be called when an option is selected and returns a promise
 * @param {function} onSearchInput - The function to be called when the search input is changed
 * @param {function} onClear - The function to be called when the clear button is clicked
 * @param {number} debounceTime - The time to debounce the search input
 * @param {function} onChange - The function to be called when the selected options are changed
 * @param {array} defaultValue - The default value of the selected options
 * @param {array} defaultSelected - The default selected options
 * @param {string} placeholder - The placeholder of the input
 * @param {string} defaultLabel - The default label of the input
 * @param {boolean} displayTooltip - If the tooltip should be displayed
 * @param {function} onEndReached - The function to be called when the end of the page is reached
 * @param {number} truncateLength - The length to truncate the selected options
 * @param {boolean} isSingleValueOnSelectAll - If the value should be a single value when all options are selected (eg. 1 for select all on save and when fetching 1 it will select all)
 * @param {function} onLabelNotFoundPromise - The function to be called when the label is not found
 * @param {boolean} isLoadingInitialState - If the input should be loading initially
 * @param {array} value - The value of the select input (used for controlled input)
 * @param {boolean} excludeSelectedOptionsWhenNotInOptionsList - If the selected options should be excluded when not in the options list
 * @param {boolean} isView - If the input is in view mode
 * @param {string} viewBadgeClassName - The class name of the badges on view mode
 * @param {string} label - The label of the input
 * @param {boolean} hideLabel - If the label should be hidden
 * @param {string} labelClassName - The class name of the label
 * @param {string} allItemsAreSelectedMessage - The message to be displayed when all items are selected
 * @param {string} className - The class name of the input
 * @returns {JSX.Element}
 */
const MultipleSelectInput = forwardRef(
	(
		{
			disabled = false,
			required = false,
			name = null,
			containerClassName = '',
			options = null,
			onOptionSelect = () => {},
			onOptionSelectPromise = null,
			onSearchInput = null,
			onClear = () => {},
			debounceTime = 300,
			onChange = () => {},
			defaultValue = null,
			defaultSelected = null,
			placeholder = 'selectAnItem',
			defaultLabel = null,
			displayTooltip = true,
			onEndReached = null,
			truncateLength = 42,
			isSingleValueOnSelectAll = false,
			onLabelNotFoundPromise = null,
			isLoadingInitialState = false,
			value = undefined, //TODO: Check if it working as expected
			excludeSelectedOptionsWhenNotInOptionsList = false,
			isView = false,
			viewBadgeClassName = '',
			label = null,
			hideLabel = false,
			labelClassName = null,
			allItemsAreSelectedMessage = 'allItemsAreSelected',
			className = '',
		},
		ref,
	) => {
		const { translate } = useTranslations();

		const inputRef = useRef(null);
		const selectInputRef = useRef(null);
		const hasMounted = useRef(false);
		const observer = useRef();
		const lastOptionElementRef = useRef();
		const optionsDivRef = useRef(null);

		const [searchTerm, setSearchTerm] = useState('');
		const [selectedOptions, setSelectedOptions] = useState(null);
		const [isFocused, setIsFocused] = useState(false);
		const [isLoading, setIsLoading] = useState(isLoadingInitialState);
		const [inputInvalid, setInputInvalid] = useState(false);
		const [totalRows, setTotalRows] = useState(0);
		const [allSelected, setAllSelected] = useState(false);
		const [displayOptions, setDisplayOptions] = useState(options | []);
		const [filteredOptions, setFilteredOptions] = useState(null);
		const [isAllItemsSet, setIsAllItemsSet] = useState(isSingleValueOnSelectAll);

		const multipleSelectInputRef = useOutsideClickHandler(() => isFocused && setIsFocused(false));

		const checkValidity = () => {
			setInputInvalid(selectInputRef.current && selectInputRef.current.validity.valid === false);
		};

		useImperativeHandle(ref, () => ({
			setActiveSelectedOption: (options) => {
				setSelectedOptions(options);
			},
		}));

		const search = (term) => {
			if (term.length === 0) {
				setFilteredOptions(null);
			}
			if (displayOptions?.length > 0) {
				filterOptions();
			} else if (term.length > 0 && !isLoading) {
				setIsLoading(true);
			}
			if (onSearchInput && (term?.length >= SEARCH_TERM_MIN_LENGTH || term?.length === 0)) {
				onSearchInput(term)
					.then((resp) => {
						if (!resp) return;

						setDisplayOptions(resp?.data);

						if (resp?.meta?.total) setTotalRows(resp.meta.total);

						setFilteredOptions(null);
					})
					.finally(() => {
						setIsLoading(false);
					});
			}
		};

		useDebounce(searchTerm, debounceTime, search);

		const filterOptions = () => {
			const l_filteredOptions = displayOptions.filter((option) =>
				option.label.toLowerCase().includes(searchTerm.toLowerCase()),
			);
			setFilteredOptions(l_filteredOptions);
		};

		/************************  HANDLERS ******************************/

		const handleSelectAll = (selected = true) => {
			setSelectedOptions(selected ? (filteredOptions || displayOptions).map((option) => option) : []);
			onChange(selected ? displayOptions.map((option) => option) : []);
			setAllSelected(selected);
		};

		const handleSelectOption = (option) => {
			if (allSelected) {
				handleSelectAll(false);
			}

			if (onOptionSelectPromise) {
				setIsLoading(true);
				handleSetSelectOption(option);
				onOptionSelectPromise(option).then((newOptions) => {
					setIsLoading(false);
					setSelectedOptions(newOptions);
				});
			} else {
				handleSetSelectOption(option);
			}
		};

		const handleSetSelectOption = (option) => {
			let l_selectedOptions = selectedOptions || [];
			if (l_selectedOptions.find((item) => item.value === option.value)) {
				l_selectedOptions = l_selectedOptions.filter((item) => item.value !== option.value);
				setSelectedOptions(l_selectedOptions);
			} else {
				l_selectedOptions = [...l_selectedOptions, option];
				setSelectedOptions(l_selectedOptions);
			}

			if (displayOptions?.length === l_selectedOptions?.length) {
				setAllSelected(true);
			}

			onOptionSelect(option, l_selectedOptions);
			onChange(l_selectedOptions);
		};

		const handleRemoveSelectedOptions = () => {
			setSearchTerm('');
			setSelectedOptions(null);
			setAllSelected(false);
			onChange([]);
			onClear();
		};

		/************************* EFFECTS *******************************/

		/**This effect is used for default value or default selected */
		useEffect(() => {
			if (
				!hasMounted.current &&
				displayOptions && //needless
				displayOptions?.length > 0 &&
				(defaultValue || defaultSelected)
			) {
				const val = defaultValue || defaultSelected;

				if (Array.isArray(val)) {
					if (isSingleValueOnSelectAll && val.includes(SELECT_ALL_VALUE)) {
						handleSelectAll(true);
						hasMounted.current = true;
						return;
					} else {
						let notFoundLabels = [];
						const l_selectedOptions = val.map((item) => {
							if (typeof item === 'object' && item.label && item.value) {
								return item;
							}
							const defaultOption = displayOptions.find((option) => option.value === item);
							if (defaultOption) return defaultOption;

							notFoundLabels.push(item);
							return {
								value: item,
								label: defaultLabel || item,
							};
						});

						//check if all items are selected, set the ref to true
						if (l_selectedOptions?.length === displayOptions?.length) {
							setAllSelected(true);
						}

						//if labels not found, call the promise to get them
						if (notFoundLabels?.length > 0 && onLabelNotFoundPromise) {
							onLabelNotFoundPromise(notFoundLabels).then((resp) => {
								if (resp) {
									const tempSelectedOptions = l_selectedOptions;
									tempSelectedOptions.forEach((item, index) => {
										if (notFoundLabels.includes(item.value)) {
											if (resp.find((option) => option.value === item.value)) {
												tempSelectedOptions[index] = resp.find(
													(option) => option.value === item.value,
												);
											}
										}
									});

									setSelectedOptions(tempSelectedOptions);
								}
							});
						} else {
							setSelectedOptions(l_selectedOptions);
						}
					}

					hasMounted.current = true;
					return;
				}
				hasMounted.current = true;
			}
		}, [defaultValue, defaultSelected, displayOptions, defaultLabel]);

		/**This effect is used to set the value */
		useEffect(() => {
			if (value !== undefined && value && Array.isArray(value)) {
				if (isSingleValueOnSelectAll && value.includes(SELECT_ALL_VALUE)) {
					handleSelectAll(true);
					return;
				}

				const l_selectedOptions = value.map((item) => {
					const defaultOption = displayOptions.find((option) => option.value === item);
					if (defaultOption) return defaultOption;

					return {
						value: item,
						label: defaultLabel || item,
					};
				});

				//check if all items are selected, set the ref to true
				if (l_selectedOptions?.length === displayOptions?.length) {
					setAllSelected(true);
				}

				setSelectedOptions(l_selectedOptions);
			}
		}, [value]);

		/** This effect is used to set the options on change, for example on task templates where we update the options related to the parent task group */
		useEffect(() => {
			if (options?.length >= 0) setDisplayOptions(options);

			if (excludeSelectedOptionsWhenNotInOptionsList) {
				const selectedOptionsInOptions = selectedOptions?.filter((option) =>
					options?.find((item) => item.value === option.value),
				);

				setSelectedOptions(selectedOptionsInOptions);
			}
		}, [options]);

		/** This effect is used for pagination, to handle the page end reached */
		useEffect(() => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver(async (entries) => {
				if (entries[0].isIntersecting && !isLoading) {
					if (onEndReached) {
						setIsLoading(true);
						onEndReached()
							.then((resp) => {
								if (resp?.data) {
									setDisplayOptions((prev) => [...prev, ...(resp?.data ?? [])]);
								}
							})
							.finally(() => {
								setIsLoading(false);
							});
					}
				}
			});
			if (lastOptionElementRef.current) {
				observer.current.observe(lastOptionElementRef.current);
			}
		}, [isFocused, onEndReached]);

		useEffect(() => {
			checkValidity();

			if (!selectedOptions) {
				return;
			}

			if (
				selectedOptions?.length === displayOptions?.length ||
				(selectedOptions?.length === totalRows && totalRows > 0)
			) {
				setAllSelected(true);
			} else {
				setAllSelected(false);
			}
		}, [selectedOptions, searchTerm, totalRows]);

		useEffect(() => {
			const val = defaultValue || defaultSelected;
			if (
				selectedOptions?.length === 1 &&
				allSelected &&
				(val ? val.length === 1 && val[0] !== SELECT_ALL_VALUE : true)
			) {
				setIsAllItemsSet(false);
			} else {
				setIsAllItemsSet(true);
			}
		}, [allSelected, defaultValue, defaultSelected, selectedOptions]);

		useEffect(() => {
			if (isFocused && optionsDivRef.current) {
				optionsDivRef.current.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});
			}
		}, [isFocused]);

		if (isView) {
			return (
				<ViewInput
					value={
						<BadgedListRender
							items={selectedOptions?.map((item) => ({ ...item, name: item.label }))}
							tooltipContainerId='aside-tooltip'
							itemLengthToTruncate={32}
							displayMax={3}
							itemClassName={`w-80 ${viewBadgeClassName}`}
						/>
					}
				/>
			);
		}

		return (
			<div ref={multipleSelectInputRef} className={`w-full relative  ${containerClassName} `}>
				{!hideLabel && label && (
					<label className={`block mb-1 text-xs font-medium text-gray-700 ${labelClassName}`}>
						{translate(label || '', true)}
					</label>
				)}
				<div
					className={`relative ${
						disabled ? 'opacity-50 pointer-events-none cursor-not-allowed' : 'opacity-100'
					}
					border rounded-lg my-1 bg-gray-50
					${isFocused ? 'border-blue-500 border-2' : inputInvalid ? 'border-red-300' : 'border-gray-300'}
					`}
					data-tooltip-html={
						displayTooltip &&
						!isFocused &&
						!allSelected &&
						selectedOptions?.map((o) => o?.label).join(', ')?.length > truncateLength
							? selectedOptions?.map((o) => '☑ ' + o?.label ?? '').join('<br/> ')
							: ''
					}
					data-tooltip-id='search-select-input-tooltip'
				>
					<select
						ref={selectInputRef}
						className='sr-only pt-10 pl-10'
						required={required}
						name={name}
						multiple
						value={
							isSingleValueOnSelectAll && allSelected && isAllItemsSet
								? [SELECT_ALL_VALUE]
								: selectedOptions?.map((o) => o?.value)
						}
						onChange={() => {}}
					>
						<option disabled></option>
						{isSingleValueOnSelectAll && allSelected && isAllItemsSet ? (
							<option selected key={SELECT_ALL_VALUE} value={SELECT_ALL_VALUE}>
								{SELECT_ALL_VALUE}
							</option>
						) : (
							selectedOptions?.map((o) => (
								<option selected key={o?.value} value={o?.value}>
									{o?.label}
								</option>
							))
						)}
					</select>

					{searchTerm && isFocused && selectedOptions?.length > 0 && (
						<div className={`inset-y-0 mt-1 left-0 flex items-center pl-2 text-xs text-gray-400`}>
							{TruncateBeginningString(
								selectedOptions?.map((o) => o.label).join(', '),
								truncateLength + 10,
							)}
						</div>
					)}

					<div className={`flex flex-row items-center justify-center ${className}`}>
						<SearchInput
							ref={inputRef}
							disabled={disabled || isLoading}
							isFocused={isFocused}
							searchTerm={searchTerm}
							isSelectAll={allSelected}
							placeholder={placeholder}
							selectedOptions={selectedOptions}
							truncateLength={truncateLength}
							onChange={(e) => setSearchTerm(e.target.value)}
							onFocus={() => setIsFocused(true)}
							allItemsAreSelectedMessage={allItemsAreSelectedMessage}
						/>
						<div className='flex flex-row '>
							{(selectedOptions?.length > 0 || searchTerm) && (
								<IconButton
									onClick={() => {
										if (!disabled) {
											if (searchTerm) setSearchTerm('');
											else handleRemoveSelectedOptions();
										}
									}}
									className='opacity-40 hover:opacity-100 text-sm'
									isLoading={isLoading}
									ariaLabel='Clear selected option'
									iconClass='ri-close-circle-fill'
								/>
							)}
							<IconButton
								onClick={() => {
									setIsFocused(!isFocused);
								}}
								isLoading={isLoading}
								ariaLabel='Open options'
								iconClass={isFocused ? `ri-arrow-up-s-line` : `ri-arrow-down-s-line`}
							/>
						</div>
					</div>

					{isLoading && (
						<div>
							<div
								className={`absolute inset-y-0 right-0 bg-gray-50 pl-3  flex items-center pr-3 opacity-100  cursor-progress`}
							>
								<i className='ri-loader-4-line animate-spin text-blue-500 text-xl'></i>
							</div>
						</div>
					)}
				</div>

				{isFocused && (
					<div className='options z-50 bg-white absolute w-full border border-gray-300 rounded-lg shadow-lg'>
						<div
							onWheel={(e) => e.stopPropagation()}
							className=' max-h-64 overflow-y-auto '
							ref={optionsDivRef}
						>
							<SelectAllOption
								searchTerm={searchTerm}
								isSelectAll={allSelected}
								isHalfChecked={selectedOptions?.length > 0 || false}
								onClick={() => handleSelectAll(!allSelected)}
							/>

							{(filteredOptions || displayOptions)?.map((option, index) => {
								const isSelected = selectedOptions?.find((item) => item.value === option.value);
								return (
									<Option
										option={option}
										onClick={() => handleSelectOption(option)}
										isSelected={isSelected ? true : false}
									/>
								);
							})}
							<div ref={lastOptionElementRef}></div>
						</div>
						<div
							className={`transition-opacity duration-500 ease-in-out transform ${
								allSelected && isSingleValueOnSelectAll
									? 'opacity-100 translate-y-0'
									: 'opacity-0 -translate-y-4'
							}`}
						>
							{allSelected && isSingleValueOnSelectAll && (
								<div className='py-3 px-3 bg-slate-50 flex flex-row items-center '>
									<Toggle
										small={true}
										checked={isAllItemsSet}
										onClick={() => {
											setIsAllItemsSet(!isAllItemsSet);
										}}
									/>
									<div className='text-xs pl-2 text-gray-500 font-light '>
										{translate('setAllItemsSelectedIfANewRecordIsAddedOnTheList')}
									</div>
								</div>
							)}
						</div>
					</div>
				)}
				<Tooltip place='top' id='search-select-input-tooltip' className='z-50' />
			</div>
		);
	},
);
export default MultipleSelectInput;
