import UserSettings from '../pages/Settings';
import Teams from '../pages/Teams/parts/TeamsTree';
import UserProfiles from '../pages/UserProfiles/parts/UserProfileTable';
import Users from '../pages/Users/parts/UsersTable';

const items = [
	{
		name: 'catoUsers',
		path: '/persons/users/users',
		key: 'users',
		element: <Users />,
	},
	{
		name: 'userProfiles',
		path: '/persons/users/userProfiles',
		key: 'userProfiles',
		element: <UserProfiles />,
	},
	{
		name: 'teams',
		path: '/persons/users/teams',
		key: 'teams',
		element: <Teams />,
	},
	{
		name: 'userSettings',
		key: 'settings',
		path: '/persons/users/settings',
		element: <UserSettings />,
	},
];
export default items;
