import { NO_DATA_DISPLAY } from 'config/configs';
const DateRender = ({
	value,
	format = {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
	},
}) => {
	if (!value) return NO_DATA_DISPLAY;
	const localTime = new Date(value).toLocaleString('en-GB', format);

	return localTime;
};
export default DateRender;
