import { AddressRender } from 'core/renders';
import { useTranslations } from 'hooks';
const RelatedTasks = ({ relatedTasks = [], onClose = () => {}, viewRelatedTask = () => {} }) => {
	const { translate } = useTranslations();
	return (
		<div className='w-full flex flex-col p-1.5 border-2 border-orange-100 rounded-md bg-orange-50 mb-2'>
			<div className='flex flex-row justify-between items-center px-2 py-1'>
				<div>
					<div className='font-semibold text-sm text-orange-700 space-x-1 flex items-center'>
						<i class='ri-user-community-line'></i>
						<div>{translate('relatedTasks')}</div>
					</div>
					<div>
						<div className='text-xs font-thin opacity-80 text-orange-700  pb-2 pt-0.5'>
							{translate('relatedTasksDescription')}
						</div>
					</div>
				</div>
				<div>
					<button
						className=' font-semibold text-orange-700 hover:text-orange-900'
						onClick={() => onClose(null)}
					>
						<i class='ri-close-circle-line'></i>
					</button>
				</div>
			</div>

			<div>
				{relatedTasks.map((task, index) => (
					<div
						key={index}
						onClick={() => viewRelatedTask(task)}
						className='flex flex-col px-1.5 py-1 border bg-orange-100  border-orange-300 hover:border-orange-400 opacity-100 hover:opacity-80 rounded-lg mb-1 cursor-pointer'
					>
						<div className='flex flex-row justify-between pt-0.5 px-1 items-center'>
							<div className='text-orange-900 font-bold'>{task.patientName} </div>
							<div className='text-xs  opacity-60'>({task.id})</div>
						</div>
						<div className='flex flex-row justify-between pt-0.5 px-1 items-center'>
							<div className='flex flex-row items-center space-x-1 '>
								<div className='text-xs font-thin pt-0.5 opacity-60 '>{translate('from')}:</div>
								<AddressRender
									value={task.from}
									tooltipContainerId={'aside-tooltip'}
									className='text-sm text-gray-900 font-semibold '
								/>
							</div>
							<div className='flex flex-row items-center space-x-1 '>
								<div className='text-xs font-thin pt-0.5  opacity-60'>{translate('to')}:</div>
								<AddressRender
									value={task.to}
									tooltipContainerId={'aside-tooltip'}
									className='text-sm text-gray-900 font-semibold '
								/>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
export default RelatedTasks;
